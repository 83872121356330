<template>
<div class="w-full h-screen">
    <LoadingControl v-show="loading" :loading="loading"/>
    <ContainerView class="h-fit p-5" gradient>
    <Modal v-show="this.show_message_modal" 
    v-bind:display="this.show_message_modal"
    :error="this.error"
    :note ="this.note"
    :success="this.success"
    z_value="z-50"
    @positiveClick="closed"
    :positiveButton="'OK'"
    :default_width="'max-w-lg'"
    id='vote_modal'>
    <div class="w-full -mt-2 p-5 flex flex-col text-md text-gray-500 text-left" :class="note_style">
    {{ this.modal_message}}
    </div>
    <div class="w-full text-lg text-left text-gray-500 p-5 " v-show="note">
       <span v-text="'Your PIN is confidential - '"/>
       <span v-text="'do not share it '" class="text-red-500"/>
       <span v-text="'with anyone to ensure the protection of your account.'"/>
    </div>
    </Modal>
    
          <!-- @positiveClick="submitOtp"
      :positiveButton="'OK'" -->
    <Modal v-show="show_otp_modal" 
      v-bind:display="show_otp_modal"
      :z_value="'z-40'"
      :closable="true"
      @closed="show_otp_modal=false"
      :default_width="'max-w-lg'"
      id='otp_modal'>
     <div class="pb-4 text-sm italic text-left p-2">
          Please enter the one time pin we have sent to your email or mobile number so that we can verify your identity.
      </div>
      <div class="pb-2 font-bold uppercase text-left p-2 flex flex-row space-x-2">
         <span v-text="'Enter Email OTP'"/> <Icons id="textbox_otp_email" v-show="show_otp_email==false" v-bind:source="'images/circled_check.svg'"   :width="24" />  
      </div>
       <div class="pb-2 font-bold uppercase text-left p-2 space-y-3 place-items-center" v-show="show_otp_email">
        <v-otp-input
            :is-disabled="false"
            ref="otp_email"
            v-show="show_otp_email"
            input-classes="otp-input-register"
            :conditionalClass="['1', '2', '3', '4', '5', '6']"
            separator=" "
            :input-type="'number'"
            inputmode="numeric"
            :num-inputs="6"
            @on-change="handleOnChangePin"
            @on-complete="handleOnCompleteEmail"
            v-model:value="otp_email"
            :should-auto-focus="true"
            :should-focus-order="true"
            :placeholder="['*', '*', '*', '*', '*', '*']"
        />
          <!-- <TextBoxField @changed="otpUpdate" id="otp" type="text"/> -->
        <span class="text-red-500 pt-2" v-text="error_otp_email_message" v-show="show_error_otp_email"/>
        <div class="w-full flex justify-end">
            <span class="text-green-500 pt-2" v-text=" 'Resend OTP'+'('+timer_count_email+')'" v-show="!show_resend_otp_email"/>
         </div>
      </div>
      <div class="pb-2 font-bold uppercase text-right p-2" v-show="show_resend_otp_email">
         <LinkText emit_triggered='resendOtp' @resendOtp="resendOtpEmail" class="text-theme-primary font-bold" text="Resend Email OTP"/>
      </div>
       <div class="pb-2 font-bold uppercase text-left p-2 flex flex-row space-x-2">
          <span v-text="'Enter Mobile OTP'"/> <Icons id="textbox_otp_mobile" v-show="show_otp_mobile==false" v-bind:source="'images/circled_check.svg'" @click="togglePeeker" :width="24" /> 
      </div>
       <div class="pb-2 font-bold uppercase text-left p-2 space-y-3 place-items-center" v-show="show_otp_mobile"  :class="{'mb-5':show_resend_otp_email==false}">
        <v-otp-input
            :is-disabled="false"
            ref="otp_mobile"
            v-show="show_otp_mobile"
            input-classes="otp-input-register"
            :conditionalClass="['1', '2', '3', '4', '5', '6']"
            separator=" "
            :input-type="'number'"
            inputmode="numeric"
            :num-inputs="6"
            @on-change="handleOnChangePin"
            @on-complete="handleCompleteMobile"
            v-model:value="otp_mobile"
            :should-auto-focus="true"
            :should-focus-order="true"
            :placeholder="['*', '*', '*', '*', '*', '*']"
        />
          <!-- <TextBoxField @changed="otpUpdate" id="otp" type="text"/> -->
         <span class="text-red-500 pt-2" v-text="error_otp_mobile_message" v-show="show_error_otp_mobile"/>
         <div class="w-full flex justify-end">
            <span class="text-green-500 pt-2 " v-text="'Resend OTP'+'('+timer_count_mobile+')'" v-show="!show_resend_otp_mobile"/>
         </div>
      </div>
      <div class="pb-2 font-bold uppercase  text-right p-2" v-show="show_resend_otp_mobile">
         <LinkText emit_triggered='resendOtp' @resendOtp="resendOtpMobile" class="text-theme-primary font-bold" text="Resend Mobile OTP"/>
      </div>
    </Modal>
    <Modal v-show="this.show_pin_modal" 
    v-bind:display="this.show_pin_modal"
    z_value="z-40"
    @positiveClick="checkPinValidity"
    @negativeClick="backToPin"
    :text_size="'text-[20px]'"
    :positiveButton="modal_button_text"
    :negativeButton="show_negative_button"
    :default_width="'max-w-lg'"
    id='vote_modal'>
    <div class=" text-[22px] bg-theme-primary text-center font-bold text-white p-3 rounded-t-md">
        {{modal_header_text}}
    </div>
    <div class="w-full p-5 flex flex-col text-2xl">
       <div class="pb-2 font-bold uppercase text-left p-2" v-show="!show_con_pin">
           <!-- @on-change="handleOnChange"
            @on-complete="handleOnComplete" -->
        <div class="flex flex-row w-full">
            <v-otp-input
                ref="otpInput"
                input-classes="otp-input-register"
                :conditionalClass="['pin1', 'pin1', 'pin1', 'pin1', 'pin1', 'pin1']"
                separator=" "
                :input-type="input_type_pin"
                inputmode="numeric"
                :num-inputs="6"
                @on-change="handleOnChangePin"
                v-model:value="enroll_pin"
                :should-auto-focus="true"
                :should-focus-order="true"
                :placeholder="['*', '*', '*', '*', '*', '*']"
            />
            <Icons id="textbox_icon_con" class="content-center" v-bind:source="getImgSrc()" @click="togglePeeker" :width="32" />
        </div>
        
        <span v-text="error_pin_message" class="text-red-500 text-sm self-start" v-show="error_pin_validity"/>
          <!-- <TextBoxField :max_length="5" @changed="otpUpdate" id="pin" type="text"/> -->
      </div>
      <div class="pb-2 font-bold text-[17px] uppercase text-left p-2" v-show="show_con_pin">
         <div class="flex flex-row items-center space-x-2">
            
        </div>
      </div>
       <div class="pb-2 font-bold uppercase text-left p-2" v-show="show_con_pin">
         <div class="flex flex-row w-full">
            <v-otp-input
                ref="conOtpInput"
                input-classes="otp-input-register"
                :conditionalClass="['con1', 'con2', 'con3', 'con4', 'con5', 'con6']"
                separator=" "
                @on-change="handleOnChangeConPin"
                :inputType="input_type_con"
                :num-inputs="6"
                v-model:value="con_enroll_pin"
                :should-auto-focus="true"
                :should-focus-order="true"
                :placeholder="['*', '*', '*', '*', '*', '*']"
            />
            <Icons id="textbox_icon_con" class="content-center"  v-bind:source="getImgSrcCon()" @click="togglePeekerCon" :width="24" />
         </div>
        <span v-text="error_pin_message" class="text-red-500 text-sm self-start" v-show="error_pin_validity"/>
      </div>
        
    </div>
    </Modal>
    
        <CardView max_width="max-w-5xl xs:mb-16 m-auto" :title="lang('create.an.account')" v-show="show" text_color="bg-theme-primary text-yellow-500 rounded-t"
        :class="[{'-translate-x-0 opacity-100' : this.fade ,'-translate-y-full opacity-0':!this.fade ,},'transition delay-100']">
            <div class="w-full flex flex-col p-5 space-y-1 ">
                <div class="flex flex-row w-full space-x-2 xs:flex-col xs:space-x-0">
                    <TextBoxField uppercase required @changed="registerFields" id="last_name" type="text" placeholder="Last Name"/>
                    <TextBoxField uppercase required @changed="registerFields" id="first_name" type="text" placeholder="First Name"/>
                    <TextBoxField uppercase @changed="registerFields" id="middle_name" type="text" placeholder="Middle Name"/>
                </div>
                <div class="flex flex-row space-x-2 xs:flex-colxs:space-x-0">
                    <TextBoxField uppercase  @changed="registerFields" :disable="disable_account" :max_length="7" id="account_number" type="text" placeholder="Account No."/>
                    <TextBoxField uppercase  @changed="registerFields" :disable="disable_serial" :max_length="7" id="serial_number" type="text" placeholder="Serial No."/>
                </div>
                <TextBoxField @changed="registerFields" ref="tin"  id="tin" type="number" placeholder="TIN"/>
                <div class="flex flex-col space-y-1">
                    <TextBoxField required @changed="registerFields" id="email" type="text" placeholder="Email" />
                    <TextBoxField required @changed="registerFields" :max_length="9" id="mobile_number" type="mobile" placeholder="Mobile no."/>
                    <div class="text-md flex text-start pt-1 text-gray-400 w-full">
                        <span v-text="lang('username.instructions')"/>
                    </div> 
                    <TextBoxField  required @changed="registerFields" id="username" type="text" placeholder="Username"/>
                    <div class="text-md flex text-start pt-1 text-gray-400 w-full">
                        <span v-text="lang('password.instructions')"/>
                    </div> 
                    <form class="space-y-1">
                        <TextBoxField required strength peekable @score="checkScore" @changed="registerFields" id="password" type="Password" placeholder="Password"/>
                        <TextBoxField required @changed="registerFields" peekable  id="confirm_password" type="Password" placeholder="Confirm Password"/>
                    </form>
                </div>  
                <div>
                    <div class="flex flex-row space-x-2 pt-2">
                        <Checkbox id="agree_ck" class="text-left" @changed="aggree"/>
                        <div class="flex flex-row space-x-2 mt-auto mb-auto">
                            <span v-text="'I agree with the'"/>
                            <LinkText emit_triggered='OpenTerms' @OpenTerms="OpenTerms" class="text-theme-primary font-bold" text="End User License Agreement"></LinkText>
                            <span v-text="'and'"/> 
                            <LinkText emit_triggered='OpenPrivacy' @OpenPrivacy="OpenPrivacy" class="text-theme-primary font-bold" text="Privacy Policy"></LinkText>
                        </div>
                    </div>
                    <div class="w-full flex justify-center p-2">
                    <VueRecaptcha v-bind:sitekey="key"  @verify="captchaVerify" />
                    </div>
                    <Button :button_text="'REGISTER'" @clicked="register" :disabled="this.reg_button_disable"/>
                    <div class="flex-grow text-center p-1">
                    <LinkText :text="lang('already.have.an.account')" bold link='/' text_size='text-md'/>
                    </div>
                </div>
            </div>
        </CardView>
</ContainerView>
</div>

</template>
<script>
import { defineComponent } from 'vue'
import User from "@/services/User";
import TextBoxField from "@/components/controls/TextBoxField.vue";
import ContainerView from "@/components/sections/ContainerView.vue";
import CardView from "@/components/controls/CardView.vue";
import Checkbox from "@/components/controls/CheckBox.vue";
import LinkText from "@/components/controls/LinkText.vue";
import Button from '@/components/controls/ButtonControl.vue';
import { VueRecaptcha } from "vue-recaptcha";
import Otp from '@/services/OTP';
import Modal from '@/components/controls/ModalControl.vue';
import LoadingControl from '@/components/controls/LoadingControl.vue';
import mixin from "@/mixin";
import VOtpInput from "vue3-otp-input";
import Icons from '@/components/controls/Icon.vue';

export default defineComponent({
    components:{
        TextBoxField,
        Icons,
        ContainerView,
        CardView,
        Checkbox,
        LinkText,
        VueRecaptcha,
        Modal,
        LoadingControl,
        Button,
        VOtpInput
    },
    data(){
        return{
            error:false,
            note:false,
            success:false,
            warning:false,
            key:'6LdTAAQhAAAAAApdt61xDVJaAUU5Cv6opGbLJFFT',
            show:false,
            show_con_pin:false,
            note_style:'',
            fade:false,
            show_otp_email:true,
            show_otp_mobile:true,
            disable_account:false,
            disable_serial:false,
            error_pin_message:'',
            show_pin_modal:false,
            note_message:'',
            input_type_pin:'password',
            input_type_con:'password',
            modal_button_text:'NEXT',
            modal_header_text:'ENTER YOUR PIN',
            hash:'',
            otp:'',
            otp_pin_mobile:'',
            otp_pin_email:'',
            otp_mobile:'',
            otp_email:'',
            show_negative_button:null,
            reg_button_disable: true,
            show_message_modal:false,
            error_pin_validity:false,
            show_otp_modal:false,
            error_pin:false,
            modal_message:'',
            loading:false,
            show_resend_otp:false,
            show_resend_otp_email:false,
            show_resend_otp_mobile:false,
            timerEnabled:false,
            timer_enable_mobile:false,
            timer_enable_email:false,
            timerCount:0,
            timer_count_email:0,
            timer_count_mobile:0,
            con_enroll_pin:'',
            visible:true,
            con_visible:true,
            enroll_pin:'',
            reg_pin:'',
            pin:'',
            otp_token:'',
            mobile_otp_pin:'',
            email_otp_pin:'',
            mobile_otp_token:'',
            email_otp_token:'',
            error_otp_email_message:'',
            error_otp_mobile_message:'',
            show_error_otp_mobile:false,
            show_error_otp_email:false,
            otp_pin:'',
            reg_info:{
                last_name:'',
                middle_name:'',
                first_name:'',
                account_number:'',
                serial_number:'',
                email:'',
                mobile_number:'',
                username:'',
                password:'',
                confirm_password:'',
                aggree:false,
                score:'',
                pin:'',
                tin:'',
                captcha:false,
            },
        }
        
    },
    mixins: [
     mixin,
    ],
    mounted() {
        let that = this;
        // this.show_otp_modal=true;
        // this.show_resend_otp_mobile=false;
        // this.show_resend_otp_email=false;
        // this.show_message_modal=true;
        // that.show_pin_modal= true;
        // this.timerEnabled = true;
        // this.timerCount = 120;
        // this.timer_count_email = 120;
        // this.timer_count_mobile = 120;
        // this.timer_enable_email = true;
        // this.timer_enable_mobile = true;
        // this.show_otp_modal = true;
        // this.modal_message = 'For enhance security, please create a unique Personal Identification Number (PIN) for future application logins. This PIN should be different from your current password'
        // this.note = true;
        
        that.show=true;
    },
    methods:{
        togglePeeker(){
            if(this.visible==true) {
                this.input_type_pin="number";
                this.visible=false;
            } else {
                this.input_type_pin="password";
                this.visible=true;
            }
        },
        handleCompleteMobile()
        {
            this.submitOtpMobile();
        },
        handleOnCompleteEmail()
        {
            this.submitOtpEmail();
        },
        checkPinValidity() {
            let that = this;
            if(that.enroll_pin=="") {
                that.error_pin_validity=true;
                that.error_pin_message='Dont Leave the PIN Empty';
            } 
            else if(that.enroll_pin.length<6) {
                that.error_pin_validity=true;
                that.error_pin_message='invalid PIN';
            } 
            else if(that.con_enroll_pin.length<6 && that.show_con_pin){
                that.error_pin_validity=true;
                that.error_pin_message='invalid PIN';
            }
            else if(that.con_enroll_pin=="" && that.show_con_pin) {
                that.error_pin_validity=true;
                that.error_pin_message='Dont Leave the PIN Empty';
            } else {
                if(that.show_con_pin) {
                    // console.log(this.show_con_pin)
                    if(that.enroll_pin == that.con_enroll_pin){
                    that.error_pin_validity=false; 
                    let payload = {
                            hash:that.hash,
                            pin:that.reg_pin,
                            mpin:that.enroll_pin
                    }
                    that.loading = true;
                        User.enrollPin(payload).then(function() {
                            that.loading = false;
                            that.error=false;
                            that.note=false;
                            that.show_pin_modal = false;
                            that.success=true;
                            that.note_style= 'text-center';
                            that.modal_message = 'Congratulations! You have Successfully Created your Account';
                            that.show_message_modal= true;
                            // that.$router.push("/")
                        }).catch(function(error) {
                            that.note_style="text-center";
                            that.loading= false;
                            that.$refs.conOtpInput.clearInput();
                            that.$refs.otpInput.clearInput();
                            that.backToPin();
                            that.error = true;
                            that.show_message_modal = true;
                            that.modal_message = error.response.data.message;
                          
                        });
                    }else{
                        that.error_pin_validity=true;
                        that.error_pin_message = 'Pins do not Match';
                    }
                // that.success = true;
                // that.modal_message = "CONGRATULATIONS YOU HAVE SUCCESSFULLY REGISTERED";
                // that.show_message_modal= true; 
                } else {
                    that.error_pin_validity = false;
                    // console.log('gadgagadg');
                    that.show_con_pin=true;
                    that.show_negative_button="BACK";
                    that.modal_button_text = "CONFIRM"
                    that.modal_header_text = "CONFIRM YOUR PIN";
                }
            }

        },
        backToPin(){
            this.show_con_pin=false;
            this.show_negative_button=null;
            this.modal_button_text="NEXT";
            this.modal_header_text = "ENTER YOUR PIN";
        },
        togglePeekerCon(){
            if(this.con_visible==true) {
                this.input_type_con="number";
                this.con_visible=false;
            }else{
                this.input_type_con="password";
                this.con_visible=true;
            }
        },
        getImgSrc() {
            return this.visible ? "images/papsi_invi_icon.svg" : "images/papsi_vis_icon.svg";
        },
        getImgSrcCon() {
            return this.con_visible ? "images/papsi_invi_icon.svg" : "images/papsi_vis_icon.svg";
        },
        closed() {
            let that = this;
            that.show_message_modal = false;
            if(that.success) {
                that.note_style= 'text-center';
                that.success=false;
                that.$router.push('/');
            }else if(that.note) {
                that.note_style= 'text-left'
                that.note=false;
                that.show_pin_modal = true;   
            }else{
                 that.note_style= 'text-center';
            }
        },
        handleOnChangeConPin(){
            if(this.enroll_pin.length !=6){
                this.error_pin  = true;
            } else {
                this.error_pin  = false;
            }
        },
        handleOnChangePin(e){
            if(/[A-Za-z]/.test(e)) {
              this.$refs.otpInput.clearInput();
            }
        },
        OpenTerms() {
          this.$emit('OpenTerms');
        },
        OpenPrivacy() {
          this.$emit('OpenPrivacy');
        },
        otpUpdate(e) {
            let that = this;
            that.otp = e.value;
        },
        checkScore(e){
            let that = this;
            that.reg_info.score = e;
            // console.log(that.reg_info.score);
            that.enableButton();
        },
        clicked() {
         this.$router.push('/register/validate');
        },
        captchaVerify() {
            this.reg_info.captcha = true;
            this.enableButton();
        },
        enableButton(){
            let that = this;
            if(that.reg_info.first_name != '' && 
            that.reg_info.last_name != '' && 
            that.reg_info.username != '' &&
            that.reg_info.password != '' &&
            that.reg_info.score =='Strong' &&
            that.reg_info.confirm_password != '' &&
            (that.reg_info.email != '' ||
            that.reg_info.mobile_number != '') &&
            (that.reg_info.account_number != '' || 
            that.reg_info.serial_number != '') &&
            that.reg_info.aggree && that.reg_info.captcha) {
                that.reg_button_disable=false; 
            } else {
              that.reg_button_disable=true;
            }
        },
        register() {
            let payload;
            let that = this;
            that.loading = true;
            that.show_registration_modal = false;
            // this.show_poll_modal = true;
            payload = {
                first_name:that.reg_info.first_name, 
                last_name:that.reg_info.last_name, 
                middle_name:that.reg_info.middle_name, 
                account_no:that.reg_info.account_number, 
                serial_no:that.reg_info.serial_number, 
                email:that.reg_info.email, 
                mobile_no:that.reg_info.mobile_number ==''?'':'+63'+that.reg_info.mobile_number, 
                username:that.reg_info.username,
                password:that.reg_info.password, 
                con_password:that.reg_info.confirm_password,
                agreement:1
            }
            User.signup(payload).then(function(response) {
                that.reg_pin = response.data.data.pin;
                that.otp_pin_email = response.data.data.otp.email.pin
                that.otp_pin_mobile = response.data.data.otp.mobile.pin
                that.pin = response.data.data.otp_pin
                that.hash = response.data.data.hash;
                that.loading = false;
                // that.timerEnabled= true;
                // that.timerCount = 120;
                that.timer_count_email = 120;
                that.timer_count_mobile = 120;
                that.timer_enable_email = true;
                that.timer_enable_mobile = true;
                // that.timerCount = 10;
                that.note_style= "text-center"
                that.show_otp_modal=true;

            }).catch(function(error) {
                that.note_style="text-center";
                that.loading= false;
                that.error = true;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
            });
      },
      resendOtp() {
        let that = this;
        that.loading = true;
        that.show_resend_otp = false;
        that.timerEnabled= true;
        that.timerCount = 120;
        Otp.requestOtp(
            {
              type:2, 
              email:that.reg_info.email, 
              mobile:that.reg_info.mobile_number ==''?'':'+63'+that.reg_info.mobile_number, 
              name :that.reg_info.first_name, 
              pin:that.pin,
            }
          ).then(function(response) {
              that.otp_pin = response.data.data.pin;
              that.loading= false;
              that.show_otp_modal = true;
              
          }).catch(function() {
              that.note_style="text-center";
              that.loading= false;
              that.modal_type = 1;
          });
      },
      resendOtpEmail() {
        let that = this;
        that.loading = true;
        that.show_resend_otp = false;
        that.timer_enable_email= true;
        that.timer_count_email = 120;
        Otp.requestOtp(
            {
              type:2, 
              email:that.reg_info.email, 
              mobile:that.reg_info.mobile_number ==''?'':'+63'+that.reg_info.mobile_number, 
              name :that.reg_info.first_name, 
              pin:that.otp_pin_email,
            }
          ).then(function(response) {
              that.otp_pin = response.data.data.pin;
              that.loading= false;
              that.show_otp_modal = true;
              
          }).catch(function() {
              that.note_style="text-center";
              that.loading= false;
              that.modal_type = 1;
          });
      },
      resendOtpMobile() {
        let that = this;
        that.loading = true;
        that.show_resend_otp = false;
        that.timer_enable_mobile= true;
        that.timer_count_mobile= 120;
        Otp.requestOtp(
            {
              type:2, 
              email:that.reg_info.email, 
              mobile:that.reg_info.mobile_number ==''?'':'+63'+that.reg_info.mobile_number, 
              name :that.reg_info.first_name, 
              pin:that.otp_pin_mobile,
            }
          ).then(function(response) {
              that.otp_pin = response.data.data.pin;
              that.loading= false;
              that.show_otp_modal = true;
              
          }).catch(function() {
              that.note_style="text-center";
              that.loading= false;
              that.modal_type = 1;
          });
      },
      createUser() {
        let payload;
        let that = this;
        that.show_otp_modal = false;
        payload = {
           mobile_otp_pin:that.mobile_otp_pin,
           mobile_otp_token:that.mobile_otp_token,
           email_otp_pin:that.email_otp_pin,
           email_otp_token:that.email_otp_token,
           pin:that.reg_pin,
           hash:that.hash,
           first_name:that.reg_info.first_name, 
           last_name:that.reg_info.last_name, 
           account_no:that.reg_info.account_number,
           middle_name:that.reg_info.middle_name, 
           serial_no:that.reg_info.serial_number, 
           username:that.reg_info.username,
           email:that.reg_info.email, 
           mobile_no:that.reg_info.mobile_number ==''?'':'+63'+that.reg_info.mobile_number, 
           password:that.reg_info.password, 
           con_password:that.reg_info.confirm_password,
           tin:that.reg_info.tin,
           agreement:1
        }
        User.createUser(payload).then(function() {
            that.loading= false;
            that.show_otp_modal = false;
            that.note=true;
            that.note_style = "text-left";
            that.show_message_modal= true;
            that.success= false;
            that.error = false;
            that.modal_message= "For enhance security, please create a unique Personal Identification Number (PIN) for future application logins. This PIN should be different from your current password"
            // that.note_message ="";
            // that.show_pin_modal = true;
        }).catch(function(error) {
            that.note_style="text-center";
            that.loading= false;
            that.modal_type = 2;
            that.error = true;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
        });
      },
    submitOtp() {
        let that = this;
        that.loading =true;
        Otp.validate({otp:that.otp,type:2,pin:that.pin}).then(function(response){
          that.show_otp_modal= false;
          that.otp_token = response.data.data.otp_token;
          that.otp_pin = response.data.data.otp_pin;
          that.createUser();
        //   that.pin = '';
        //   that.otp = '';
        }).catch(function(error) {
          that.note_style="text-center";
          that.error = true;
          that.show_message_modal= true;
          that.modal_message = error.response.data.message;
          that.loading = false;
        });
      },
     submitOtpEmail() {
        let that = this;
        that.loading =true;
        Otp.validate({otp:that.otp_email,type:2,pin:that.otp_pin_email}).then(function(response){
        //   that.show_otp_modal= false;
          that.show_otp_email = false;
        //   that.otp_token = response.data.data.otp_token;
        //   that.otp_pin = ;
            that.email_otp_pin=response.data.data.otp_pin;
            that.email_otp_token=response.data.data.otp_token;
          if(that.show_otp_email == false && that.show_otp_mobile == false){
               that.loading = true;
               that.show_error_otp_email = false;
               that.createUser();
          }else{
            that.loading= false;
          }
        //   that.pin = '';
        //   that.otp = '';
        }).catch(function(error) {
          that.note_style="text-center";
          that.$refs.otp_email.clearInput();
          that.error = true;
          that.show_error_otp_email= true;
          that.error_otp_email_message = error.response.data.message;
          that.loading = false;
        });
      },
        submitOtpMobile() {
        let that = this;
        that.loading =true;
        Otp.validate({otp:that.otp_mobile,type:2,pin:that.otp_pin_mobile}).then(function(response){
        //   that.show_otp_modal= false;
          that.show_otp_mobile = false;
        //   that.otp_token = ;
        //   that.otp_pin = ;
          that.mobile_otp_pin=response.data.data.otp_pin;
          that.mobile_otp_token=response.data.data.otp_token;
          if(that.show_otp_email == false && that.show_otp_mobile == false){
                // that.loading = true;
               that.show_error_otp_mobile=false;
               that.createUser();
          }else{
            that.loading= false;
          }
        }).catch(function(error) {
          that.note_style="text-center";
          that.$refs.otp_mobile.clearInput();
          that.error = true;
          that.show_error_otp_mobile = true;
          that.error_otp_mobile_message = error.response.data.message;
          that.loading = false;
        });
      },
     aggree(n) {
        let that = this;
        that.reg_info.aggree = n;
        this.enableButton();
      },
      registerFields(e){
        let that = this;
        let obj = that.reg_info;
        obj[e.key] = e.value;
        
        if(e.key == "account_number"){
            if(that.reg_info.account_number.length>0){
                that.disable_serial=true;
            }else{
                that.disable_serial=false;
            }
        }

        if(e.key == "serial_number"){
            if(that.reg_info.serial_number.length>0){
                that.disable_account=true;
            }else{
                that.disable_account=false;
            }
        }
        this.enableButton();
      },
    },
    watch:{
        show() {
             setTimeout(() => {
                this.fade=true;
            }, 100);
        },
        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled) {
                this.show_resend_otp = false;
                setTimeout(() => {
                    this.timerCount = this.timerCount - 1;
                    // console.log(this.timerCount);
                }, 1000);
                } else {
                    this.show_resend_otp = true;
                    this.timerEnabled = false; // run your function here
                }
            },
            immediate: true
        },
        timer_count_email: {
            handler(value) {
                if (value > 0 && this.timer_enable_email) {
                this.show_resend_otp_email = false;
                setTimeout(() => {
                    this.timer_count_email = this.timer_count_email - 1;
                    // console.log(this.timerCount);
                }, 1000);
                } else {
                    if(this.show_otp_email){
                        this.show_resend_otp_email = true;
                    }
                    this.timer_enable_email = false; // run your function here
                }
            },
            immediate: true
        },
        timer_count_mobile: {
            handler(value) {
                if (value > 0 && this.timer_enable_mobile) {
                    this.show_resend_otp_mobile = false;
                    setTimeout(() => {
                        this.timer_count_mobile = this.timer_count_mobile - 1;
                        // console.log(this.timerCount);
                    }, 1000);
                } else {
                      if(this.show_otp_mobile){
                        this.show_resend_otp_mobile = true;
                    }
                    this.timer_enable_mobile = false; // run your function here
                }
            },
            immediate: true
        },
    }
})
</script>

<style>
.otp-input-register {
  width: 52px;
  height: 55px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 2px solid grey;
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input-register.is-complete {
  background-color: white;
}
.otp-input-register::-webkit-inner-spin-button,
.otp-input-register::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
