<template>
    <Container  class="flex w-full h-full" gradient>
      <Loading v-show="loading" :loading="loading"/>
      <Modal v-show="show_message_modal" 
      v-bind:display="show_message_modal"
      :error="error"
      :success="success"
      :note="warning" 
      @positiveClick="closed"
      :positiveButton="'OK'"
      :z_value="'z-50'"
      :default_width="'max-w-xl'"
      id='vote_modal'>
        <div class="w-full p-5 flex flex-col text-xl font-bold">
            {{ this.modal_message}}
        </div>
      </Modal>
      <Card max_width="max-w-xl p-2 m-auto" v-show="show"
        :class="[{'-translate-x-0 opacity-100' : this.fade ,'-translate-y-full opacity-0':!this.fade ,},'transition delay-100']">
          <div>
            <div class="md:p-18 xs:p-5">
              <div class="text-base flex flex-col">
              <img src="https://media.pafcpic.net/images/logo/pafcpic-nav.png" class="pb-2 w-full" />
              <span class="text-theme-primary font-bold text-xl pb-2">UPDATE PASSWORD</span>
            
          </div>
              <div class="pb-1">
                <div class="pb-6 text-base text-justify italic">
                  Your new password should consist of uppercase and lowercase letters with numbers.
                </div>
                <Textbox type="Password" 
                  id="new_password" 
                  @changed="changed"
                  class="mb-4"
                  v-bind:placeholder="lang('new.password')" 
                  v-bind:value="new_password"
                  strength
                  peekable
                  required />
                <Textbox type="Password" 
                  id="con_password"
                  @changed="changed"
                  peekable
                  class="mb-4" 
                  v-bind:placeholder="lang('confirm.password')" 
                  v-bind:value="con_password"
                  required />
                <Button @clicked="submit" :button_text="lang('UPDATE')" :disabled="disabled" rounded/>
            </div>
          </div>
        </div>
      </Card>
    </Container>
</template>

<script>
import { defineComponent } from "vue";
import mixin from "@/mixin";
import User from "@/services/User";
import Button from "@/components/controls/ButtonControl.vue";
import Modal from "@/components/controls/ModalControl.vue";
import Textbox from "@/components/controls/TextBoxField.vue";
import Loading from "@/components/controls/LoadingControl.vue";
import Container from "@/components/sections/ContainerView.vue";
import Card from "@/components/controls/CardView.vue";
export default defineComponent({
  components: {
    Card,
    Button,
    Modal,
    Textbox,
    Loading,
    Container,
  },
  mixins: [
    mixin,
  ],
  data() {
    return {
      disabled: true,
      score: 0,
      show_message_modal:false,
      modal_message:'',
      new_password: '',
      con_password: '',
      fade: false,
      show:false,
      loading:false,
      success: false,
      error: false,
      warning: false,
      uuid: '',
    }
  },
  mounted() {
    this.$emit('back', '/forgot-password');

      const store = this.$store;
      const state = store.state.forgot_password_payload;

    this.show = true;
    if(!state.uuid)
    {
      this.$router.push('/');
    }
    else
    {
      this.uuid = state.uuid;
    }
  },
  methods: {
    closed() {
      if(this.success) {
        this.success=false;
        this.show_message_modal = false;
        this.$router.push('/');
      }
      this.show_message_modal = false;
    },
    changed(e) {
      this[e.key] = e.value;
      this.enableButton();
    },
    enableButton() {
      if(this.new_password != '' && this.con_password !=''){
          this.disabled = false;
      }
    },
    submit() {
      const that = this;
      const store = that.$store;
      const state = store.state.forgot_password_payload;

      that.loading=true
      let payload = {
          password:this.new_password,
          con_password:this.con_password,
          hash:state.hash,
          pin:state.pin,
          otp_token:store.state.recovery.otp_token,
          otp_pin:store.state.recovery.otp_pin,
      }
      // console.log(payload);
      User.updatePassword(payload,state.uuid)
        .then(function() {
          that.loading=false;
          that.success=true;
          that.modal_message = 'Congratulations! You have Successfully Updated your Password';
          that.show_message_modal= true;
        })
        .catch(function(error) {
          that.error = true;
          that.loading = false;
          that.success=false;
          that.show_message_modal= true;
          that.modal_message = error.response.data.message;
        });
    }
  },
  watch:{
    show() {
          setTimeout(() => {
            this.fade=true;
        }, 100);
    },
   }
});
</script>
