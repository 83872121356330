<template>
    <div class="h-full w-full bg-translucent absolute flex z-50">
        <div class="flower-spinner m-auto">
            <img src="https://media.pafcpic.net/images/logo/pafcpic.png" class="w-48 h-48 absolute z-50" />
            <div class="dots-container ">
                <div class="bigger-dot">
                    <div class="smaller-dot"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
        data(){
            return {
            
            }
        },
        props:{
            loading:{
                type:Boolean,
                required:true
            }
        }
})
</script>
<style scoped>
.bg-translucent {
    background-color: rgba(0 ,0 ,0 ,0.75);
}
.flower-spinner,  .flower-spinner * {
      box-sizing: border-box;
    }

    .flower-spinner {
      height: 500px;
      width: 500px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .flower-spinner .dots-container {
      height: calc(400px / 6);
      width: calc(400px / 6);
    }

    .flower-spinner .smaller-dot {
      background: #16A34A;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      animation: flower-spinner-smaller-dot-animation 2.5s 0s infinite both;
    }

    .flower-spinner .bigger-dot {
      background: #16A34A;
      height: 100%;
      width: 100%;
      padding: 10%;
      border-radius: 50%;
      animation: flower-spinner-bigger-dot-animation 2.5s 0s infinite both;
    }

    @keyframes flower-spinner-bigger-dot-animation {
      0%, 100% {
        box-shadow: rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px;
      }

      50% {
        transform: rotate(180deg);
      }

      25%, 75% {
        box-shadow: rgb(22,163,74) 145px 0px 0px,
        rgb(22,163,74) -145px 0px 0px,
        rgb(22,163,74) 0px 145px 0px,
        rgb(22,163,74) 0px -145px 0px,
        rgb(251,214,3) 100px -100px 0px,
        rgb(251,214,3) 100px 100px 0px,
        rgb(251,214,3) -100px -100px 0px,
        rgb(251,214,3) -100px 100px 0px;
      }

      100% {
        transform: rotate(360deg);
        box-shadow: rgb(251,214,3) 0px 0px 0px,
        rgb(251,214,3) 0px 0px 0px,
        rgb(251,214,3) 0px 0px 0px,
        rgb(251,214,3) 0px 0px 0px,
        rgb(251,214,3) 0px 0px 0px,
        rgb(251,214,3) 0px 0px 0px,
        rgb(251,214,3) 0px 0px 0px,
        rgb(251,214,3) 0px 0px 0px,
        rgb(251,214,3) 0px 0px 0px,
        rgb(251,214,3) 100px 0px 0px,
        rgb(251,214,3) 100px 100px 0px,
        rgb(251,214,3) 0px 100px 0px,
        rgb(251,214,3) -100px 0px 0px,
        rgb(251,214,3) 0px -100px 0px,
        rgb(251,214,3) -100px -100px 0px,
        /* rgb(251,214,3) 205px 0px 0px,
        rgb(251,214,3) 0px 205px 0px,
        rgb(251,214,3) -205px 0px 0px,
        rgb(251,214,3) 0px -205px 0px,
        rgb(251,214,3) 0px -205px 0px,
        rgb(251,214,3) -205px -205px 0px,
        rgb(251,214,3) 205px 205px 0px ; */
      }
    }

    @keyframes flower-spinner-smaller-dot-animation {
      0%, 100% {
        box-shadow: rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px;
      }

      25%, 75% {
        box-shadow: rgb(251,214,3) 14px 0px 0px,
        rgb(251,214,3) -14px 0px 0px,
        rgb(251,214,3) 0px 14px 0px,
        rgb(251,214,3) 0px -14px 0px,
        rgb(22,163,74) 10px -10px 0px,
        rgb(22,163,74) 10px 10px 0px,
        rgb(22,163,74) -10px -10px 0px,
        rgb(22,163,74) -10px 10px 0px;
      }

      100% {
        box-shadow: rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        rgb(22,163,74) 0px 0px 0px,
        /* rgb(22,163,74) 150px 0px 0px,
        rgb(22,163,74) 0px 150px 0px,
        rgb(22,163,74) -150px 0px 0px,
        rgb(22,163,74) 0px -150px 0px,
        rgb(22,163,74) 0px -150px 0px,
        rgb(22,163,74) -150px -150px 0px,
        rgb(22,163,74) 150px 150px 0px */
      }
    }
</style>
