<template>
  <ContainerView class="h-full w-full">
    <LoadingControl v-show="loading" :loading="loading"/>
     <ModalControl v-show="this.show_poll_modal" 
      v-bind:display="this.show_poll_modal" 
      :z_value="'z-50'"
      v-bind:title="lang('amendments.title')"
      @closed="closedPoll"
      @positiveClick="vote"
      @readAmendments="showAmendments"
      :positiveButton="lang('submit')"
      :amendment="lang('view.amendments')"
      :default_width="'max-w-2xl'"
      id='vote_modal'
      ref='pollModal'
      closable>
      <div class="w-full p-5 flex flex-col space-y-2 text-left">
        <span class="text-lg" v-text="lang('letter.greetings')" />
        <span class="text-lg mb-3" v-text="lang('letter.first.para')" />
        <LinkText emit_triggered='OpenTerms' @OpenTerms="show_pdf=true" class="text-theme-primary font-bold text-lg" :text="lang('view.amendments')"></LinkText>
        <span class="text-lg font-bold mt-3" v-text="lang('letter.second.para')" />
         <span class="text-lg" v-text="lang('letter.casting.vote')" />
         <span class="text-lg" v-text="lang('Very truly yours,')" />
         <img src="https://media.pafcpic.net/images/misc/paredes-signature.jpg" class="modal_max_width -ml-1 -mb-2" />
          <span class="text-lg font-bold" v-text="lang('BGEN FRANCISCO M. PAREDES (RET)')" />
          <span class="text-lg" v-text="lang('Chairperson of the Board')" />
         <div class="border border-black w-full mb-2"/>
         <span class="text-lg -mt-2" v-text="lang('letter.acknowledging.para')" />
      </div>
    </ModalControl>
    <ModalControl v-show="show_message_modal" 
      v-bind:display="show_message_modal"
      :error="this.error"
      :success="this.success"
      :note="this.warning"
      z_value="z-50"
      @positiveClick="closed()"
      :positiveButton="'OK'"
      :default_width="'max-w-xl'"
      id='vote_modal'>
      <div class="w-full p-5 flex flex-col text-xl text-gray-500">
          {{ this.modal_message}}
      </div>
    </ModalControl>
    <AmendmentView :display="show_pdf" @closedAmendments="closedAmendments" />
      <div class="w-full h-full">
        <NavBarView :user_name="name" special @logout="logout" @donelogout="donelogout" @slide="goto" @showMenu="showMenu"/>
          <article ref="article1" class="bg-white flex w-full place-content-center">
            <div  class=" bg-white  flex flex-col w-full h-fit ">
              <div class="w-full justify-items-center ">
                    <img src="https://tinyurl.com/2u2t2wb6"  class="w-full banner-size-md xs:hidden pb-2"/>
                    <img src="https://tinyurl.com/2u2t2wb6"  class="w-full banner-size-sm sm:hidden md:hidden pb-2"/>
                  <div class="w-full flex">
                    <div class="bg-white w-full border p-5  max-w-3xl rounded border-gray-200 m-auto flex flex-col justify-center shadow-xl" >
                      <div v-if="!show_timer" class="space-y-5 p-5">
                          <div  class="loader w-full">

                          </div>
                          <div  class="loader" style="width:80% !important">

                          </div>
                          <div  class="loader w-full" style="width:90% !important">

                          </div>
                            <div  class="loader w-full" style="width:75% !important">

                          </div>
                            <div  class="loader w-full" style="width:100% !important">

                          </div>
                      </div>
                      <div class="text-lg block leading-4 border-b mb-5 p-2 uppercase font-bold " v-show="show_timer" v-text="label"></div>
                          <div class="w-full flex justify-center" v-show="show_timer">
                              <Tick v-bind:value="days.toString()" label="Day" margin />
                              <Tick v-bind:value="hour.toString()" label="Hour" margin />
                              <Tick v-bind:value="minute.toString()" label="Min" margin />
                              <Tick v-bind:value="seconds.toString()" label="Sec" />
                          </div>
                          <div class=" text-white text-center flex justify-center" v-show="show_timer">
                            <div class="w-full max-w-sm p-4">
                              <!-- <div class="text-red-500 font-bold mb-2" v-show="this.show_timer">
                                <span v-text="'Wait (' + this.request_timer + ') Before proceeding'"/>
                              </div> -->
                              <!-- <Button class="text-lg" :class="[{'hidden':false}]" :button_text="button_text" @clicked="click" rounded :disabled="false"/>  -->
                              <Button class="text-lg" :class="[{'hidden':button_visible}]" :button_text="button_text" @clicked="click" rounded :disabled="button_visible"/>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </article>
      </div>
  </ContainerView>
</template>

<script>
import { defineComponent } from 'vue';
// import { StreamBarcodeReader } from "vue-barcode-reader";
import ContainerView from '@/components/sections/ContainerView.vue';
// import TextBoxField from "@/components/controls/TextBoxField.vue";
// import member from "@/services/Member";
import User from "@/services/User";
import Button from "@/components/controls/ButtonControl.vue";
// import Checkbox from "@/components/controls/CheckBox.vue";
// import CardView from "@/components/controls/CardView.vue";
import ModalControl from "@/components/controls/ModalControl.vue";
import AmendmentView from "@/components/sections/AmendmentView.vue";
// import Icon from "@/components/controls/Icon.vue";
import LinkText from "@/components/controls/LinkText.vue";
import LoadingControl from "@/components/controls/LoadingControl.vue";
import NavBarView from "@/components/controls/NavBarView.vue";
import Tick from "@/components/controls/TickControl.vue";
import election from "@/services/Election";
import mixin from "@/mixin";
import OTP from "@/services/OTP"
export default defineComponent({
    components:{
      ContainerView,
      NavBarView,
      // TextBoxField,
      ModalControl,
      AmendmentView,
      LoadingControl,
      // Icon,
      // StreamBarcodeReader,
      // CardView,
      Tick,
      Button,
      LinkText,
      // Checkbox
    },
    
    mounted(){
       let that = this;
       let store = that.$store;
       let state = store.state;
      //  state.credentials.has_pin=false;
        if(that.expired){
          that.$cookies.remove('user');
          that.$cookies.remove('user_session');
          that.$router.push('/');
        }
        if(state.credentials.express) {
          that.$router.push('/user/settings');
        }
        if(state.credentials.deactivated) {
          that.$router.push('/user/settings');
        }
      
        if(state.credentials.username == null || state.credentials.username ==""){
          that.getUserInfo();
        }else{
          if(!state.credentials.has_pin) {
            that.warning = true;
            that.modal_message = "You currently don't have a PIN. Enroll now to proceed. ";
            that.show_message_modal=true;
            // that.$router.push('/user/settings');
          }
          that.getGaInfo(); 
        }
        
      
      //  else {
      //   // that.testHash();
      // }
    },
     data(){
      return {
        show_poll_modal:false,
        show_message_modal:false,
        error:false,
        server_time:'',
        success:false,
        warning:false,
        modal_message:'',
        expired:false,
        show_pdf:false,
        loading:false,
        mev:false,
        name:'',
        days: 0,
        hour: 0,
        ga_series:'',
        show_timer:false,
        minute: 0,
        seconds: 0,
        date_start:'',
        date_end:'',
        voted: false,
        voting_ended: false,
        label:'',
        modal_type:0,
        registration_start:'',
        registration_end:'',
        registration_ended:false,
        registration_started: false,
        election_registered: false,
        can_vote: false,
        button_text:'',
        button_disabled:false,
        button_visible:false,
        button_function:0,
        show_menu:false,
      }
    },
    mixins: [
      mixin,
    ],
    methods:{
      getGaInfo(){
       let that = this;
       let cookie = that.$cookies;
       let store = that.$store;
       let state = store.state;
       that.setNavbar();
       election.getGaInfo(cookie.get('user_session').hash).then(function(response) {
              let user_data = response.data.data;

              state.election_config.registration_end= user_data.schedules.registration_end;
              state.election_config.registration_ended= user_data.schedules.registration_ended;
              state.election_config.registration_start=user_data.schedules.registration_start;
              state.election_config.registration_started= user_data.schedules.registration_started;
              state.election_config.date_end = user_data.schedules.voting_end;
              state.election_config.voting_ended = user_data.schedules.voting_ended;
              state.election_config.date_start = user_data.schedules.voting_start;
              // state.election_config.date_start ='2025-02-27 10:20:00';
              
              state.election_config.server_time = response.timestamp;
              state.election_config.election_registered = user_data.user.registered;
              state.election_config.code = user_data.code;
              state.election_config.can_vote = user_data.user.can_vote;
              state.election_config.voted = user_data.user.voted;
              state.election_config.total_votes = user_data.user.total_votes;
              that.mev = user_data.user.mev;
              that.registered = user_data.user.registered;
              that.voted = user_data.user.voted;
              that.ga_series = user_data.code;
              if(user_data.user.voted != null) {

                that.date_start = state.election_config.date_start;
                that.date_end = state.election_config.date_end;
                that.server_time= state.election_config.server_time;
                that.election_registered = state.election_config.election_registered;
                that.registration_start=state.election_config.registration_start;
                that.registration_end =state.election_config.registration_end;
                that.registration_started =state.election_config.registration_started;
                that.can_vote = state.election_config.can_vote;
                that.voted = state.election_config.voted;
                that.voting_ended = state.election_config.voting_ended;

                that.setSchedule();
                // that.setButton();
            }
            
          // console.log(response);
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            if(error.response.status == 403) {
              that.expired = true
              that.modal_message = "Please Log in Again.";
            }
        });

    },
    
    getUserInfo() {
       let that = this;
       const cookie = that.$cookies;
       const store = that.$store;
       let state = store.state;
        that.loading = true;
        // console.log(cookie.get('user').hash);
        User.recoverSession(cookie.get('user_session').hash).then(function(response) {
            let data = response.data.data;  
            // that.loading = false;
            state.credentials.account_no=data.info.account_number;
            state.credentials.email=data.info.email;
            state.credentials.key=data.key;
            state.credentials.mobile=data.info.mobile;
            state.credentials.name=data.info.first_name + " "+ data.info.last_name;
            state.credentials.qr=data.qr;
            state.credentials.serial_no=data.info.serial_number;
            state.credentials.timestamp=response.timestamp;
            state.credentials.username=data.info.username;
            state.credentials.hash = data.hash;
            state.credentials.has_pin= data.has_pin;
            state.credentials.deactivated= data.deactivated;
            state.credentials.tin= data.info.tin;
            
            that.loading = false;

            if(!state.credentials.has_pin) {
              that.warning = true;
              that.modal_message = "You currently don't have a PIN. Enroll now to proceed.";
              that.show_message_modal=true;
              // that.$router.push('/user/settings');
            }else{
              that.getGaInfo(); 
            }
        }).catch(function(error) {
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            if(error.response.status == 403) {
              that.expired = true
              that.modal_message = "Please Log in Again.";
            }
        });
      },
       goto(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;
        this.show_menu=false;
        window.scrollTo(0, top);
      },
      remove() {
        this.show_identifier = false;
      },
      OpenTerms() {
          this.$emit('OpenTerms');
      },
      OpenPrivacy() {
        this.$emit('OpenPrivacy');
      },
      setSchedule(){
          let that = this;
          let store = that.$store;
          let state = store.state;

          let now = new Date().getTime(that.server_time);
          let registration_start =new Date(that.registration_start).getTime();
          //  let registration_end =new Date(that.registration_end).getTime();
          // let registration_started =that.registration_started;
          let election_start = new Date(state.election_config.date_start).getTime();
          let election_end = new Date(state.election_config.date_end).getTime();

          if(that.voted) {
            that.button_visible = true;
            if(now>election_start) {
              that.label = 'Voting Ends In';
              that.date_end = election_end;
            }
            if(now>election_end){
                that.label = 'ENDED';
                //  that.button_visible = true;
            }
          } else {
             if(that.election_registered) {
                if(now>=election_end){
                    that.label = 'ENDED';
                    that.button_visible = true;
                }
                else if(now<election_start)
                {
                    that.label = 'Voting Starts In';
                    that.date_end = election_start;
                    that.button_visible = true;
                  
                }
                else if(now>=election_start)
                {
                    that.date_end = election_end;
                    that.label = 'Voting Ends In';
                    that.button_text = "VOTE NOW";
                    that.button_function = 2;
                  if(that.can_vote && now<election_end ){
                    that.button_visible = false;
                  }else{
                    that.button_visible = true;
                  }
              }
                
          } else {
              
              if(now>election_end) {
                  that.label = 'ENDED';
                  that.button_visible = true;
              } else if(now<registration_start) {
                  that.button_visible = true;
                  that.label = 'Registration Starts In';
                  that.date_end = that.registration_start;
                  // that.button_text = "REGISTER";
                  // that.button_function = 1;
              } else if(now>registration_start) {
                  that.button_visible = false;
                  that.label = 'Registration Ends In';
                  that.date_end = election_end;
                  that.button_text = "REGISTER";
                  that.button_function = 1;
              } 
              // else if(now>registration_end) {
              //   if(now<election_start){
              //     that.button_visible = true;
              //     that.label = 'Voting Starts In';
              //     that.date_end = that.election_start;
              //   }else if(now>election_start){
              //     that.button_visible = true;
              //     that.label = 'Voting Ends In';
              //     that.date_end = that.election_end;
              //   }
              // }
          }
          }
          that.setButton();
          that.setClock();
          that.loading = false;
          // that.show_timer=true;
      },
      logout() {
        let that = this;
        that.loading = true;
      },
      donelogout() {
        let that = this;
        that.loading = false;
      },
      setButton(){
        let that = this;
        let now = new Date().getTime(that.server_time);
        let election_start = new Date(that.date_start).getTime();

        if(!that.voting_ended) {
          if(!that.election_registered) {
            that.button_text = "REGISTER";
            // that.button_visible = false;
            that.button_function = 1;
          }
          else if(that.election_registered && that.can_vote) {
            that.button_text = "VOTE NOW";
            that.button_visible = false;
            that.button_function = 2;
          }
          else if(that.election_registered && !that.can_vote){
            that.button_visible = true;
          }
          if(!that.election_registered && !that.can_vote){
              that.button_text = "REGISTER";
              // that.button_visible = false;
              that.button_function = 1;
          }
          // console.log(now<election_start);
          else if(that.election_registered && (election_start>now)) {
            that.button_visible =true;
          }
          else if(that.voted) {
            that.button_visible = true;
          }
          // if(!that.can_vote) {
          //   that.button_visible = true;
          // }
          }else{
            that.button_visible = true;
            that.label = "Ended";
          }
        that.show_timer = true;
      },
      showMenu() {
        if(this.show_menu){
          this.hideMenu();
        } else {
          this.show_menu = true;
        }
      },
      hideMenu(){
        this.show_menu = false;
      },
      vote(n)
      {
        let that= this;
        const cookie = that.$cookies;
        let payload = {
            vote:n,
        }
        that.button_visible = false;
        that.voted = true;
        that.loading = true;
        that.show_poll_modal = false;
        election.pollVote(cookie.get('user').uuid,payload).then(function() {
            that.success = true;
            that.modal_message = 'Your vote has been successfully cast. Thank you for participating in our Special General Assembly';
            that.show_message_modal = true;
            that.loading= false;
            that.setButton();
        }).catch(function(error) {
            if(error.response.status == 403){
              that.expired = true;
              that.modal_message = "Please Log in Again.";
            }else{
              that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            
        });
        
      },
      click() {
        let that= this;
        let store = that.$store;
        let state = store.state;

        // const cookie = that.$cookies;
        // console.log(that.button_function);
        if(that.button_function == 1 ){
            that.register();
        }
        if(that.button_function == 2){
          // that.showPollModal();
          state.user.skip=false;
          that.loading = true;
          that.$router.push("/election/voting");
          
          // that.setButton();
        }
        
      },
      requestOtp() {
            let that = this;
            const store = that.$store;
            const state = store.state;
            var payload = {
                email:state.credentials.email,
                mobile:state.credentials.mobile,
                name:state.credentials.first_name,
                pin:state.credentials.pin,
                type:5
            }
            that.loading = true;
             OTP.requestOtp(payload).then(function(response) {
                var request_otp = response.data.data;
                state.credentials.otp = request_otp.otp;
                state.credentials.pin = request_otp.pin;
                that.loading = false;
            }).catch(function(response) {
                that.loading = false;
                that.button_visible = true;
                that.voted = true;
                that.error = true;
                that.show_message_modal = true;
                that.modal_message = response.data.message;
            });
           
        },
      register(){
        let that= this;
        // const cookie = that.$cookies;
        const store = that.$store;
       let state = store.state;
        that.loading = true;
        election.GaRegister({hash:state.credentials.hash},that.ga_series).then(function(response) {
              that.loading = false;
              if(that.can_vote) {
                  state.election_config.election_registered = true;
                  that.election_registered = true;
                // that.show_poll_modal = true;
                  that.success = true;
                  that.show_message_modal = true;
                  that.modal_message = response.data.data.message;
                  that.setSchedule();
                  // if(response.data.data.status == 0) {
                  //   that.requestOtp();
                  // }
               } else {
                that.button_visible = true;
                that.voted = true;
                that.success = true;
                that.show_message_modal = true;
                that.modal_message = response.data.data.message;
              }
        }).catch(function(error) {
            if(error.response.status == 403) {
              that.expired = true
              that.modal_message = "Please Log in Again";
            }else{
              that.modal_message = error.response.data.message;
            }
              that.loading = false;
              that.error = true;
              that.show_message_modal = true;
              
              that.modal_type = 0;
        });
      },
      setNavbar(){
            let that = this;
            const store = that.$store;
            let state = store.state.credentials;
            
            that.name = state.username;
      },
      showAmendments() {
         this.show_pdf = true;
      },
      showPollModal(){
        this.show_poll_modal = true;
      },
      closedPoll(){
        this.show_poll_modal = false;
      },
      closed(){
        let that = this;
        let store = that.$store;
        let state = store.state;
        if(that.expired) {
          that.$cookies.remove('user');
          that.$cookies.remove('user_session');
          that.$router.push('/');
        }
        if(!state.credentials.has_pin) {
          that.$router.push('/user/settings');
        }
        that.show_message_modal = false;
      },
      closedAmendments(){
        this.show_pdf = false;
      },
      closePdf() {
        this.show_pdf = false;
      },
      setClock() {
      const that = this;
      
      let t = setInterval(function () {
        const route = that.$route.path.toLowerCase();

        if(route == '/dashboard')
        {
          let now = new Date().getTime(that.server_time);
          let date = new Date(that.date_end).getTime();
          let delta = date - now;

          that.days = Math.floor(delta / (1000 * 60 * 60 * 24));
          that.hour = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          that.minute = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((delta % (1000 * 60)) / 1000);
          
          if(that.days < 10) {
              that.days = '0'+ that.days;
          }
           if(that.hour < 10) {
              that.hour = '0'+ that.hour;
          }
           if(that.minute < 10) {
              that.minute = '0'+ that.minute;
          }

          if(seconds == 60) 
          {
            seconds = 0;
          }
          if(seconds < 10) {

            that.seconds = '0' + seconds;

          } else {

            that.seconds = seconds;

          }
          if(that.days == '00' && that.hours == '00' && that.minutes == '00' && that.seconds == '00') {
              
              if(that.voting_ended) {
                that.days = '00';
                that.hour = '00';
                that.minute = '00';
                that.seconds = '00';
              }
          }
          if(seconds < 0) {
              that.days = '00';
              that.hour = '00';
              that.minute = '00';
              that.seconds = '00';
              that.setSchedule();
              // that.setButton();
              clearInterval(t);
          }
          else if(seconds == 0){
            that.setSchedule();
            // that.setButton();
            clearInterval(t);
          }
        }
      }, 1000);
    },
    },
   
})
</script>
<style scoped>
.modal_max_width{
  max-width: 200px;
}
.contact-w {
    width: 100px !important;
    min-width: 120px !important;
}
.strip-banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.timer{
  margin: auto !important;
}
.banner-size-md{
  height: 500px;
}
.banner-size-sm{
  height: 180px ;
}
/* .margin-top-menu {
  margin-top: 73px;
} */
 /* HTML: <div class="loader"></div> */
.loader {
  width: 100%;
  height: 20px;
  background: 
    linear-gradient(90deg,#019E00 33%,#016B00 50%,#019E00 66%)
    #f2f2f2;
  background-size:300% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0% {background-position: right}
}

</style>
