import services from "../service";

export default {
    getSession(ssuid : string) {
        return services.get('/user/session', {
            ssuid: ssuid,
        });
    },
    login(username: string, password: string) {
        return services.post('/user/login/validate', {
            username: username,
            password: password,
            type: 1,            
        });
    },
    authentication(payload) {
        return services.post('/user/login' ,payload);
    },
    testHash(hash: string) {
        return services.get('/user/authenticate?hash='+hash);
    },
    recoverSession(hash: string){
        return services.post('/user',{hash:hash});
    },
    revertAskOtp(uuid: string){
        return services.put('/user/'+ uuid +'/revert');
    },
    logout(payload: any) {
        return services.delete('/user/logout',payload);
    },
    verifyACcount(uuid: string){
        return services.post('/user/'+uuid+'/verify');
    },
    editUserName(uuid: string, payload: object) {
        return services.put('/user/'+ uuid +'/username',payload);
    },
    editEmail(uuid: string, payload: object) {
        return services.put('/user/'+ uuid +'/email',payload);
    },
    validateEmail(uuid,payload){
        return services.post('/user/'+ uuid +'/email',payload);
    },
    validateMobile(uuid,payload){
        return services.post('/user/'+ uuid +'/mobile',payload);
    },
    editMobile(uuid: string, payload: object) {
        return services.put('/user/'+ uuid +'/mobile',payload);
    },
    signup(obj : object) {
        return services.post('/user/register/validate', obj);
    },
    signupResend(name : string, email : string, mobile : string) {
        return services.post('/user/register/resend', {
            name: name,
            email: email,
            mobile: mobile,
        });
    },
    createUser(payload) {
        return services.post('/user/register', payload);
    },
    forgotPassword(info : string) {
        return services.post('/user/recover', {
            value: info,
        });
    },
    recovery(payload,values){
        return services.post('/user/recover/'+payload.uuid, {
           hash: payload.hash,
           pin:  payload.pin,
           value: values
        });
    },
    forgotPasswordResend(uuid : any) {
        return services.post('/user/forgot-password/resend', {
            uuid: uuid,
        });
    },
    updatePassword(payload, uuid : string) {
        return services.put('/user/recover/'+uuid,payload);
    },
    changePassword(payload : object, uuid : string) {
        return services.put('/user/'+uuid+'/password',payload);
    },
    getuserInfo(payload) {
        return services.get('/user/'+payload.uuid+'?hash='+payload.hash);
    },
    validatePassword(payload: object,uuid : string) {
        return services.post('/user/'+uuid+ '/password', payload);
    },
    deactivate(uuid:string,payload) {
        return services.post('/user/'+uuid+'/settings/deactivate',payload);
    },
    deactivateConfirm(uuid:string,payload) {
        return services.delete('/user/'+uuid+'/settings/deactivate',payload);
    },
    validateOldPin(uuid:string,payload){
        return services.post('/user/'+uuid+'/pin',payload);
    },
    reactivateRequest(uuid:string,payload){
        return services.post('/user/'+uuid+'/settings/activate',payload);
    },
    testPin(payload){
        return services.post('/user/pin',payload);
    },
    reactivate(uuid:string) {
        return services.post('/user/'+uuid);
    },
    reactivateConfirm(uuid:string,payload){
        return services.put('/user/'+uuid+'/settings/activate',payload);
    },
    rating(uuid:string,payload) {
        return services.post('/support/'+uuid+'/rating',payload);
    },
    testData(payload){
        return services.post('/user/test',payload);
    },
    updateTin(uuid,payload){
        return services.put('/user/'+uuid + '/tin', payload)
    },
    updatePin(uuid,payload){
        return services.put('/user/'+uuid + '/pin', payload)
    },
    enrollPin(payload){
        return services.post('/user/register/enroll',payload);
    },
    enrollPinlogged(uuid,payload){
        // console.log(uuid,payload);
        return services.post('/user/'+uuid+'/pin/enrollment',payload);
    },
}
