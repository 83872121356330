import axios from "axios";
import store from "./store/index";

axios.defaults.params = {};
axios.defaults.params.token = "80d217376d9cf686c72433a0ef5f5696";

export default {

    /**
     * Current version of the api.
     * 
     * @var string
     */
    version: "v2",
    /**
     * Base URL of the api.
     * 
     * @var string
     */
    baseUrl: "https://cloud.pafcpic.net/api/",
    // baseUrl: "https://dev.cloud.pafcpic.net/api/",
    // baseUrl: "http://127.0.0.1:8000/api/",
    /**
     * Generate an api endpoint.
     * 
     * @param path string
     * @returns string
     */
    endpoint(path : string) : string {
        if(path.substring(0, 1) == "/")
        {
            path = path.substring(1, path.length);
        }
        // console.log("here");
        if(store.state.credentials.hash !=''){
            axios.defaults.params.hash = store.state.credentials.hash;
            axios.defaults.params.token = "80d217376d9cf686c72433a0ef5f5696";
        }else{
            axios.defaults.params = {};
            axios.defaults.params.token = "80d217376d9cf686c72433a0ef5f5696";
        }
        return this.baseUrl + this.version + "/" + path;
    },

    /**
     * Make a GET request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    get(path : string, params = {}) {
        return axios.get(this.endpoint(path),{
            params:params,
            withCredentials: true,
        });
    },

    getAddress(path : string) {
        return axios.get(path);
    },
    /**
     * Make a POST request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    post(path : string, params = {}) {
        return axios.post(this.endpoint(path), params);
    },

    /**
     * Make a PUT request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    put(path : string, params = {}) {
        return axios.put(this.endpoint(path),params);
    },

    /**
     * Make a PATCH request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    patch(path : string, params = {}) {
        return axios.patch(this.endpoint(path), {
            params: params,
            withCredentials: true,
        });
    },

    /**
     * Make a DELETE request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    delete(path : string, params = {}) {
        return axios.delete(this.endpoint(path),{
            data: params,
        });
    },

    getbody(path : string, params = {}) {
        return axios.get(this.endpoint(path),{
            params:params,
            withCredentials: true,
        });
    },
}
