<template>
    <footer class="w-full bg-theme-primary p-2 pl-6 pr-6 h-14 flex flex-wrap z-40 margin-top-footer place-content-center">
        <span  v-text="copy_right_text" class="self-center text-yellow-500 font-bold"/>
        <div class="grow xs:hidden" />
        <div class="space-x-3 self-center cursor-pointer text-yellow-500">
            <span v-for="(item,index) in clickables" :id="item+index" :key="index" v-text="item" @click="clicked(item)" class="hover:underline"/>
        </div>
    </footer>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props:{
        copy_right_text:{
            type:String,
            required:true,
        },
        clickables:{
            type:Array,
            required:false,
        }
    },
    data(){
        return {
            version:'2.01.55'
        }
    },
    methods:{
        clicked(item) {
            this.$emit('clicked',item);
        }
    }
})
</script>
<style scoped>
.margin-top-footer {
    bottom:0 !important;
}
</style>
