<template>
    <div class="w-full h-full flex flex-col">
        <Loading v-show="loading" :loading="loading"/>
    <NavBarView class="h-14 " special :user_name="this.username" @showMenu="showMenu"  @logout="logout" @donelogout="donelogout"/>
    <div class="w-full h-full m-auto mt-10 space-y-5 max-w-7xl">
        <div class="bg-white  border rounded shadow-lg  flex flex-col h-fit relative mt-20">
                <div class="relative w-full flex flex-row p-5  xs:flex-col ">
                    <div class="relative w-full flex flex-col xs:max-w-[100%] max-w-xs p-2 bg-theme-primary mr-4">
                        <div class="border border-black mb-2 shadow self-center mt-6">
                            <div class="w-full  bg-theme-primary ">
                                <img :src="getQr()" width="180"/>
                            </div>                
                        </div>
                          <!-- <div class="w-full flex justify-center text-xl text-white">
                            <span v-text="'WITH PAPSI ONLINE ACCOUNT '" class="font-bold"/>
                        </div> -->
                         <div class="w-full flex justify-center text-xl text-white">
                            <span v-text="'Serial No: ' + serial" class="font-bold"/>
                        </div>
                        <div class="w-full flex justify-center text-xl text-white">
                            <span v-text="'Account No: ' + account_number" class="font-bold"/>
                        </div>
                        
                        <div class="text-center mr-auto ml-auto text-lg p-2 border bg-white  rounded hover:bg-theme-primary hover:text-white hover:cursor-pointer border-g shadow mt-2" @click="downloadQR">
                                Download QR
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                    <div class="w-full p-5 flex flex-col rounded space-y-2 border mr-16  relative">
                        <div class="w-full border-b -mt-2 pb-2">
                            <span v-text="'Member Information'" class="font-bold text-lg"/>
                        </div>
                        <div class="border-b border-b-gray-400 w-full space-x-2 p-2">
                            <span v-text="'Full Name:'" class="text-gray-400"/>
                            <span v-text="this.full_name" class="font-bold"/>
                        </div>
                        <div class="border-b border-b-gray-400 w-full space-x-2 p-2" v-if="gender">
                            <span v-text="'Gender: '" class="text-gray-400" />
                            <span v-text="this.gender" class="font-bold"/>
                        </div>
                        <div class="border-b border-b-gray-400 w-full space-x-2 p-2" v-if="Astatus">
                        <span v-text="'Status: '" class="text-gray-400"/>
                        <span v-text="this.Astatus" class="font-bold"/>
                        </div>
                          <div class="border-b border-b-gray-400 w-full space-x-2 p-2" v-if="tin">
                            <span v-text="'Tin: '" class="text-gray-400" />
                            <span v-text="this.tin" class="font-bold"/>
                        </div>
                        <div class="border-b border-b-gray-400 w-full space-x-2 p-2" v-if="Membership">
                            <span v-text="'Membership: '" class="text-gray-400"/>
                            <span v-text="this.Membership" class="font-bold"/>
                        </div>
                        <div class="border-b border-b-gray-400 w-full space-x-2 p-2" v-if="Member_since">
                            <span v-text="'Member Since: '" class="text-gray-400"/>
                            <span v-text="Member_since" class="font-bold"/>
                        </div>
                    </div>
                    <div class="w-full p-5 flex flex-col rounded space-y-2 border xs:ml-0  mr-2 mt-2 relative">
                        <div class="w-full border-b -mt-2 pb-2">
                            <span v-text="'Contact Information'" class="font-bold text-lg"/>
                        </div>
                        <div class="border-b border-b-gray-400 w-full space-x-2 p-2" v-if="mobile">
                            <span v-text="'Mobile No.: '" class="text-gray-400"/>
                            <span v-text="this.mobile" class="font-bold"/>
                        </div>
                        <div class="border-b border-b-gray-400 w-full space-x-2 p-2" v-if="email">
                            <span v-text="'Email Address: '" class="text-gray-400" />
                            <span v-text="this.email" class="font-bold"/>
                        </div>
                    </div>
                    <div class="w-full p-5 flex flex-col rounded space-y-2 border mr-16 mt-2 relative">
                        <div class="w-full border-b -mt-2 pb-2">
                            <span v-text="'Account Information'" class="font-bold text-lg"/>
                        </div>
                        <div class="border-b border-b-gray-400 w-full space-x-2 p-2" v-if="username">
                            <span v-text="'Username:'" class="text-gray-400"/>
                            <span v-text="this.username" class="font-bold"/>
                        </div>
                        <div class="border-b border-b-gray-400 w-full space-x-2 p-2" v-if="registered">
                            <span v-text="'Registered: '" class="text-gray-400" />
                            <span v-text="this.registered" class="font-bold"/>
                        </div>
                    </div>
                    </div>
                    
                </div>
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue';
import Loading from '@/components/controls/LoadingControl.vue';
import User from "@/services/User";
import OTP from "@/services/OTP";
import axios from 'axios'
import NavBarView from '@/components/controls/NavBarView.vue';
export default defineComponent({
      components:{
        Loading,
        NavBarView
      },
      data(){
        return {
            account_number:'',
            full_name:'',
            gender:'',
            Astatus:'',
            Membership:'',
            Member_since:'',
            email:'',
            mobile:'',
            tin:'',
            qr:'',
            serial:'', 
            username:'',
            loading:false,
            registered:'',
            expired:false,
            error:false,
            success:false,
            warning:false,
            modal_message:'',
            show_message_modal:false,
    }
},
mounted(){
    const that = this;
    let store = that.$store;
    let state = store.state;
    if(state.credentials.express){
          that.$router.push('/user/settings');
    }
    if(state.credentials.deactivated){
        that.$router.push('/user/settings');
    }
    if(state.election_config.voted != null) {
            
           that.account_number = state.credentials.account_no;
            that.full_name = state.credentials.name;
            that.gender= state.credentials.gender;
            that.Astatus=state.credentials.status;
            that.Membership= state.credentials.membership_type;
            that.Member_since=state.credentials.membership_date;
            that.email=state.credentials.email;
            that.mobile=state.credentials.mobile;
            that.tin=state.credentials.tin;
            that.serial=state.credentials.serial_no; 
            that.username=state.credentials.username;
            that.registered=state.credentials.registered_date;
            that.qr = state.credentials.qr;

      } else {
        that.testHash();
      }
},
methods: {
    testHash() {
        let that = this;
       const cookie = that.$cookies;
       const store = that.$store;
       let state = store.state;
        that.loading = true;
        // console.log(cookie.get('user').hash);
        User.recoverSession(cookie.get('user_session').hash).then(function(response) {
            let data = response.data.data;  
            // that.loading = false;
            state.credentials.account_no=data.info.account_number;
            state.credentials.email=data.info.email;
            state.credentials.key=data.key;
            state.credentials.mobile=data.info.mobile;
            state.credentials.name=data.info.first_name + " "+ data.info.last_name;
            state.credentials.qr=data.qr;
            state.credentials.serial_no=data.info.serial_number;
            state.credentials.timestamp=response.timestamp;
            state.credentials.username=data.info.username;
            state.credentials.tin=data.info.tin;
            state.credentials.hash = data.hash;
            state.credentials.gender = data.info.gender;
            state.credentials.membership_type= data.info.membership_type;
            state.credentials.serial_no = data.info.serial_number;
             state.credentials.has_pin= data.has_pin;
             state.credentials.deactivated = data.deactivated;
            // state.credentials.membership_date = data.info.;
            state.credentials.registered_date= data.info.registered;
            that.loading = false;

            that.account_number = state.credentials.account_no;
            that.full_name = state.credentials.name;
            that.gender= state.credentials.gender;
            that.Astatus=state.credentials.status;
            that.Membership= state.credentials.membership_type;
            that.Member_since=state.credentials.membership_date;
            that.email=state.credentials.email;
            that.mobile=state.credentials.mobile;
            that.tin=state.credentials.tin;
            that.serial=state.credentials.serial_no==null?'??????':state.credentials.serial_no; 
            that.username=state.credentials.username;
            that.registered=state.credentials.registered_date;
            that.qr =data.qr;
            // console.log(data.info.mobile);
            // that.username = state.credentials.username;
            // that.currentUsername = state.credentials.username;
            // that.currentEmail = state.credentials.email;
            // that.currentMobile = state.credentials.mobile!=null?state.credentials.mobile.replace('+63',''):'';
            that.currentTin = state.credentials.tin;
            // console.log(that.currentMobile);
            that.express = data.express;
            that.deactivated = data.deactivated;
            // that.loading = false;
            if(data.express) {
                that.showUpdateEmailView();
            }
            if(data.deactivated) {
                that.showSecurity();
            }
            if(that.currentEmail || that.currentMobile){
                that.verify = false;
            }
            // that.getGaInfo(); 
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            if(error.response.status == 403) {
              that.expired = true
              that.modal_message = "Please Log in Again.";
            }
        });
    },
    // getUserInfo() {
    //     let that = this;
    //     const cookie = that.$cookies;
    //     const store = that.$store;
    //     //  let log = store.state.logged;
    //     let state = store.state;
    //     that.loading = true;
    //     User.getuserInfo(cookie.get('user')).then(function(response){
    //         let user_data = response.data.data;
    //         state.credentials.name = user_data.account.username;
    //         state.credentials.first_name=user_data.info.first_name;
    //         state.credentials.last_name=user_data.info.last_name;
    //         state.credentials.middle_name=user_data.info.middle_name;
    //         state.credentials.gender=user_data.info.gender;
    //         state.credentials.regular=user_data.info.regular;
    //         state.credentials.account_no = user_data.info.account_no;
    //         state.credentials.serial_no = user_data.info.serial_no;
    //         state.credentials.tin= user_data.info.tin;
    //         state.credentials.email = user_data.account.email;
    //         state.credentials.mobile = user_data.account.mobile;
    //         state.credentials.username = user_data.account.username;
    //         state.credentials.qr =user_data.qr;
    //         state.credentials.uuid = user_data.uuid;
    //         state.credentials.hash = cookie.get('user').hash;
    //         state.credentials.key = user_data.key;
    //         state.credentials.membership_date = user_data.info.membership_date;
    //         state.credentials.registered_date = user_data.account.registered;
    //         state.credentials.status = user_data.info.status;
    //         state.credentials.membership_type = user_data.info.membership_type;
    //         state.credentials.deactivated = user_data.account.deactivated;

    //         that.account_number = state.credentials.account_no;
    //         that.full_name = state.credentials.first_name +' '+state.credentials.middle_name +' '+state.credentials.last_name;
    //         that.gender= state.credentials.gender;
    //         that.Astatus=state.credentials.status;
    //         that.Membership= state.credentials.membership_type;
    //         that.Member_since=state.credentials.membership_date;
    //         that.email=state.credentials.email;
    //         that.mobile=state.credentials.mobile;
    //         that.tin=state.credentials.tin;
    //         that.serial=state.credentials.serial_no; 
    //         that.username=state.credentials.username;
    //         that.registered=state.credentials.registered_date;
    //         that.qr =user_data.qr;
    //         if(user_data.account.express){
    //             that.$router.push('/user/settings');
    //           }
    //           if(user_data.account.deactivated){
    //             that.$router.push('/user/settings');
    //           }
    //         that.loading = false;
    //     }).catch(function(error){
    //         that.error = true;
    //         that.loading = false;
    //         that.show_message_modal = true;
    //         that.modal_message = error.response.data.message;
    //     });
    // },
    // deactivateAccountTrigger() {
    //     this.updateType = 5; 
    //     this.modal_message = "Are you sure you want to deactivate your account?";
    //     this.warning = true;
    //     this.error = false;
    //     this.success = false;
    //     this.show_message_modal = true;
    // },
    submit(){
        let that = this;
        let store = that.$store;
        let state = store.state;
         const cookie = that.$cookies;
         if(!that.expired)
         {
            if(that.error || that.success){
            if(that.updateType == 5){
                cookie.remove('user');
                that.$router.push('/');
            }
                 that.show_message_modal = false;
            } else {
            if(that.updateType == 1){
                that.updateUsername();
            }
            if(that.updateType == 2){
                that.requestUpdateOtp();
            }
            if(that.updateType == 3){
                that.requestUpdateOtp();
            }
            if(that.updateType == 4){
                that.updatePassword();
            }
            // if(that.updateType == 5){
            //     that.deactivateAccount();
            // }
            if(that.updateType == 6){
                that.updateTin();
            }
            } 
         } else {
            if(that.expired) {
                state.credentials.hash = '';
                state.logged = false;
                that.expired = false;
                that.$cookies.remove('user');
                that.$router.push('/')
            }
         }
        
    },
    logout(){
        this.loading = true;
    },
    donelogout(){
        this.loading = false;
    },
    closed_modal() {
        this.show_message_modal = false;
    },
    updateUsernameTrigger() {
        this.updateType = 1; 
        this.modal_message = "Are you sure you want to update your username?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updateEmailTrigger() {
        this.otpType = '6';
        this.updateType = 2; 
        this.modal_message = "Are you sure you want to update your email?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updateMobileTrigger() {
        this.otpType = '7';
        this.updateType = 3; 
        this.modal_message = "Are you sure you want to update your mobile?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updateTinTrigger(){
        this.updateType = 6; 
        this.modal_message = "Are you sure you want to update your TIN?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updatePasswordTrigger(){
        this.updateType = 4; 
        this.modal_message = "Are you sure you want to update your password?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    showUpdateUsernameView() {
        if(this.showUpdateUsername == false) {
            this.showUpdateUsername = true;
            this.showUpdateEmail = false;
            this.showUpdateMobile = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    showUpdateEmailView() {
        if(this.showUpdateEmail == false) {
            this.showUpdateEmail = true;
            this.showUpdateUsername = false;
            this.showUpdateMobile = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    updateUsername() {
        const that = this;
        const cookie = that.$cookies;
        var payload = {
            hash: cookie.get('user').hash,
            value: that.username,
        }
        that.loading = true;
        that.currentUsername = '';
        that.show_message_modal = false;
        User.editUserName(cookie.get('user').uuid,payload).then(function() {
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your username.";
            that.show_message_modal= true;
            that.checkExistingCookie();
        }).catch(function(error){
              if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        }); 
    },
    updateTin(){
        const that = this;
        const cookie = that.$cookies;
        let tin = that.tin.replace('-','')
        let tin1 = tin.charAt(0) + tin.charAt(1) + tin.charAt(2);
        let tin2 = tin.charAt(3) + tin.charAt(4) + tin.charAt(5);
        let tin3 = tin.charAt(6) + tin.charAt(7) + tin.charAt(8);

        that.loading = true;
        that.currentTin = '';
        that.show_message_modal = false;

        User.updateTin(cookie.get('user').uuid,tin1,tin2,tin3).then(function() {
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your TIN.";
            that.show_message_modal= true;
            that.checkExistingCookie();
        }).catch(function(error){
             if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    updateEmail() {
        const that = this;
        const cookie = that.$cookies;
        var payload = {
            hash: cookie.get('user').hash,
            value: that.email,
        }
        that.loading = true;
        that.currentEmail = '';
        that.show_message_modal = false;
        User.editEmail(cookie.get('user').uuid,payload).then(function() {
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your email.";
            that.show_message_modal= true;
            that.checkExistingCookie();
        }).catch(function(error){
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
            that.modal_message = error.response.data.message;
        }); 
    },
    updateMobile() {
        const that = this;
        const cookie = that.$cookies;

        var payload = {
            hash: cookie.get('user').hash,
            value: '+63'+that.mobile_number,
        }
        that.loading = true;
        that.currentMobile = '';
        that.show_message_modal = false;
        User.editMobile(cookie.get('user').uuid,payload).then(function() {
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your mobile number.";
            that.show_message_modal= true;
            that.checkExistingCookie();
        }).catch(function(error){
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
            that.modal_message = error.response.data.message;
        }); 
    },
    updatePassword() {
        const that = this;
        const cookie = that.$cookies;
        
        var validationPayload = {
            hash: cookie.get('user').hash,
            password: that.old_password
        }
        that.loading = true;
        that.show_message_modal = false;
        User.validatePassword(validationPayload,cookie.get('user').uuid).then(function() {
               var updatingPasswordPayload = {
                    old_password:that.old_password,
                    new_password:that.new_password,
                    con_password:that.con_password,
               }
            User.updatePassword(updatingPasswordPayload,cookie.get('user').uuid).then(function() {
                that.success = true;
                that.warning = false;
                that.error = false;
                that.modal_message = "Congratulations you have successfully updated your password.";
                that.show_message_modal= true;
                that.checkExistingCookie();
            }).catch(function(error) {
                 if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
                that.error = true;
                that.warning = false;
                that.success = false;
                that.loading = false;
                that.show_message_modal= true;
            });
        }).catch(function(error){
              if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        }); 
    },
    // deactivateAccount() {
    //     const that = this;
    //     const cookie = that.$cookies;
        
    //     var validationPayload = {
    //         hash: cookie.get('user').hash,
    //         password: that.password_deact
    //     }
    //     that.loading = true;
    //     that.show_message_modal = false;
    //     User.deactivate(cookie.get('user').uuid,validationPayload).then(function(response) {
    //         let data = response.data.data
    //         // that.success = true;
    //         // that.warning = false;
    //         // that.error = false;
    //         // that.modal_message = "You have successfully deactivated your account.";
    //         // that.show_message_modal = true;
    //     }).catch(function(error) {
    //         if(error.response.status == 403){
    //             that.expired = true;
    //             that.modal_message = "Please Log in again.";
    //         }else{
    //             that.modal_message = error.response.data.message;
    //         }
    //         that.error = true;
    //         that.warning = false;
    //         that.success = false;
    //         that.loading = false;
    //         that.show_message_modal= true;
    //     });
    // },
    showUpdateMobileView() { 
        if(this.showUpdateMobile == false) {
            this.showUpdateMobile = true;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    showUpdatePasswordView() {
        if(this.showPasswordUpdateView == false ) { 
            this.showPasswordUpdateView = true;
            this.showUpdateMobile = false;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showSecurityView = false;
             this.showUpdateTin = false;
        }
    },
    showUpdateTinView(){
        if(this.showUpdateTin == false ) { 
            this.showUpdateTin = true;
            this.showPasswordUpdateView = false;
            this.showUpdateMobile = false;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showSecurityView = false;
        }
    },
    showSecurity() {
         if(this.showSecurityView == false ) { 
            this.showUpdateMobile = false;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = true;
            this.showUpdateTin = false;
        }
    },
    showAccountView() {
        if(this.showAccountInfoView == false ) { 
            this.showPasswordUpdateView = false;
            this.showAccountInfoView = true;
            this.showSecurityView = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    showMenu() {
        if(this.show_menu){
            this.hideMenu();
        } else {
            this.show_menu = true;
        }
    },
    hideMenu(){
        this.show_menu = false;
    },
    changed(e){
        let key = e.key;
        let that = this;
        that[key] = e.value;
        if(key =='mobile_number') {
            that.checkChange({value:e.value,type:1});
        }
        if(key =='email') {
            that.checkChange({value:e.value,type:2});
        }
    },
    checkChange(e) {
        if(e.type == 1){
            if(e.value != this.currentMobile){
                this.enableMobile = true;
            }else{
                this.enableMobile = false;
            }
        }
        if(e.type == 2){
            if(e.value != this.currentEmail){
                this.enableEmail = true;
            }else{
                this.enableEmail = false;
            }
        }
    },

    otpUpdate(e) {
      let that = this;
      that.otp = e.value;
    },
    downloadQR() {
          let url = this.getQr();
         axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'image/png' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'QR';
                link.click();
                URL.revokeObjectURL(link.href);
            })
    },
    resendOtp() {
        let that = this;
        that.loading = true;
        that.show_resend_otp = false;
        that.timerEnabled= true;
        that.timerCount = 120;
        let payload;
        if(that.otpType == '6'){
            payload =  {
              type:that.otpType, 
              email:that.currentEmail,
              name :that.full_name, 
              pin:that.pin,
            }
        }
         if(that.otpType == '7'){
            payload =  {
              type:that.otpType, 
              mobile:that.currentMobile,
              name :that.full_name, 
              pin:that.pin,
            }
        }
        OTP.requestOtp(payload).then(function(response) {
              that.pin = response.data.data.pin;
              that.loading= false;
              that.show_otp_modal = true;
          }).catch(function() {
              that.loading= false;
          });
      },
    requestUpdateOtp() {
        var payload = {}
        const that = this;
        that.show_message_modal = false;
        that.show_resend_otp = false;
        that.timerEnabled= true;
        that.timerCount = 120;

        if(that.otpType == '6') {
            payload = {
                type: that.otpType,
                email: that.currentEmail,
                name: that.full_name,
            }
        }
        if(that.otpType == '7') {
             payload = {
                type: that.otpType,
                mobile:that.currentMobile,
                name: that.full_name,
            }
        }
        that.loading = true;
        OTP.requestOtp(payload).then(function() {
            that.loading = false;
            that.show_otp_modal = true;
        }).catch(function(error){
             if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    submitOtp() {
      let that = this;
      that.loading =true;
      let payload;
      if(that.otpType == '6') {
         payload={
            otp:that.otp,
            type:that.otpType,
            email:that.currentEmail,
        }
      }
      if(that.otpType == '7') {
        payload={
            otp:that.otp,
            type:that.otpType,
            mobile:that.currentMobile,
        }
      }
     that.loading = true;
      OTP.validate(payload).then(function(){
        that.show_otp_modal= false;
        that.otp = '';
        if(that.otpType == '6') {
            that.updateEmail();
        }
        if(that.otpType == '7'){
            that.updateMobile();
        }
      }).catch(function(error) {
        that.error = true;
        that.loading = false;
        that.show_message_modal= true;
        that.modal_message = error.response.data.message;
      });
    },
    getQr() {
        let that = this;
        return "https://quickchart.io/qr?text=" + that.qr;
    },
},
watcher:{
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          this.show_resend_otp = false;
          setTimeout(() => {
            this.timerCount = this.timerCount - 1;
            // console.log(this.timerCount);
          }, 1000);
        } else {
          this.show_resend_otp = true;
          this.timerEnabled = false; // run your function here
        }
      },
      immediate: true
    },
}
})
</script>
<style scoped>
.max-width-back{
    max-width: 50px;
}
</style>
