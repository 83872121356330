<template>
    <div class="w-full flex">
        <!-- :note="this.warning" -->
         <Loading v-show="loading" :loading="loading" class="z-50"/>
         <div class="w-full flex flex-col  content-center">
            <Modal v-show="this.show_message_modal" 
            v-bind:display="this.show_message_modal"
            :error="this.error"
            :success="this.success"
            :modal_message="'test'"
            :question="this.warning"
            @positiveClick="submit"
            :positiveButton="this.success?'OK':this.error?null:'YES'"
            @negativeClick="closed_modal"
            :negativeButton="this.error?'OK':this.success?null:'NO'"
            z_value="z-50"
            :default_width="'max-w-lg'"
            id='vote_modal'>
                <div class="w-full p-5 pt-2 flex flex-col text-gray-500 text-lg ">
                {{ this.modal_message}}
                </div>
                <!-- <div class="flex flex-row space-x-1 p-3">
                    <Button :button_text="" @clicked="submit"/>
                    <Button :button_text="'NO'" v-if="this.warning" @clicked="" :theme="'bg-red-700'"/>
                </div> -->
            </Modal>
            <!-- show_new_pin -->
            <Modal v-show="show_new_pin" 
                v-bind:display="show_new_pin"
                z_value="z-40"
                :title="'UPDATE PIN'"
                @positiveClick="updatePin"
                positiveButton="UPDATE"
                closable
                :default_width="'max-w-[480px]'"
                id='vote_modal'>
                <!-- <div class="pb-2 bg-theme-primary text-yellow-500 font-bold rounded-t-sm text-xl uppercase text-left p-3">
                   
                </div> -->
                <div class="pb-2 font-bold uppercase text-left p-2 flex flex-row space-x-2">
                    <span v-text="'NEW PIN'"/>
                </div>
                  <!-- @on-change="handleOnChangePin" -->
                <div class="pb-2 w-full justify-items-center font-bold flex flex-row uppercase text-left p-2">
                     <v-otp-input
                        :is-disabled="false"
                        ref="new_pin_input"
                        input-classes="otp-input"
                        :conditionalClass="['1', '2', '3', '4', '5', '6']"
                        separator=" "
                        :input-type="new_pin_type"
                        inputmode="numeric"
                        :num-inputs="6"
                        v-model:value="new_pin"
                        :should-auto-focus="true"
                        :should-focus-order="true"
                        :placeholder="['*', '*', '*', '*', '*', '*']"
                    />
                  <Icons id="textbox_icon_con" class="content-center" v-bind:source="getImgSrcNewPin()" @click="togglePeekerUpdatePin(1)" :width="32" />
                    <!-- <TextBoxField @changed="otpUpdate" id="otp" type="text"/> -->
                </div>
                <div class="pb-2 font-bold uppercase text-left p-2 flex flex-row space-x-2">
                    <span v-text="'CONFIRM PIN'"/>
                </div>
                  <!-- @on-change="handleOnChangePin" -->
                <div class="pb-2 w-full justify-items-center font-bold flex flex-row uppercase text-left p-2">
                     <v-otp-input
                        :is-disabled="false"
                        ref="con_pin_input"
                        input-classes="otp-input"
                        :conditionalClass="['1', '2', '3', '4', '5', '6']"
                        separator=" "
                        :input-type="con_pin_type"
                        inputmode="numeric"
                        :num-inputs="6"
                        v-model:value="con_pin"
                        :should-auto-focus="true"
                        :should-focus-order="true"
                        :placeholder="['*', '*', '*', '*', '*', '*']"
                    />
                    <Icons id="textbox_icon_con" class="content-center" v-bind:source="getImgSrcConPin()" @click="togglePeekerUpdatePin(2)" :width="32" />
                    <!-- <TextBoxField @changed="otpUpdate" id="otp" type="text"/> -->
                </div>
                <div class="flex flex-row space-x-1 p-3">
                    <span v-text="pin_error_message" class="text-red-500" v-show="error_pin"/>
                    <!-- <Button :button_text="'UPDATE'" @clicked="submit"/> -->
                    <!-- <Button :button_text="'NO'" v-if="this.warning" @clicked="closed_modal" :theme="'bg-red-700'"/> -->
                </div>
            </Modal>
            <!-- show_otp_modal -->
            <Modal v-show="show_otp_modal" 
            v-bind:display="show_otp_modal"
            :z_value="'z-40'"
            :closable="true"
            :closed="closedOtp"
            :default_width="'max-w-lg'"
            id='otp_modal'>
                <div class="pb-2 text-sm italic text-left p-2">
                    Please enter the One-Time PIN (OTP) sent to your email address or mobile number to verify your identity.
                </div>
                <div class="pb-2 font-bold uppercase text-left p-2">
                 Enter OTP
                </div>
                  <!-- @on-change="handleOnChangePin" -->
                <div class="pb-2 w-full justify-items-center font-bold uppercase text-left p-2">
                     <v-otp-input
                        :is-disabled="false"
                        ref="otp_input"
                        input-classes="otp-input"
                        :conditionalClass="['1', '2', '3', '4', '5', '6']"
                        separator=" "
                        :input-type="'number'"
                        inputmode="numeric"
                        :num-inputs="6"
                        @on-complete="handleOnCompleteEmail"
                        v-model:value="otp"
                        :should-auto-focus="true"
                        :should-focus-order="true"
                        :placeholder="['*', '*', '*', '*', '*', '*']"
                    />
                    <!-- <TextBoxField @changed="otpUpdate" id="otp" type="text"/> -->
                </div>
                <div class="pb-2 font-bold uppercase text-left p-2" >
                    <span v-text="'RESEND IN ('+timerCount+')'" class="text-theme-primary" v-show="!show_resend_otp"/>
                    <LinkText emit_triggered='resendOtp' @resendOtp="resendOtp" v-show="show_resend_otp" class="text-theme-primary font-bold" text="Resend"/>
                </div>
                <div class="pb-4">

                </div>
            </Modal>
        <NavBarView class="h-14 fixed" special :user_name="this.username" @showMenu="showMenu" @logout="logout" @donelogout="donelogout"/>
            <div class="w-full  flex xs:flex-col  m-auto mt-24 max-w-7xl">
                <div class=" flex w-full md:max-w-sm flex-col xs:flex-col text-lg xs:mt-12  xs:mr-2 xs:ml-2 xs:mb-2 space-y-2">
                    <div @click="showUpdateUsernameView" 
                        :class="[{'border rounded bg-[#016B00] text-yellow-500 border-theme-primary shadowed  font-bold': showUpdateUsername},{'hidden':express || deactivated || no_pin}]"
                        class="w-full bg-white border shadow-lg   text-black rounded p-5 cursor-pointer hover:text-yellow-500 hover:bg-theme-primary hover:font-bold">
                        <span v-text="'ACCOUNT INFORMATION'" />
                    </div>
                    <div @click="showUpdateEmailView" class="w-full border bg-white shadow-lg  text-black rounded p-5 cursor-pointer hover:text-yellow-500  hover:bg-theme-primary hover:font-bold"
                        :class="[{'border bg-[#016B00] text-yellow-500 rounded border-theme-primary shadowed font-bold': showUpdateEmail},{'hidden': deactivated || no_pin}]">
                        <span v-text="'CONTACT INFORMATION'" />
                    </div>
                    <!-- <div @click="showUpdatePasswordView" class="w-full border bg-white shadow-lg  text-black rounded p-5 cursor-pointer hover:text-yellow-500 hover:bg-theme-primary hover:font-bold"
                        :class="[{'border bg-[#016B00] text-yellow-500 rounded border-theme-primary shadowed font-bold': showPasswordUpdateView},{'hidden':express || deactivated || !has_pin}]">
                        <span v-text="'MEMBER INFORMATION'" />
                    </div> -->
                    <div @click="showSecurity" class="w-full border bg-white shadow-lg  text-black rounded p-5 cursor-pointer hover:text-yellow-500 hover:bg-theme-primary hover:font-bold"
                        :class="[{'border bg-[#016B00] text-yellow-500 rounded border-theme-primary shadowed font-bold':showSecurityView},{'hidden':express}]">
                        <span v-text="'SECURITY'" />
                    </div>
                </div>
            <div class=" w-full rounded flex flex-row xs:pb-16  pl-2">
                <div class="w-full h-full">
                    <div v-show="showUpdateUsername" class="w-full h-full" >
                    <div class="flex flex-col w-full space-y-2  xs:p-0">
                        <div class="border flex-col bg-white rounded shadow-md flex justify-center h-full ">
                            <div class="text-xl font-bold bg-theme-primary rounded-t-md border-b p-3">
                                <span v-text="'EDIT USERNAME'" class="w-full text-yellow-500" style="text-shadow: 0.10em 1.5px black"/>
                            </div>
                            <div class="w-full h-full flex flex-row pt-5 p-7 ">
                                <div class="text-md w-full flex flex-col space-y-2" v-if="showUpdateUsername" >
                                    <div>
                                        <span v-text="'USERNAME'" class="w-full" />
                                    </div>
                                    <div class="w-full flex xs:flex-col space-x-1 items-center">
                                        <TextBoxField @changed="changed" v-if="currentUsername" :initial="currentUsername" id="username" class="w-full"/> 
                                        <Button rounded @clicked="updateUsernameTrigger" :disabled="enableUsername" button_text="UPDATE" :loading="this.updatingUsername"  class="xs:self-end xs:mr-0 max-w-xxs "/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border rounded bg-white shadow-md flex flex-col justify-center h-full ">
                            <div class="text-xl font-bold bg-theme-primary rounded-t-md border-b p-3">
                                <span v-text="'EDIT PASSWORD'" class="w-full text-yellow-500"  style="text-shadow: 0.10em 1.5px black"/>
                            </div>
                            <div class="w-full h-full flex flex-col pt-5 p-7">
                                <form class="text-md w-full flex flex-col space-y-2" >
                                    <div>
                                        <span v-text="'OLD PASSWORD'" class="w-full" />
                                    </div>
                                    <div class="w-full flex">
                                    <TextBoxField id="old_password" peekable @changed="changed" type="Password"/>
                                    </div>
                                    <div>
                                        <span v-text="'NEW PASSWORD'" class="w-full" />
                                        
                                    </div>
                                    <div class="w-full flex flex-col">
                                         <span v-text="'Your password must be at least eight characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.'" class="w-full text-gray-400"/>
                                        <TextBoxField id="new_password" strength peekable @score="checkScore" @changed="changed" type="Password"/>
                                    </div>
                                    <div>
                                        <span v-text="'CONFIRM PASSWORD'" class="w-full" />
                                    </div>
                                    <div class="w-full flex">
                                        <TextBoxField id="con_password" peekable @changed="changed" type="Password"/> 
                                    </div>
                                    </form>
                                    <div class="flex  float-right pt-2 w-full self-end">
                                        <Button rounded @clicked="updatePasswordTrigger" :disabled="disablePassword" class="w-full max-w-xxs" button_text="UPDATE" :loading="this.updatingPassword"  />
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="showUpdateEmail" class="gap-x-5 gap-y-5 h-full">
                    <div class="border flex-col bg-white rounded shadow-md flex justify-center">
                        <div class="text-xl font-bold bg-theme-primary rounded-t-md border-b p-3">
                            <span v-text="'EDIT CONTACT INFORMATION'" class="w-full text-yellow-500" style="text-shadow: 0.10em 1.5px black"/>
                        </div>
                        <div class="w-full h-full flex flex-row pt-5 p-7" >
                            <div class="text-md w-full flex flex-col space-y-2"  v-if="!express">
                                <div v-if="express">
                                    <span v-text="'PLEASE UPDATE AND VERIFY YOUR EMAIL OR MOBILE NUMBER'" class="w-full text-red-500 italic" />
                                </div>
                                <div>
                                    <span v-text="'EMAIL ADDRESS'" class="w-full" />
                                </div>
                                <div class="w-full flex xs:flex-col items-center space-x-1" v-if="currentEmail">
                                    <TextBoxField @changed="changed" :initial="this.currentEmail" type="email" id="email"/> 
                                    <Button rounded @clicked="updateEmailTrigger" :disabled="!enableEmail" button_text="UPDATE" :loading="this.updatingEmail" class="xs:self-end xs:mr-0 max-w-xxs"/>
                                </div>
                                <div>
                                    <span v-text="'MOBILE NUMBER'" class="w-full" />
                                </div>
                                <!--  -->
                                <div class="w-full flex xs:flex-col items-center space-x-1" v-if="currentMobile">
                                    <TextBoxField nospecialchar @changed="changed" :initial="currentMobile" type="mobile" id="mobile_number" :max_length="9"/>
                                    <Button rounded @clicked="updateMobileTrigger" :disabled="!enableMobile" button_text="UPDATE" :loading="this.updatingMobile" class="xs:self-end xs:mr-0 max-w-xxs "/>
                                </div>
                            </div>
                            <div class="text-lg w-full flex flex-col"  v-if="express">
                                <div>
                                    <span v-text="'PLEASE UPDATE AND VERIFY YOUR EMAIL OR MOBILE NUMBER'" class="w-full text-red-500 italic" />
                                </div>
                                <div>
                                    <span v-text="'EMAIL ADDRESS'" class="w-full" />
                                </div>
                                <div class="w-full flex xs:flex-col" >
                                    <TextBoxField @changed="changed" :initial="currentEmail" type="email" id="email"/> 
                                    <Button rounded @clicked="updateEmailTrigger" :disabled="!enableEmail" button_text="UPDATE" :loading="updatingEmail" class="xs:self-end xs:mr-0 max-w-xxs ml-2 mr-2 mt-2"/>
                                </div>
                                <div>
                                    <span v-text="'MOBILE NUMBER'" class="w-full" />
                                </div>
                                <div class="w-full flex xs:flex-col" >
                                    <TextBoxField nospecialchar @changed="changed" :initial="currentMobile" type="mobile" id="mobile_number" :max_length="9"/>
                                    <Button rounded @clicked="updateMobileTrigger" :disabled="!enableMobile" button_text="UPDATE" :loading="updatingMobile" class="xs:self-end xs:mr-0 max-w-xxs ml-2 mr-2 mt-2"/>
                                </div>
                                <div class="w-full flex xs:flex-col" v-if="express">
                                    <Button rounded @clicked="verifyAccount" :disabled="verify" button_text="VERIFY" class="xs:self-end xs:mr-0 max-w-xxs mt-2"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="showPasswordUpdateView">
                    <div class="flex-col bg-white rounded shadow-md flex h-full">
                        <div class="text-xl font-bold border-b p-3 rounded-t-md bg-theme-primary">
                            <span v-text="'EDIT MEMBER INFORMATION'" class="w-full text-yellow-500" style="text-shadow: 0.10em 1.5px black" />
                        </div>
                        <div class="w-full h-full flex flex-row pt-5 p-7" >
                            <div class="text-md w-full flex flex-col" >
                                <div>
                                    <span v-text="'TIN'" class="w-full" />
                                </div>
                                <div class="w-full flex items-center space-x-1" v-if="currentTin">
                                    <TextBoxField id="tin" :initial="currentTin" @changed="changed" type="text"/> 
                                    <Button rounded theme="error" button_text="UPDATE" v-if="!currentTin" :disabled="disable_tin" @clicked="updateTinTrigger" class="max-w-xxs"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="showSecurityView">
                    <div class="w-full" v-show="!deactivated && !no_pin">
                            <div class="w-full ">
                                <div class="text-xl font-bold max-w-xl bg-theme-primary rounded-t-md border-b p-3">
                                        <span v-text="'ACCOUNT DEACTIVATION'" class="w-full text-yellow-500" style="text-shadow: 0.10em 1.5px black"/>
                                </div>
                                <div class="border rounded shadow-md max-w-xl flex flex-row justify-center h-full p-8 ">
                                    <div class="w-full h-full flex flex-col space-y-2 -mt-3">
                                        <div class="text-lg w-full">
                                            <span v-text="'Password'" />
                                            <TextBoxField ref="Pass" type="Password" id="deac" peekable @changed="changed" required/>
                                        </div>
                                        <div class="flex ">
                                            <Button theme="bg-red-500" rounded @click="deactivateAccountTrigger" :button_text="'DEACTIVATE ACCOUNT'" :loading="this.updatingPassword"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="w-full pt-2" v-show="!deactivated && !no_pin">
                       <div class="text-xl max-w-xl font-bold bg-theme-primary rounded-t-md border-b p-3">
                                <span v-text="'UPDATE PIN'" class="w-full text-yellow-500" style="text-shadow: 0.10em 1.5px black"/>
                            </div>
                            <div class="border max-w-xl rounded shadow-md flex flex-row justify-center h-full p-8 ">
                                <div class="w-full h-full flex flex-col space-y-2 -mt-3 justify-items-center">
                                    <div class="text-lg space-x-2 w-full flex flex-row">
                                        <span v-text="'OLD PIN'" />
                                        <Icons id="textbox_icon" v-bind:source="getImgSrc()" @click="togglePeeker" :width="24" />
                                        <!-- <TextBoxField ref="Pass" type="Password" id="deac" peekable @changed="changed" required/> -->
                                    </div>
                                    <div class="w-full justify-center ">
                                    <v-otp-input
                                        :is-disabled="false"
                                        ref="old_pin_input"
                                        input-classes="otp-input"
                                        :conditionalClass="['1', '2', '3', '4', '5', '6']"
                                        separator=" "
                                        :input-type="old_pin_type"
                                        inputmode="numeric"
                                        :num-inputs="6"
                                        v-model:value="old_pin"
                                        :should-auto-focus="true"
                                        :should-focus-order="true"
                                        :placeholder="['*', '*', '*', '*', '*', '*']"
                                    />
                                    
                                    </div>
                                    <div class="flex ">
                                        <Button rounded :disabled="old_pin.length<1" @click="updatePinTrigger" :button_text="'CHANGE PIN'"/>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="w-full pt-2" v-show="no_pin">
                       <div class="text-xl max-w-xl font-bold bg-theme-primary rounded-t-md border-b p-3">
                                <span v-text="'ENROLL PIN'" class="w-full text-yellow-500" style="text-shadow: 0.10em 1.5px black"/>
                            </div>
                            <div class="border max-w-xl rounded shadow-md flex flex-row justify-center h-full p-8 ">
                                <div class="w-full h-full flex flex-col space-y-2 -mt-3 justify-items-center">
                                    <div class="text-lg w-full">
                                        <span v-text="'PIN'" />
                                        <!-- <TextBoxField ref="Pass" type="Password" id="deac" peekable @changed="changed" required/> -->
                                    </div>
                                    <div class="w-full justify-center">
                                    <v-otp-input
                                        :is-disabled="false"
                                        ref="pin_input"
                                        input-classes="otp-input"
                                        :conditionalClass="['1', '2', '3', '4', '5', '6']"
                                        separator=" "
                                        :input-type="'password'"
                                        inputmode="numeric"
                                        :num-inputs="6"
                                        v-model:value="enroll_pin"
                                        :should-auto-focus="true"
                                        :should-focus-order="true"
                                        :placeholder="['*', '*', '*', '*', '*', '*']"
                                    />
                                    </div>
                                    <div class="flex ">
                                        <Button rounded @click="enrollPin" :button_text="'ENROLL PIN'"/>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="w-full" v-show="deactivated">
                        <div class="border rounded shadow-md flex flex-row justify-center h-full p-10 ">
                            <div class="w-full h-full flex flex-col space-y-2">
                                <!-- <div class="text-lg w-full">
                                    <span v-text="'Account No.'" />
                                    <TextBoxField ref="Pass" type="text" id="reac" @changed="changed" required/>
                                </div> -->
                                <div class="flex ">
                                    <Button theme="bg-theme-primary" rounded @click="reactivateAccountTrigger" :button_text="'REACTIVATE ACCOUNT'" :loading="this.updatingPassword"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    </div>
    
</template>
<script>
import { defineComponent } from 'vue';
import TextBoxField from '@/components/controls/TextBoxField.vue';
import Button from '@/components/controls/ButtonControl.vue';
import Loading from '@/components/controls/LoadingControl.vue';
import User from "@/services/User";
import OTP from "@/services/OTP";
import axios from 'axios'
import Modal from '@/components/controls/ModalControl.vue';
import LinkText from "@/components/controls/LinkText";
import NavBarView from '@/components/controls/NavBarView.vue';
import VOtpInput from "vue3-otp-input";
import Icons from '@/components/controls/Icon.vue';
export default defineComponent({
      components:{
        TextBoxField,
        Button,
        Loading,
        Modal,
        Icons,
        LinkText,
        VOtpInput,
        NavBarView
      },
      data(){
        return {
            showUpdateUsername: true,
            showUpdateEmail: false,
            pin:'',
            enroll_pin:'',
            showUpdateMobile: false,
            enableMobile: false,
            enableEmail: false,
            showAccountInfoView: true,
            showPasswordUpdateView:false,
            showSecurityView: false,
            showDeactivateAccountView:false,
            showUpdateUsernameModal:false,
            showUpdateEmailModal:false,
            showUpdateMobileModal:false,
            showUpdatePasswordModal:false,
            showDeactivateAccountModal:false,
            showUpdateTin:false,
            show_new_pin:false,
            showSuccessModal: false,
            disablePassword: true,
            show_message_modal:false,
            showOtpModal: false,
            updatingUsername: false,
            updatingEmail: false,
            updatingMobile: false,
            disable_tin:false,
            updatingPassword:false,
            deactivatingAccount: false,
            message:'',
            otpType:'',
            otp:'',
            new_pin:'',
            con_pin:'',
            updateType:'',
            errors_log: [],
            currentUsername:'',
            currentEmail: '',
            verify:true,
            visible:false,
            currentMobile: '',
            account_number:'',
            has_pin:'',
            show_resend_otp:false,
            timerCount:0,
            timerEnabled:false,
            full_name:'',
            show_menu:false,
            email:'',
            number:'',
            new_pin_type:'password',
            con_pin_type:'password',
            visible_new_pin:false,
            visible_con_pin:false,
            Astatus:'',
            deac:'',
            reac:'',
            tin:'',
            Mstatus:'', 
            express:false,
            enableUsername:true,
            deactivated:false,
            serial:'', 
            loading:false,
            disablenum:true,
            disableemail:true,
            disableuser:true,
            username:'',
            old_pin:'',
            currentTin:'',
            old_password:'',
            show_new_pin_pass:false,
            show_con_pin_pass:false,
            new_password:'',
            con_password:'',
            pin_error_message:'',
            mobile_number:'',
            deactivate_payload:null,
            password_deact:'',
            error:false,
            success:false,
            warning:false,
            otp_pin:'',
            deactivation_pin:'',
            otp_token:'',
            score:'',
            modal_message:'',
            old_pin_type:'password',
            show_otp_modal:false,
            expired:false,
            otp_payload:null,
            error_pin:false,
            edit_payload:null,
            no_pin:false,
    }
},
mounted(){
    const that = this;
    let store = that.$store;
    let state = store.state;
    // that.show_new_pin = true;
        
        
        if(state.credentials.username != null) {
        that.currentUsername = state.credentials.username;
        that.currentEmail = state.credentials.email;
        that.currentMobile = state.credentials.mobile!=null?state.credentials.mobile.replace('+63',''):'';
        // that.currentMobile = state.credentials.mobile;
        that.has_pin = state.credentials.has_pin;
        that.currentTin = state.credentials.tin;
        that.deactivated = state.credentials.deactivated;
        that.express = state.credentials.express;
        if(that.currentTin==null && that.currentTin=='') {
            that.disable_tin = true;
        }
        if(that.deactivated){
            that.showSecurity();
        }
        if(that.express){
            that.showUpdateEmailView();
        }
        if(!state.credentials.has_pin) {
            that.no_pin=true;
            that.showSecurity();
        }else{
            that.no_pin=false;
        }
        }else{
         that.getUserInfo();
        }
       
},
methods: {
    // testHash() {
    //     let that = this;
    //     const cookie = that.$cookies;
    //     that.loading = true;
    //     User.testHash(cookie.get('user').hash).then(function(){
    //         that.getUserInfo();
    //     }).catch(function(error){
    //         that.error = true;
    //         that.loading = false;
    //         that.show_message_modal = true;
    //         that.modal_message = error.response.data.message;
    //         that.expired = true
    //     });
    // },
    // handleOnCompletePin(){

    // },
    checkScore(e){
        this.score= e;
    },
    enrollPin(){
        let that = this;
        const cookie = that.$cookies;
        let store = that.$store;
        let state = store.state;
        let payload = {
            hash:cookie.get('user_session').hash,
            pin:that.enroll_pin,
        };
        let payload_new_pin = {
            hash:cookie.get('user_session').hash,
            value:that.enroll_pin,
        }
        that.loading = true;
        User.testPin(payload).then(function() {
             User.updatePin(cookie.get('user').uuid,payload_new_pin).then(function(){
                that.success = true;
                that.updateType = 7;
                that.warning = false;
                state.credentials.has_pin = true;
                that.error = false;
                that.loading = false;
                that.show_message_modal = true;
                that.modal_message = "Congratulations! You have successfully enrolled your PIN";
               
                // that.$router.push('/Dashboard')
            }).catch(function(error){
                that.$refs.new_pin_input.clearInput();
                that.$refs.con_pin_input.clearInput();
                that.success = false;
                that.error = true;
                that.note = false;
                that.loading = false;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
            });
        }).catch(function(error){
            that.$refs.new_pin_input.clearInput();
            that.$refs.con_pin_input.clearInput();
            that.success = false;
            that.error = true;
            that.warning = false;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
        });
       
        // console.log(that.enroll_pin);
    },
    verifyAccount(){
        let that = this;
        let store = that.$store;
        let state = store.state;
        that.loading = true;
        User.verifyACcount(state.credentials.uuid).then(function(){
            that.express=false; 
            state.credentials.express=false;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = "YOUR ACCOUNT HAS BEEN VERIFIED THANK YOU!";
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            that.expired = true
        });
    },

    getUserInfo() {
       let that = this;
       const cookie = that.$cookies;
       const store = that.$store;
       let state = store.state;
        that.loading = true;
        // console.log(cookie.get('user').hash);
        User.recoverSession(cookie.get('user_session').hash).then(function(response) {
            let data = response.data.data;  
            // that.loading = false;
            state.credentials.account_no=data.info.account_number;
            state.credentials.email=data.info.email;
            state.credentials.key=data.key;
            state.credentials.mobile=data.info.mobile;
            state.credentials.name=data.info.first_name + " "+ data.info.last_name;
            state.credentials.qr=data.qr;
            state.credentials.serial_no=data.info.serial_number;
            state.credentials.timestamp=response.timestamp;
            state.credentials.username=data.info.username;
            state.credentials.tin=data.info.tin;
            state.credentials.hash = data.hash;
            state.credentials.gender = data.info.gender;
            state.credentials.has_pin= data.has_pin;
            state.credentials.membership_type= data.info.membership_type;
            state.credentials.serial_no = data.info.serial_number;
            // state.credentials.membership_date = data.info.;
            state.credentials.registered_date= data.info.registered;
            // that.no_pin = !data.info.has_pin;

            // console.log(data.info.mobile);
            that.username = state.credentials.username;
            that.currentUsername = state.credentials.username;
            that.currentEmail = state.credentials.email;
            that.currentMobile = state.credentials.mobile!=null?state.credentials.mobile.replace('+63',''):'';
            that.currentTin = state.credentials.tin;
            // console.log(that.currentMobile);

            that.express = data.express;
            that.deactivated = data.deactivated;
            that.loading = false;
            // that.loading = false;
            if(that.express) {
                that.showUpdateEmailView();
            }
            if(that.deactivated) {
                that.showSecurity();
            }
            if(that.currentEmail || that.currentMobile){
                that.verify = false;
            }
            // that.getGaInfo(); 
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            if(error.response.status == 403) {
              that.expired = true
              that.modal_message = "Please Log in Again.";
            }
        });
      },


    // getUserInfo() {
    //     let that = this;
    //     const cookie = that.$cookies;
    //     const store = that.$store;
    //     //  let log = store.state.logged;
    //     let state = store.state;
    //     that.loading = true;
    //     User.getuserInfo(cookie.get('user')).then(function(response){
    //         let user_data = response.data.data;

    //         //CREDENTIALS
    //         // state.credentials.name = user_data.account.username;
    //         state.credentials.first_name=user_data.info.first_name;
    //         state.credentials.last_name=user_data.info.last_name;
    //         state.credentials.middle_name=user_data.info.middle_name;
    //         state.credentials.gender=user_data.info.gender;
    //         state.credentials.regular=user_data.info.regular;
    //         state.credentials.account_no = user_data.info.account_no;
    //         state.credentials.serial_no = user_data.info.serial_no;
    //         state.credentials.tin= user_data.info.tin;
    //         state.credentials.email = user_data.account.email;
    //         state.credentials.mobile = user_data.account.mobile;
    //         state.credentials.username = user_data.account.username;
    //         state.credentials.qr =user_data.qr;
    //         state.credentials.uuid = user_data.uuid;
    //         state.credentials.hash = cookie.get('user').hash;
    //         state.credentials.key = user_data.key;
    //         state.credentials.deactivated = user_data.account.deactivated;

    //         that.username = state.credentials.username;
    //         that.currentUsername = state.credentials.username;
    //         that.currentEmail = state.credentials.email;
    //         that.currentMobile = state.credentials.mobile!=null?state.credentials.mobile.replace('+63',''):'';
    //         that.currentTin = state.credentials.tin;
    //         that.express = user_data.account.express;
    //         that.deactivated = user_data.account.deactivated;
    //         that.loading = false;
            
    //         if(user_data.account.express) {
    //             that.showUpdateEmailView();
    //         }
    //         if(user_data.account.deactivated) {
    //             that.showSecurity();
    //         }
    //         if(that.currentEmail || that.currentMobile){
    //             that.verify = false;
    //         }
    //     }).catch(function(error){
    //         that.error = true;
    //         that.loading = false;
    //         that.show_message_modal = true;
    //         that.modal_message = error.response.data.message;
    //     });
    // },
    deactivateAccountTrigger() {
        this.updateType = 5; 
        this.otpType = '3';
        this.modal_message = "Are you sure you want to deactivate your account?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    closedOtp(){
        this.show_otp_modal = false;
    },
    updatePinTrigger() {
        if(this.old_pin.length>1){
            this.updateType = 8; 
            // this.otpType = '3';
            this.modal_message = "Are you sure you want to change your PIN?";
            this.warning = true;
            this.error = false;
            this.success = false;
            this.show_message_modal = true;
        }
    },
    updatePin() {
        let that = this;
        const cookie = that.$cookies;
        // console.log(that.new_pin,that.con_pin);
        if(that.new_pin && that.con_pin) {
            if(that.new_pin == that.con_pin) {
                let pin_payload= {
                    hash:cookie.get('user_session').hash,
                    value:that.new_pin,
                    pin:that.old_pin
                }
                // User.updatePin();
                that.loading = true;
                that.error_pin=false;
                 User.updatePin(cookie.get('user').uuid,pin_payload).then(function() { 
                     that.show_new_pin=false,
                     that.loading = false;
                     that.success = true;
                     that.warning = false;
                     that.error = false;
                     that.modal_message = "Congratulations! You have successfully updated your PIN.";
                    //  that.getUserInfo();
                     that.show_message_modal= true;
                }).catch(function(error){
                    that.$refs.new_pin_input.clearInput();
                    that.$refs.con_pin_input.clearInput();
                    that.error = true;
                    that.warning=false;
                    that.success=false;
                    that.loading = false;
                    that.show_message_modal = true;
                    that.modal_message = error.response.data.message;
                });
            } else {
                that.error_pin= true;
                that.pin_error_message= "PIN Mismatch";
            }   
        } else {
            that.pin_error_message= "Dont leave the new pin/confirm pin empty"
            that.error_pin= true;
        }
    },

    reactivateAccountTrigger() {
        let that = this;
        // const store = this.$store;
        // let state = store.state;

        // if(this.reac == state.credentials.account_no) {
             that.updateType = 7; 
             that.otpType = '4';
             that.modal_message = "Are you sure you want to reactivate your account?";
             that.warning = true;
             that.error = false;
             that.success = false;
             that.show_message_modal = true;
        // }else{
        //     this.error = true;
        //     this.show_message_modal = true;
        //     this.modal_message = "Account number miss match";
        // }
       
    },
    submit(){
        let that = this;
        let store = that.$store;
        let state = store.state;
         const cookie = that.$cookies;
        //  console.log(that.updateType);
         if(!that.expired)
         {
            if(that.success) {
                
                if(that.updateType == 5){
                    cookie.remove('user');
                    cookie.remove('user_session');
                    that.$router.push('/');
                }
                if(that.updateType == 7){
                    that.$router.push('/Dashboard');
                }
                that.show_message_modal = false;
            }
            else if (that.error) 
            {
                that.show_message_modal = false;
            }
            else {
            if(that.updateType == 1){
                that.updateUsername();
            }
            if(that.updateType == 2){
                that.validateEmail();
            }
            if(that.updateType == 3){
                that.validateMobile();
            }
            if(that.updateType == 4){
                that.updatePassword();
            }
            if(that.updateType == 5){
                that.deactivateAccount();
            }
            if(that.updateType == 6){
                that.updateTin();
            }
            if(that.updateType == 7){
                that.reactivateRequest();
            }
            // console.log(that.updateType);
            if(that.updateType == 8){
                that.validateOldPin();
            }
            } 
         } else {
            if(that.expired) {
                state.credentials.hash = '';
                state.logged = false;
                that.expired = false;
                that.$cookies.remove('user');
                that.$router.push('/')
            }
         }
        
    },
    reactivateRequest(){
        let that = this;
        // let store = that.$store;
        // let state = store.state;
        const cookie = that.$cookies;
        that.loading = true;
        // console.log(cookie.get('user_session').hash);
        that.show_message_modal = false;
        User.reactivateRequest(cookie.get('user').uuid,cookie.get('user_session').hash).then(function(response){
            let data = response.data.data;
            that.otp_payload = {
                reactivation_key:data.hash,
                pin:data.pin,
                email:that.email,
                type:'4',
                mobile:that.mobile_number
            };
            // console.log(that.otp_payload);
            that.$refs.otp_input.clearInput();
            that.show_otp_modal = true;
            that.loading = false;
        }).catch(function(error){
            that.error = true;
            that.warning=false;
            that.success=false;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
        });
    },
    validateOldPin(){
        let that = this;
        // let store = that.$store;
        // let state = store.state;
        const cookie = that.$cookies;
        let pin_payload ={
            hash: cookie.get('user_session').hash,
            pin:that.old_pin
        }
        that.loading = true;
        that.show_message_modal = false;
        User.validateOldPin(cookie.get('user').uuid,pin_payload).then(function(){
            // let data = response.data.data;
            // console.log(data);
            that.show_new_pin = true;
            that.loading = false;
        }).catch(function(error){
            that.error = true;
            that.warning=false;
            that.success=false;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
        });
    },
    logout(){
        this.loading = true;
    },
    validateEmail(){
        let that = this;
        const cookie = that.$cookies;
        that.loading = true;
        let payload = {
            hash:cookie.get('user_session').hash,
            value:that.email
        }
        that.loading = true;
        that.show_message_modal = false; 
        User.validateEmail(cookie.get('user').uuid,payload).then(function(response) {
            let data = response.data.data;
            that.otp_payload = {
                update_key:data.hash,
                pin:data.pin,
                email:that.email,
                type:data.type
            };
            that.timerEnabled=true;
            that.timerCount=120;
            that.show_resend_otp = false;
            that.loading = false;
            that.$refs.otp_input.clearInput();
            that.show_otp_modal = true;
        }).catch(function(error){
            that.timerEnabled=true;
            that.timerCount=120;
            that.error = true;
            that.warning=false;
            that.success=false;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
        });
    },
    validateMobile(){
        let that = this;
        const cookie = that.$cookies;
        that.loading = true;
        let payload = {
            hash:cookie.get('user_session').hash,
            value:'+63'+that.mobile_number
        }
        that.loading = true;
        that.show_message_modal = false; 
        User.validateMobile(cookie.get('user').uuid,payload).then(function(response) {
            let data = response.data.data;
                that.otp_payload = {
                    update_key:data.hash,
                    pin:data.pin,
                    mobile:that.mobile_number,
                    type:data.type
                };
                that.timerEnabled=true;
                that.timerCount=120;
                that.show_resend_otp = false;
                that.loading = false;
                that.$refs.otp_input.clearInput();
                that.show_otp_modal = true;
        }).catch(function(error){
            that.error = true;
            that.warning=false;
            that.success=false;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
        });
    },
    donelogout(){
        this.loading = false;
    },
    closed_modal() {
        this.show_message_modal = false;
    },
    reactivateAccount(){
        let that = this;
        const cookie = that.$cookies;
        that.loading = true;
        User.reactivate(cookie.get('user').uuid).then(function(){
            that.loading = false; 
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully reactivated your account.";
            that.getUserInfo();
            that.show_message_modal= true;
        }).catch(function(error){
            that.error = true;
            that.warning=false;
            that.success=false;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
        });
    },
    updateUsernameTrigger() {
        this.updateType = 1; 
        this.modal_message = "Are you sure you want to update your username?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updateEmailTrigger() {
        
        this.otpType = '6';
        this.updateType = 2; 
        this.modal_message = "Are you sure you want to update your email?";
        this.show_message_modal = true;
        this.warning = true;
        this.error = false;
        this.success = false;
        
    },
    updateMobileTrigger() {
        this.otpType = '7';
        this.updateType = 3; 
        this.modal_message = "Are you sure you want to update your mobile?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updateTinTrigger(){
        this.updateType = 6; 
        this.modal_message = "Are you sure you want to update your TIN?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updatePasswordTrigger(){
        this.updateType = 4; 
        this.modal_message = "Are you sure you want to update your password?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    showUpdateUsernameView() {
        if(this.showUpdateUsername == false) {
            this.showUpdateUsername = true;
            this.showUpdateEmail = false;
            this.showUpdateMobile = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    showUpdateEmailView() {
        if(this.showUpdateEmail == false) {
            this.showUpdateEmail = true;
            this.showUpdateUsername = false;
            this.showUpdateMobile = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    updateUsername() {
        const that = this;
        const cookie = that.$cookies;
        var payload = {
            hash: cookie.get('user_session').hash,
            value: that.username,
        }
        that.loading = true;
        // that.currentUsername = '';
        that.show_message_modal = false;
        User.editUserName(cookie.get('user').uuid,payload).then(function() {
            that.currentUsername = that.username;
            that.loading = false; 
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your username.";
            that.show_message_modal= true;
           

        }).catch(function(error){
            if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
            
        }); 
    },
    updateTin(){
        const that = this;
        const cookie = that.$cookies;
        let store = that.$store;
        let state = store.state;
        let tin = that.tin.replace('-','')
        let tin1 = tin.charAt(0) + tin.charAt(1) + tin.charAt(2);
        let tin2 = tin.charAt(3) + tin.charAt(4) + tin.charAt(5);
        let tin3 = tin.charAt(7) + tin.charAt(8) + tin.charAt(9);

        that.loading = true;
        that.currentTin = '';
        that.show_message_modal = false;
        let payload = {
            hash:cookie.get('user_session').hash,
            value:tin1+'-'+tin2+'-'+tin3,
        }
        User.updateTin(cookie.get('user').uuid,payload).then(function() {
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your TIN.";
            that.show_message_modal= true;
            that.loading = false;
            state.credentials.tin = that.tin;
            that.currentTin = that.tin;
        }).catch(function(error){
              if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    // updateEmail() {
    //     const that = this;
    //     const cookie = that.$cookies;
    //     var payload = {
    //         hash: cookie.get('user_session').hash,
    //         value: that.email,
    //     }
    //     that.loading = true;
    //     that.currentEmail = '';
    //     that.show_message_modal = false;
    //     User.editEmail(cookie.get('user').uuid,payload).then(function() {
    //         that.success = true;
    //         that.warning = false;
    //         that.error = false;
    //         that.modal_message = "Congratulations! you have successfully updated your email.";
    //         that.show_message_modal= true;
    //         that.currentEmail = that.email;
    //         that.loading = false; 
    //     }).catch(function(error){
    //         that.error = true;
    //         that.warning = false;
    //         that.success = false;
    //         that.loading = false;
    //         that.show_message_modal= true;
    //         that.modal_message = error.response.data.message;
    //     }); 
    // },
    updateMobile() {
        const that = this;
        const cookie = that.$cookies;

        // var payload = {
        //     hash: cookie.get('user_session').hash,
        //     value: '+63'+that.mobile_number,
        // }
        that.loading = true;
        that.show_message_modal = false;
        User.editMobile(cookie.get('user').uuid,that.edit_payload).then(function() {
            that.show_otp_modal = false;
            that.otp='';
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations! Your mobile number has been successfully updated";
            that.show_message_modal= true;
            that.currentMobile = that.mobile_number;
            that.loading = false; 
        }).catch(function(error){
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
            that.modal_message = error.response.data.message;
        }); 
    },
    updatePassword() {
        const that = this;
        const cookie = that.$cookies;
        
        // var validationPayload = {
        //     hash: cookie.get('user_session').hash,
        //     password: that.old_password
        // }
        that.loading = true;
        that.show_message_modal = false;
        // User.validatePassword(validationPayload,cookie.get('user').uuid).then(function() {
            var updatingPasswordPayload = {
                hash: cookie.get('user_session').hash,
                old_password:that.old_password,
                new_password:that.new_password,
                con_password:that.con_password,
            }
            User.changePassword(updatingPasswordPayload,cookie.get('user').uuid).then(function() {
                that.success = true;
                that.warning = false;
                that.error = false;
                that.modal_message = "Congratulations! You have successfully updated your password.";
                that.show_message_modal= true;
                that.loading = false;
            }).catch(function(error) {
                    if(error.response.status == 403){
                that.expired = true;
                    that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
                that.error = true;
                that.warning = false;
                that.success = false;
                that.loading = false;
                that.show_message_modal= true;
            }).catch(function(error){
                if(error.response.status == 403){
                that.expired = true;
                    that.modal_message = "Please Log in again.";
                }else{
                    that.modal_message = error.response.data.message;
                }
                that.error = true;
                that.warning = false;
                that.success = false;
                that.loading = false;
                that.show_message_modal= true;
        }); 
        // })
    },
    handleOnCompleteEmail()
    {
        let that = this;
        // console.log(that.otp_payload.type);
        if(that.otp_payload.type=='6'){
            that.submitOtpEmail();
        }
        else if(that.otp_payload.type=='7')
        {
            that.submitOtpMobile();
        }
        else if(that.otp_payload.type=='3')
        {
            that.submitDeactivationOtp();
        }
        else if(that.otp_payload.type=='4')
        {
            that.submitReactivationOtp();
        }
        
    },
    togglePeekerUpdatePin(e){
        if(e==1){
            if(this.new_pin_type=='password'){
            this.visible_new_pin = true;
            this.new_pin_type = 'number';
            }else{
                this.visible_new_pin = false;
                this.new_pin_type = 'password';
            }
        }else{
            if(this.con_pin_type=='password'){
                this.visible_con_pin= true;
                this.con_pin_type = 'number';
            }else{
               this.visible_con_pin= false;
                this.con_pin_type = 'password';
            }
        }
       
    },
    togglePeeker(){
        if(this.old_pin_type=='password'){
            this.visible = true;
            this.old_pin_type = 'number';
        }else{
            this.visible = false;
            this.old_pin_type = 'password';
        }
    },
    updateEmail(){
        let that= this;
        const cookie = that.$cookies;
        User.editEmail(cookie.get('user').uuid,that.edit_payload).then(function(){
            that.timerCount = 120;
            that.show_resend_otp = true;
            that.show_otp_modal=false;
            that.otp='';
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations! Your email address has been successfully updated";
            that.show_message_modal= true;
            that.currentEmail = that.email;
            that.loading = false; 
        }).catch(function(error){
             if(error.response.status == 403){
                that.expired = true;
                    that.modal_message = "Please Log in again.";
                }else{
                    that.modal_message = error.response.data.message;
                }
                that.error = true;
                that.warning = false;
                that.success = false;
                that.loading = false;
                that.show_message_modal= true;
        });
    },
    submitOtpMobile(){
        let that = this;
        that.loading = true;
        const cookie = that.$cookies;
        that.otp_payload.otp=that.otp;
        OTP.validate(that.otp_payload).then(function(response){
            let data = response.data.data
            that.otp_pin = data.otp_pin;
            that.otp_token = data.otp_token;
            that.edit_payload={
                value:'+63'+that.mobile_number,
                otp_token:that.otp_token,
                otp_pin:that.otp_pin,
                hash:cookie.get('user_session').hash,
                update_key:that.otp_payload.update_key
            }
            that.updateMobile();
        }).catch(function(error){
            if(error.response.status == 403){
                that.expired = true;
                that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    deactivateConfirmedAccount(){
        let that = this;
        const cookie = that.$cookies;
        let store = that.$store;
        let state = store.state;

        let payload = {
            otp_token:that.edit_payload.otp_token,
            otp_pin:that.edit_payload.otp_pin,
            deactivation_key:that.edit_payload.deactivation_key
        }
        User.deactivateConfirm(cookie.get('user').uuid,payload).then(function(){
            state.credentials.deactivated = true;
            that.warning = false;
            that.error = false;
            that.success = true;
            that.loading = false;
            that.show_message_modal= true;
            that.modal_message = "You have successfully deactivated your account";
        }).catch(function(error){
            if(error.response.status == 403){
                that.expired = true;
                that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },

    submitReactivationOtp(){
        let that = this;
        that.loading = true;
        const cookie = that.$cookies;
        that.otp_payload.otp=that.otp;
        
        OTP.validate(that.otp_payload).then(function(response){
            let data = response.data.data
            that.otp_pin = data.otp_pin;
            that.otp_token = data.otp_token;
            that.edit_payload={
                otp_token:that.otp_token,
                otp_pin:that.otp_pin,
                hash:cookie.get('user_session').hash,
                reactivation_key:that.otp_payload.reactivation_key,
            }
            that.reactivateConfirmedAccount();
        }).catch(function(error){
            if(error.response.status == 403){
                that.expired = true;
                that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },

    reactivateConfirmedAccount(){
        let that = this;
        const cookie = that.$cookies;
        let store = that.$store;
        let state = store.state;

        let payload = {
            otp_token:that.edit_payload.otp_token,
            otp_pin:that.edit_payload.otp_pin,
            reactivation_key:that.edit_payload.reactivation_key,
            hash: cookie.get('user_session').hash
        }
        User.reactivateConfirm(cookie.get('user').uuid,payload).then(function(){
            state.credentials.deactivated = null;
            that.warning = false;
            that.error = false;
            that.success = true;
            that.loading = false;
            that.show_message_modal= true;
            that.modal_message = "You have successfully deactivated your account";
        }).catch(function(error){
            if(error.response.status == 403){
                that.expired = true;
                that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    submitDeactivationOtp(){
        let that = this;
        that.loading = true;
        const cookie = that.$cookies;
        that.otp_payload.otp=that.otp;
        
        OTP.validate(that.otp_payload).then(function(response){
            let data = response.data.data
            that.otp_pin = data.otp_pin;
            that.otp_token = data.otp_token;
            that.edit_payload={
                otp_token:that.otp_token,
                otp_pin:that.otp_pin,
                hash:cookie.get('user_session').hash,
                deactivation_key:that.otp_payload.deactivation_key,
                password:that.deac
            }
            that.deactivateConfirmedAccount();
        }).catch(function(error){
            if(error.response.status == 403){
                that.expired = true;
                that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    submitOtpEmail(){
        let that = this;
        that.loading = true;
        const cookie = that.$cookies;
        that.otp_payload.otp=that.otp;
        OTP.validate(that.otp_payload).then(function(response){
            let data = response.data.data
            that.otp_pin = data.otp_pin;
            that.otp_token = data.otp_token;
            that.edit_payload={
                value:that.email,
                otp_token:that.otp_token,
                otp_pin:that.otp_pin,
                hash:cookie.get('user_session').hash,
                update_key:that.otp_payload.update_key
            }
            that.updateEmail();
        }).catch(function(error){
            if(error.response.status == 403){
                that.expired = true;
                that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    getImgSrc() {
        return this.visible ? "images/papsi_invi_icon.svg" : "images/papsi_vis_icon.svg";
    },
    getImgSrcNewPin() {
        return this.visible_new_pin ? "images/papsi_invi_icon.svg" : "images/papsi_vis_icon.svg";
    },
    getImgSrcConPin() {
        return this.visible_con_pin ? "images/papsi_invi_icon.svg" : "images/papsi_vis_icon.svg";
    },
    deactivateAccount() {
        const that = this;
        const cookie = that.$cookies;
        
        var validationPayload = {
            hash: cookie.get('user_session').hash,
            password: that.deac
        }
        that.loading = true;
        that.show_message_modal = false;
        User.deactivate(cookie.get('user').uuid,validationPayload).then(function(response) {
            let data = response.data.data
            that.loading = false;
            // that.deactivate_payload={
            //     deactivation_key:data.hash,
            //     type:'3'
            // }
            that.otp_payload = {
                deactivation_key:data.hash,
                pin:data.pin,
                email:that.email,
                type:'3',
                mobile:that.mobile_number
            };
            that.deactivation_pin = data.pin;
            that.$refs.otp_input.clearInput();
            that.show_otp_modal = true;
            // console.log(data);
            // that.success = true;
            // that.warning = false;
            // that.error = false;
            // that.modal_message = "You have successfully deactivated your account.";
            // that.show_message_modal = true;
        }).catch(function(error) {
            if(error.response.status == 403){
                that.expired = true;
                that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    triggerDeactivate(){
        const that = this;
        const cookie = that.$cookies;

         that.loading = true;
         User.deactivate(cookie.get('user').uuid).then(function() {
                that.success = true;
                that.warning = false;
                that.error = false;
                that.modal_message = "You have successfully deactivated your account.";
                that.show_message_modal = true;
                that.loading = false;
            }).catch(function(error) {
                    if(error.response.status == 403){
                        that.expired = true;
                        that.modal_message = "Please Log in again.";
                    }else{
                        that.modal_message = error.response.data.message;
                    }
                    that.error = true;
                    that.warning = false;
                    that.success = false;
                    that.loading = false;
                    that.show_message_modal= true;
            });
    },
    showUpdateMobileView() { 
        if(this.showUpdateMobile == false) {
            this.showUpdateMobile = true;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    showUpdatePasswordView() {
        if(this.showPasswordUpdateView == false ) { 
            this.showPasswordUpdateView = true;
            this.showUpdateMobile = false;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showSecurityView = false;
             this.showUpdateTin = false;
        }
    },
    showUpdateTinView(){
        if(this.showUpdateTin == false ) { 
            this.showUpdateTin = true;
            this.showPasswordUpdateView = false;
            this.showUpdateMobile = false;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showSecurityView = false;
        }
    },
    showSecurity() {
         if(this.showSecurityView == false ) { 
            this.showUpdateMobile = false;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = true;
            this.showUpdateTin = false;
        }
    },
    showAccountView() {
        if(this.showAccountInfoView == false ) { 
            this.showPasswordUpdateView = false;
            this.showAccountInfoView = true;
            this.showSecurityView = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    showMenu() {
        if(this.show_menu){
            this.hideMenu();
        } else {
            this.show_menu = true;
        }
    },
    hideMenu(){
        this.show_menu = false;
    },
    changed(e){
        let key = e.key;
        let that = this;
        that[key] = e.value;
        if(key =='mobile_number') {
            that.checkChange({value:e.value,type:1});
        }
        if(key =='email') {
            that.checkChange({value:e.value,type:2});
        }
        if(key =='username') {
            that.checkChange({value:e.value,type:3});
        }
        if((this.old_password!=''|| this.old_password!=null) && (this.new_password!=''|| this.new_password != null) && (this.score =='Strong'))
        {
            this.disablePassword = false;
        }
    },
    checkChange(e) {
        if(e.type == 1){
            if(e.value != this.currentMobile){
                this.enableMobile = true;
            }else{
                this.enableMobile = false;
            }
        }
        if(e.type == 2){
            if(e.value != this.currentEmail){
                this.enableEmail = true;
            }else{
                this.enableEmail = false;
            }
        }
        if(e.type == 3){
            if(e.value != this.currentUsername){
                this.enableUsername = false;
            }else{
                this.enableUsername = true;
            }
        }
    },

    otpUpdate(e) {
      let that = this;
      that.otp = e.value;
    },
    downloadQR() {
          let url = this.getQr();
         axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'image/png' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'QR';
                link.click();
                URL.revokeObjectURL(link.href);
            })
    },
    resendOtp() {
        let that = this;
        let store = that.$store;
        let state = store.state;
        that.loading = true;
        that.show_resend_otp = false;
        that.timerEnabled= true;
        that.timerCount = 120;
        that.otp_payload.name = state.credentials.name;
        OTP.requestOtp(that.otp_payload).then(function() {
            that.loading= false;
          }).catch(function(error) {
               if(error.response.status == 403){
                    that.expired = true;
                    that.modal_message = "Please Log in again.";
                } else {
                    that.modal_message = error.response.data.message;
                }
                    that.error = true;
                    that.warning = false;
                    that.success = false;
                    that.loading = false;
                    that.show_message_modal= true;
          });
      },
    getQr() {
        let that = this;
        const cookie = that.$cookies;
        return "https://quickchart.io/qr?text=" + cookie.get('user').hash;
    },
},
watch:{
    timerCount: {
      handler(value) {
        let that =this;
        //  console.log(value,that.timerEnabled);
        if (value > 0 && that.timerEnabled) {
          that.show_resend_otp = false;
          setTimeout(() => {
            that.timerCount = that.timerCount - 1;
            // console.log(this.timerCount);
          }, 1000);
        } else {
          that.show_resend_otp = true;
          that.timerEnabled = false; // run your function here
        }
      },
      immediate: true
    },
}
})
</script>
<style>
.max-width-back{
    max-width: 50px;
}
.shadowed{
    text-shadow: 0.10em 1.5px black;
}
.otp-input {
  width: 52px;
  height: 55px;
  padding: 5px;
  margin: 0 10px;
  font-size: 30px;
  border-radius: 4px;
  border: 2px solid grey;
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: white;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  flex: 1;
  font-size: 18px;
  text-align: center;
  font-weight: 800;
}
</style>
