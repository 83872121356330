<template>
<div class="h-full w-full gradient">
<Modal v-show="show_message_modal" 
      v-bind:display="show_message_modal"
      :error="this.error"
      :success="this.success"
      :note="this.warning"
      z_value="z-50"
      @positiveClick="messageClosed"
      :positiveButton="'OK'"
      :default_width="'max-w-xl'"
      id='vote_modal'>
      <div class="w-full p-5 flex flex-col text-xl font-bold">
          {{ this.modal_message}}
      </div>
</Modal>
<Modal v-show="show_rate" 
      v-bind:display="show_rate"
      z_value="z-50"
      :default_width="'max-w-md'"
      id='vote_modal'>
      <div class="w-full flex flex-col text-xl font-bold">
       <div class="w-full h-full flex relative" v-if="show_rate">
        <div class=" flex w-full m-auto bg-white justify-center rounded-md flex-row p-5 relative z-50 ">
            <div class="flex flex-col w-full relative  space-y-2">
                <span v-text="lang('how.experience')" class="italic text-lg"/>
                <Rating @score="Savescore" />
                  <span v-text="message" class="text-red-500"/>
                <div class="flex flex-row space-x-2 pt-2">
                    
                <div @click="submitVote" class="border w-full border-theme-primary p-3 text-theme-primary hover:bg-theme-primary hover:text-white cursor-pointer text-lg font-bold rounded text-center shadow-lg ">
                    <span v-text="'OK'" />
                </div>
                <div @click="back" class="border w-full border-theme-primary p-3 text-theme-primary hover:bg-theme-primary hover:text-white cursor-pointer text-lg font-bold rounded text-center shadow-lg ">
                    <span v-text="'SKIP'" />
                </div>
                </div>
            </div>
            
        </div>
         <img src="@/assets/icons/hi.png" class="max-w-xs z-40 fixed self-start mt-32 ml-72"/>
    </div>
      </div>
</Modal>
<div :class="[{'hidden':!loading}]">
    <Loading :loading="!loading"/>
</div>
<div v-if="this.$store.state.user.selected_candidatesBodOf != null" class="bg-[#015B19] flex justify-center p-20" >
    <div class="h-fit w-full receipt-paper xs:p-2 max-w-3xl flex justify-center p-5" :class="[{'hidden':loading || show_rate}]">
        <div class="w-full bg-white h-fit flex flex-col mb-10">
            <div class="flex">
                   <img src="https://media.pafcpic.net/images/logo/pafcpic.png" class="m-auto w-24 h-24 z-40 fix-content pt-3" />
            </div>
           <div class="p-5 pl-0 pr-0 flex text-center font-mono font-bold text-xl" >
            <span class="w-full content-center ">
                PHILIPPINE ARMY FINANCE CENTER PRODUCERS INTEGRATED COOPERATIVE
             </span>
             <!-- <div class="flex">
                   <img src="https://media.pafcpic.net/images/logo/pafcpic.png" class="m-auto w-32 h-32 z-40 fix-content" />
            </div> -->
           </div>
            
            <div class="p-4">
                <div class="border-[2px] border-dashed border-gray-400 w-full"/>
             </div>
             <div class=" p-4 text-lg text-black" >
                <div>
                    <span v-text="'BALLOT NUMBER: '" class="font-bold"/>
                    <span v-text="this.$store.state.election_config.ballot_no"/>
                </div>
                <div>
                    <span v-text="'NAME: '" class="font-bold"/>
                    <span v-text="this.$store.state.credentials.name"/>
                </div>
                <div>
                    <span v-text="'ACCOUNT NUMBER: '" class="font-bold"/>
                    <span v-text="this.$store.state.credentials.account_no"/>
                </div>
                <div>
                    <span v-text="'SERIAL NUMBER: '" class="font-bold"/>
                    <span v-text="this.$store.state.credentials.serial_no"/>
                </div>
                <div>
                    <span v-text="'NUMBER OF VOTES: '" class="font-bold"/>
                    <span v-text="this.$store.state.election_config.total_votes"/>
                </div>
             </div>
             <div class="p-4">
                <div class="border-[2px] border-dashed border-gray-400 w-full"/>
             </div>
           <div class=" p-4">
                <div class="text-2xl font-bold" >I. BOARD OF DIRECTORS</div>
                <div class="p-2">
                    <div class="font-bold text-xl ml-3" >AT-LARGE CATEGORY</div>
                    <div class="space-x-2 text-lg ml-6" v-for="(items,index) in this.$store.state.user.selected_candidatesBodOf" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                    </div>
                    <!-- <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace">OFFICER CATEGORY</div>
                    <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesBodOf" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                    </div>
                    <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace">ENLISTED PERSONNEL CATEGORY</div>
                    <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesBodEp" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                    </div>
                    <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace">CIVILIAN CATEGORY</div>
                    <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesBodCv" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                    </div> -->
                </div>
                
                <div class="text-2xl font-bold" >II. AUDIT & SUPERVISORY COMMITTEE</div>
                <div class="p-2">
                        <div class="space-x-2 text-lg ml-6" v-for="(items,index) in this.$store.state.user.selected_candidatesAudit" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                        </div>
                </div>
                <div class="text-2xl font-bold">III. ELECTION COMMITTEE</div>
                <div class="p-2">
                        <div class="font-bold text-xl ml-3" > OFFICER CATEGORY</div>
                        <div class="space-x-2 text-lg ml-6"  v-for="(items,index) in this.$store.state.user.selected_candidatesElecomOf" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                        </div>
                        <div class="font-bold text-xl ml-3" > ENLISTED PERSONNEL CATEGORY</div>
                        <div class="space-x-2 text-lg ml-6" v-for="(items,index) in this.$store.state.user.selected_candidatesElecomEp" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                        </div>
                         <!-- <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace"> CIVILIAN CATEGORY</div>
                        <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesElecomCv" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                        </div> -->
                </div>
                <div class="p-2">
                    <div class="border-[2px] border-dashed border-gray-400 w-full"/>
                </div>

                <div class="p-2">
                    <div class="flex flex-col text-gray-400" >
                        <div>
                            <span v-text="'You Started On: '"/>
                            <span v-text="this.$store.state.user.date_started"/>
                        </div>
                        <div>
                            <span v-text="'You Submmited On: '"/>
                            <span v-text="this.$store.state.user.date_submitted"/>
                        </div>
                        <!-- <div>
                            <span v-text="'Date Casted:'"/>
                            <span v-text="Date.Now()"/>
                        </div> -->
                    </div>
                </div>
                <div class="p-2">
                    <div class="border-[2px] border-dashed border-gray-400 w-full"/>
                </div>
                <div class="p-2 justify-items-center">
                    <VueBarcode :value="String(this.$store.state.election_config.ballot_no)" format="CODE128" style="width: 15rem;"/>
                </div>
                <div class="p-2">
                    <div class="text-2xl w-full text-center text-gray-400" >
                        THANK YOU FOR VOTING!
                    </div>
                     <div class="text-md w-full text-center text-gray-400" >
                        Serving the AFP Since 1981
                    </div>
                </div>
           </div>
            <div class="w-full bg-white p-5">
                <div @click="click"
                class="border p-3 text-theme-primary bg-theme-secondary cursor-pointer text-xl font-bold rounded text-center shadow-lg ">
                    <span v-text="'PROCEED'" />
                </div>
            </div>
        </div>
    </div>
 </div> 
 </div>  
</template>

<script>
import { defineComponent } from "vue";
import mixin from "@/mixin";
import User from "../../services/User";
import Rating from "@/components/controls/Rating.vue";
import Loading from "@/components/controls/LoadingControl.vue";
import Modal from "@/components/controls/ModalControl.vue";
import VueBarcode from '@chenfengyuan/vue-barcode';
export default defineComponent({
    mounted() {
        let that = this;
        let store = that.$store;
        let state = store.state;
        
       if(state.user.skip==true){
            that.$router.push('/Dashboard');
        }else{
            // this.show_rate=true;
            this.checkStarted();
        }
        // this.checkExistingCookie();
    },
    data(){
        return {
            loading: false,
            score:null,
            message:null,
            show_rate:false,
            modal_message:null,
            show_message_modal:false,
            error:false,
            success:false,
            warning:false,
        }
    },
    mixins: [
        mixin,
    ],
    components:{
        Rating,
        VueBarcode,
        Loading,
        Modal
    },
    methods:{
    messageClosed(){
        this.error = false;
        this.success = false;
        this.warning = false;
        this.show_message_modal=false;
    },
    checkExistingCookie() {
        let that = this;
       const cookie = that.$cookies;
       const store = that.$store;
      //  let log = store.state.logged;
       let state = store.state;
        state.hash = cookie.get('user').hash;
        that.loading = true;
        User.getuserInfo(cookie.get('user')).then(function(response){
          let user_data = response.data.data;
              //election
              
              state.election_config.date_end = user_data.election.vote_end;
              state.election_config.date_start = user_data.election.vote_start;
              state.election_config.server_time = response.timestamp;
              state.election_config.election_registered = user_data.election.registered;
              state.election_config.can_vote = user_data.election.can_vote;
              state.election_config.voted = user_data.election.voted;
              state.election_config.voting_ended = user_data.election.voting_ended;
              state.election_config.registration_start=user_data.election.reg_start;
              state.election_config.registration_end= user_data.election.reg_end;
              state.election_config.registration_started= user_data.election.reg_started;
              
              //credential
              state.credentials.name = user_data.account.username;
              state.credentials.first_name=user_data.info.first_name;
              state.credentials.last_name=user_data.info.last_name;
              state.credentials.middle_name=user_data.info.middle_name;
              state.credentials.gender=user_data.info.gender;
              state.credentials.regular=user_data.info.regular;
              state.credentials.account_no = user_data.info.account_no;
              state.credentials.serial_no = user_data.info.serial_no;
              state.credentials.tin= user_data.info.tin;
              state.credentials.email = user_data.account.email;
              state.credentials.mobile = user_data.account.mobile;
              state.credentials.username = user_data.account.username;
              state.credentials.qr =user_data.qr;
              state.credentials.uuid = user_data.uuid;
              state.credentials.hash = cookie.get('user').hash;
              state.credentials.key = user_data.key;
              state.credentials.membership_date = user_data.info.membership_date;
              state.credentials.registered_date = user_data.account.registered;
              state.credentials.status = user_data.info.status;
              state.credentials.membership_type = user_data.info.membership_type;
              state.credentials.deactivated = user_data.account.deactivated;
              that.loading = false;
                if(user_data.account.express){
                that.$router.push('/user/settings');
              }
              if(user_data.account.deactivated){
                that.$router.push('/user/settings');
              }
                // that.$router.push('/dashboard');
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
             if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
        });
        },
        click() {
            const that = this;
            const store = that.$store;
            const state = store.state;

            that.success =true;
            that.show_message_modal =true;
            that.modal_message = "Thank you for participating in this year's Annual Regular General Assembly Meeting and Election!";
            // state.user.rated = true;
            state.user.selected_candidatesBod = null;
            state.election_config.finished = true;
            that.show_rate= true;
        },
        Savescore(e){
            this.score = e; 
        },
        back(){
         this.$router.push('/Dashboard');
        },
        checkStarted() {
            const that = this;
            const store = that.$store;
            const state = store.state;

            if(state.election_config.finished) {
                that.$router.push('/Dashboard');
            }
        },
        submitVote(){
            let that = this;
            const cookie = that.$cookies;
            let score_payload = {
                type:'1',
                score:that.score,
                hash:cookie.get('user_session').hash
            };
            
            if(that.score == null){
                that.message = "Please select a star to rate your experience";
            }else{
                that.loading = true;
                that.show_rate = false;
                User.rating(cookie.get('user').uuid,score_payload).then(function() {
                that.loading = false;
                that.$router.push('/Dashboard');
                }).catch(function(){
                    that.show_rate = true;
                    that.loading = false;
                });
            }
            
        }
    }

})
</script>
<style>
/* .gradient{
   background-image: linear-gradient(to top,#9CA3AF, #eaebed);
} */
.receipt-paper {
    background: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    position: relative;
}

.receipt-paper::before {
    content: "";
    position: absolute;
    left: -4rem; 
    width: calc(100% + 8rem); 
    height: 1.5rem;
    background: #014700;
    border-radius: 4rem;
    top: -10px; 
}
.fix-content{
    max-width: fit-content !important;
}
.receipt-paper::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 40px; /* Increased height */
    background: radial-gradient(circle at center, white 50%, transparent 50%);
    background-size: 40px 40px; /* Adjusted size */
    bottom: -20px; /* Adjusted position */
}
</style>