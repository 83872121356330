import services from "../service";

export default {
    validate(payload) {
        return services.post('support/otp/validate',payload);
    },
    requestOtp(payload){
        return services.post('support/otp',payload);
    },
    verify(uuid : string, otp : string) {
        return services.post('/election/otp', {
            uuid: uuid,
            otp: otp,
        });
    },
}