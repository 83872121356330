<template>
<div>
<div :class="[{'hidden':!Loading}]">
    <LoadingControl  v-show="Loading" :loading="Loading"/>
</div>
<div class="w-full h-fit bg-white fixed overflow-y-auto z-50 shadow-lg" v-show="showSelectedCandidates && !Loading && !showOtpModal">
    <div class="border p-3 h-screen w-full grid grid-col-2 grid-flow-row card_color transition delay-100 duration-500 ease-in-out"
    v-bind:class="[{'-translate-x-0' : this.showSelectedCandidates ,'-translate-x-full':!this.showSelectedCandidates},{}]">
        <div class="card_color">
            <div class="flex flex-col text-xl font-bold bg-theme-primary text-white p-2">
                <span v-text="'Board Of Directors (7)'"/>
            </div>
            <div class="font-bold text-lg p-2">
             AT-LARGE CATEGORY(7)
            </div>
            <div v-for="(items,index) in selectedBod" v-bind:key="items.key">
                <div class="border-b-theme-primary border-b" >
                    <div class="flex flex-row relative p-1 text-theme-primary" >
                        <div class="font-bold mr-1 p-1 ">
                            {{index + 1 +'.'}} 
                        </div>
                        <div class=" p-1 grow text-black">
                            {{items.name}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="font-bold text-lg pl-2">
             OFFICER (3)
            </div>
            <div v-for="(items,index) in selectedBodOf" v-bind:key="items.name"> -->
            <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
            <!-- <div class="border-b-theme-primary border-b typed">
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div> -->
            <!-- <div class="font-bold text-lg pl-2">
             ENLISTED PERSONNEL (3)
            </div>
            <div v-for="(items,index) in selectedBodEp" v-bind:key="items.name"> -->
            <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
            <!-- <div class="border-b-theme-primary border-b typed">
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div> -->
            <!-- <div class="font-bold text-lg pl-2">
             CIVILIAN CATEGORY (2)
            </div> -->
            <!-- <div v-for="(items,index) in selectedBodCv" v-bind:key="items.name"> -->
            <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
            <!-- <div class="border-b-theme-primary border-b typed">
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div> -->
        </div>
        <div class="card_color">
            <div class="flex flex-col text-xl font-bold ml-1 bg-theme-election-adcom text-white p-2">
                <span v-text="'AUDIT & SUPERVISORY COMMITTEE (1)'"/>
            </div>
            <div v-for="(items,index) in selectedAudit" v-bind:key="items.name" class="mt-2">
                <!-- <div class="adcom_selected" :class="[{'bg-theme-election-adcom': index%2 }]"> -->
                    <div class="border-b-theme-primary border-b typed" >
                    <div class="flex flex-row relative p-0 text-theme-primary ">
                        <div class="font-bold mr-1 p-1">
                            {{index + 1 +'.'}} 
                        </div>
                        <div class="p-1 grow text-black">
                            {{items.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card_color">
            <div class="flex flex-col text-xl font-bold bg-theme-election-elecom text-white p-2">
                <span v-text="'ELECTION COMMITTEE (2)'"/>
            </div>
            <div class="font-bold text-lg p-2">
             OFFICER (1)
            </div>
            <div v-for="(items,index) in selectedElecomOf" v-bind:key="items.name">
            <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
            <div class="border-b-theme-primary border-b typed">
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div>
            <div class="font-bold text-lg p-2">
             ENLISTED PERSONNEL (1)
            </div>
            <div v-for="(items,index) in selectedElecomEp" v-bind:key="items.name">
             <!-- <div class="border-b" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
                <div class="border-b-theme-primary border-b typed" >
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div>
            <!-- <div class="font-bold text-lg pl-2">
             CIVILIAN PERSONNEL (1)
            </div>
            <div v-for="(items,index) in selectedElecomCv" v-bind:key="items.name"> -->
             <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
                <!-- <div class="border-b-theme-primary border-b typed" >
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div> -->
        </div>
        <div class="self-end ">
            <div @click="closeList"
            class="border p-3  text-lg font-bold rounded text-center shadow-lg  border-theme-primary bg-theme-primary text-white cursor-pointer">
                <span v-text="'CLOSE'" />
            </div>
        </div>
    </div>
</div>
<div class="flex flex-row bg-white shadow-lg" :class="[{'hidden':Loading},{'hidden':showSelectedCandidates}]">
<Modal id="alert"
      @closed="closed"
      type="info"
      @positiveClick="positiveClick"
      @negativeClick="negativeClick"
      :question="true"
      v-bind:display="confirmation"
      v-bind:positiveButton="'OK'"
      v-bind:negativeButton="'Cancel'">
      <div class="p-5 text-base leading-6 w-full text-center">
        <span v-text="'Proceed to submit your votes?'" />
      </div>
</Modal>

<Modal id="alert"
      type="error"
      @positiveClick="closed"
      v-show="exceed"
      :note ="true"
      :modal_message="'VOTE LIMIT REACHED'"
      v-bind:positiveButton="'Ok'"
      :display="exceed"
      >
      <div class="p-5 text-base leading-6" v-text="'You have already selected the maximum number of candidates. Please deselect a candidate and select again.'"></div>
</Modal>
<Modal id="elecCode"
        @positiveClick="closed"
        v-bind:positiveButton="lang('CONTINUE')"
        v-bind:title="'Election Policy'"
        default_width="max-w-3xl"
        v-bind:display="electionCodeView">
      <div class="p-2 w-full ">
        <ElectionPolicy />
      </div>
</Modal>
      <!-- @positiveClick="showElectionPolicy"
      @negativeClick="cancel"
      @startVoting="startVoting"
      @requestOtp="requestOtp" 
      v-bind:positiveButton="'Submit'"
      v-bind:negativeButton="'Cancel'"
      -->
<Modal id="alert"
       type="otp"
       :otpType="'5'"
       closable
       :z_value="'40'"
       @closed="closedElectionOtpModal"
      v-bind:display="showOtpModal"
      v-bind:title="'ELECTION OTP'"
     >
      <div class="p-5 text-base leading-6" v-text="'Please enter the election OTP we have sent to your mobile no. and email address.'"></div>
       <!-- <TextField 
        v-bind:value="otp"
        v-bind:placeholder="lang('One-Time-Pin')"
        class="p-2"
        @changed="updateOtp"
        id="otp"
        type="number"/> -->
         <!-- @on-change="handleOnChangePin" -->
        <v-otp-input
            :is-disabled="false"
            ref="otp_election"
            class="pb-2"
            v-show="true"
            input-classes="otp-input-election"
            :conditionalClass="['1', '2', '3', '4', '5', '6']"
            separator=" "
            :input-type="'number'"
            inputmode="numeric"
            :num-inputs="6"
            @on-complete="handleCompletedOtp"
            v-model:value="otp"
            :should-auto-focus="true"
            :should-focus-order="true"
            :placeholder="['*', '*', '*', '*', '*', '*']"
        />
        <span v-text="error_message" v-show="error_message!=null && error_message!=''" class="text-red-500"/>
        <div class="flex w-full pl-3 pr-3 pb-3 pt-1">
            <span v-text="'Resend'" @click="resend" class=" text-lg self-start text-theme-primary hover:text-blue-500 hover:underline hover:cursor-pointer" v-show="show_resend_otp"/>
        </div>
</Modal>
    <div class="border card_color w-full sm:max-w-xs max-w-md shadow-lg grid grid-col-2 grid-flow-row xs:hidden">
        <div class="card_color">
            <div class="flex flex-col text-xl font-bold bg-theme-primary text-white p-2">
                <span v-text="'Board Of Directors (7)'"/>
            </div>
            <div class="font-bold text-lg p-2">
             AT-LARGE CATEGORY (7)
            </div>
            <div v-for="(items,index) in selectedBod" v-bind:key="items.key">
                <div class="border-b-theme-primary border-b" >
                    <div class="flex flex-row relative p-1 text-theme-primary typed" >
                        <div class="font-bold mr-1 p-1 ">
                            {{index + 1 +'.'}} 
                        </div>
                        <div class=" p-1 grow text-black">
                            {{items.name}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="font-bold text-lg pl-2">
             OFFICER (3)
            </div> -->
            <!-- <div v-for="(items,index) in selectedBodOf" v-bind:key="items.name"> -->
            <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
            <!-- <div class="border-b-theme-primary border-b typed">
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black ">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div> -->
            <!-- <div class="font-bold text-lg pl-2">
             ENLISTED PERSONNEL (3)
            </div> -->
            <!-- <div v-for="(items,index) in selectedBodEp" v-bind:key="items.name"> -->
            <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
            <!-- <div class="border-b-theme-primary border-b typed">
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div> -->
            <!-- <div class="font-bold text-lg pl-2">
             CIVILIAN CATEGORY (2)
            </div> -->
            <!-- <div v-for="(items,index) in selectedBodCv" v-bind:key="items.name"> -->
            <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
            <!-- <div class="border-b-theme-primary border-b typed">
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div> -->
        </div>
        <div class="card_color">
            <div class="flex flex-col text-xl font-bold ml-1 bg-theme-election-adcom text-white p-2">
                <span v-text="'AUDIT & SUPERVISORY COMMITTEE (1)'"/>
            </div>
            <div v-for="(items,index) in selectedAudit" v-bind:key="items.name" class="mt-2">
                <!-- <div class="adcom_selected" :class="[{'bg-theme-election-adcom': index%2 }]"> -->
                    <div class="border-b-theme-primary border-b typed" >
                    <div class="flex flex-row relative p-0 text-theme-primary ">
                        <div class="font-bold mr-1 p-1">
                            {{index + 1 +'.'}} 
                        </div>
                        <div class="p-1 grow text-black">
                            {{items.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card_color">
            <div class="flex flex-col text-xl font-bold bg-theme-election-elecom text-white p-2">
                <span v-text="'ELECTION COMMITTEE (2)'"/>
            </div>
            <div class="font-bold text-lg pl-2">
             OFFICER (1)
            </div>
            <div v-for="(items,index) in selectedElecomOf" v-bind:key="items.name">
            <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
            <div class="border-b-theme-primary border-b typed">
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div>
            <div class="font-bold text-lg pl-2">
             ENLISTED PERSONNEL (1)
            </div>
            <div v-for="(items,index) in selectedElecomEp" v-bind:key="items.name">
             <!-- <div class="border-b" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
                <div class="border-b-theme-primary border-b typed" >
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div>
             <!-- <div class="font-bold text-lg pl-2">
             CIVILIAN PERSONNEL (1)
            </div>
            <div v-for="(items,index) in selectedElecomCv" v-bind:key="items.name"> -->
             <!-- <div class="elecom_selected" :class="[{'bg-theme-election-elecom': index%2 }]"> -->
                <!-- <div class="border-b-theme-primary border-b typed" >
                <div class="flex flex-row relative p-0 text-theme-primary">
                    <div class="font-bold mr-1 p-1 ">
                        {{index + 1 +'.'}} 
                    </div>
                    <div class="p-1 grow text-black">
                        {{items.name}}
                    </div>
                </div>
             </div>
            </div> -->
        </div>
        <div class="self-end pt-2">
            <div @click="submiVotes"
            :class="[{'bg-gray-400 text-white border-gray-400 hover:bg-gray-400':this.disabled},{'cursor-pointer':!this.disabled}]"
            class="border p-3  text-lg font-bold rounded text-center shadow-lg  bg-theme-primary  text-white ">
                <span v-text="'SUBMIT VOTE'" />
            </div>
        </div>
    </div>
    <div class="flex flex-col w-full bg-white h-screen overflow-y-auto">
        <div class="w-full p-0 fixed  sm:hidden md:hidden z-40"  v-show="this.showMenuIcon && !showOtpModal">
            <div @click="this.openList"
                class="max-w-prose text-lg font-bold mr-[20px] bg-theme-primary w-16  float-right rounded-full text-center text-white cursor-pointer p-2">
                    <img src="@/assets/images/papsi_menu_icon.svg"/>
            </div>
        </div>
        <div class="border-black w-full grow h-fit md:p-2  flex">
            <div class="h-fit w-full">
                <div class="bg-theme-primary h-16 flex">
                    <div class="text-white font-bold text-2xl pl-5 self-center w-full">BOARD OF DIRECTORS</div>
                </div>
            <div class="p-5 pb-2 text-2xl font-bold">
                AT-LARGE CATEGORY
            </div>
            <!-- <div class="p-5 text-2xl font-bold">
                OFFICER CATEGORY
            </div> -->
            <div class="w-full p-5 pt-2 rounded-lg  grid sm:grid-cols-4 xs:grid-cols-4 md:grid-cols-5 gap-4 relative">
            <div @click="selectedCandidates(items.name,items.key,'BOD',items.type,index,items.selected)" 
                v-bind:id="items.key" v-for="(items,index) in this.candidateBod" 
                v-bind:key="items.name"
                :ref="items.key"
                :class="[items.theme,{'hidden':items.type==2 || items.type==3}]"
                class="xs:col-span-2 p-3">  
                <div class="rounded-full justify-end flex w-full" v-if="items.selected">
                    <img src="@/assets/images/papsi_selection_icon.svg" class="absolute"/>
                </div> 
                <div class="p-2 w-full bg-white">
                    <div class="bg-white rounded-lg">
                        <!-- <img src="@/assets/icons/papsi_profile.svg"/> -->
                         <img :src="items.photo" class="w-full"/>
                    </div>
                    <!-- <img :src="getSource(items.key,items.selected)" class="w-full"/> -->
                </div>
                <div  class="p-2 pb-0 m-auto flex flex-col text-center font-bold min-h-[55px]">
                    <span>{{items.name}}</span>
                </div>
                <!-- <div  class="pt-2 m-auto flex flex-col text-center text-gray-400 " :class="[{'text-green-700 !important':items.selected}]">
                    <span>{{items.type}}</span>
                </div> -->
            </div>
            </div>
            <!-- <div class="p-5 text-2xl font-bold">
                ENLISTED PERSONNEL CATEGORY
            </div>
            <div class="w-full p-2 grid sm:grid-cols-3 xs:grid-cols-4 md:grid-cols-5 gap-4 relative">
            <div @click="selectedCandidates(items.name,items.key,'BOD',items.type,index,items.selected)" 
            v-bind:id="items.key" v-for="(items,index) in this.candidateBod" 
            v-bind:key="items.name"
            :ref="items.key"
            :class="[items.theme,{'hidden':items.type==1 || items.type==3}]"
            class="xs:col-span-2">  
                <div class="absolute ml-2 mt-2 rounded-full" v-if="items.selected">
                    <img src="@/assets/images/papsi_selected_icon.svg"/>
                </div> 
                <div class="border-b w-full">
                    <img :src="getSource(items.key,items.selected)" class="w-full"/>
                </div>
                <div  class="p-6 m-auto flex flex-col text-center font-bold">
                    <span>{{items.name}}</span>
                </div>
            </div>
            </div> -->
            <!-- <div class="p-5 text-2xl font-bold">
                CIVILIAN CATEGORY
            </div>
            <div class="w-full p-2 grid sm:grid-cols-3 xs:grid-cols-4 md:grid-cols-5 gap-4 relative">
            <div @click="selectedCandidates(items.name,items.key,'BOD',items.type,index,items.selected)" 
            v-bind:id="items.key" v-for="(items,index) in this.candidateBod" 
            v-bind:key="items.name"
            :ref="items.key"
            :class="[items.theme,{'hidden':items.type==2 || items.type==1}]"
            class="xs:col-span-2">  
                <div class="absolute ml-2 mt-2 rounded-full" v-if="items.selected">
                    <img src="@/assets/images/papsi_selected_icon.svg"/>
                </div> 
                <div class="border-b w-full">
                    <img :src="getSource(items.key,items.selected)" class="w-full"/>
                </div>
                <div  class="p-6 m-auto flex flex-col text-center font-bold">
                    <span>{{items.name}}</span>
                </div>
            </div>
            </div> -->
             <!-- <div class="p-5 text-2xl font-bold">
                CIVILIAN CATEGORY
            </div> -->
            <!-- <div class="w-full p-2 sm:grid-cols-3 grid xs:grid-cols-4 md:grid-cols-5 gap-4 relative">
            <div @click="selectedCandidates(items.name,items.key,'ELECOM',items.type,index,items.selected)" 
            v-for="(items,index) in this.candidateElecom" v-bind:key="items.name" 
            :class="[items.theme,{'hidden':items.type==2 || items.type==1}]"
            class="xs:col-span-2">
             <div class="absolute ml-2 mt-2 rounded-full" v-if="items.selected">
                    <img src="@/assets/images/papsi_selected_elecom.svg"/>
                </div>    
                <div :id="items" class="border-b w-full">
                    <img :src="getSource(items.key,items.selected)" class="w-full"/>
                </div>
                  <div  class="p-2 pb-0 m-auto flex flex-col text-center font-bold min-h-[55px]">
                    <span>{{items.name}}</span>
                </div>
                 <div  class="pt-2 m-auto flex flex-col text-center text-gray-400 " :class="[{'text-green-700 !important':items.selected}]">
                    <span>{{items.type}}</span>
                </div>
            </div>
            </div> -->
            <div class="bg-theme-election-adcom h-16 flex">
                <div class="text-white font-bold text-2xl pl-5 self-center">AUDIT & SUPERVISORY COMMITTEE</div>
            </div>
            <div class="w-full p-2 grid sm:grid-cols-4 xs:grid-cols-4 md:grid-cols-5 gap-4 relative">
            <div @click="selectedCandidates(items.name,items.key,'AUDIT',items.type,index,items.selected)" 
            v-for="(items,index) in this.candidateAudit" v-bind:key="items.name" 
            :class="[items.theme]"
            class="xs:col-span-2">  
                <div class="rounded-full justify-end flex w-full" v-if="items.selected">
                    <img src="@/assets/images/papsi_selection_icon_audit.svg" class="absolute"/>
                </div>   
                <div class="p-2 w-full bg-white">
                    <div class="bg-white rounded-lg">
                        <!-- <img src="@/assets/icons/papsi_profile.svg"/> -->
                           <img :src="items.photo" class="w-full"/>
                    </div>
                    <!-- <img :src="getSource(items.key,items.selected)" class="w-full"/> -->
                </div>
                  <div  class="p-2 pb-0 m-auto flex flex-col text-center font-bold min-h-[55px]">
                    <span>{{items.name}}</span>
                </div>
                 <!-- <div  class="pt-2 m-auto flex flex-col text-center text-gray-400 " :class="[{'text-[#25387A] !important':items.selected}]">
                    <span>{{items.type}}</span>
                </div> -->
            </div>
            </div>
            <div class="bg-theme-election-elecom h-16 flex">
                <div class="text-white font-bold text-2xl pl-5 self-center">ELECTION COMMITTEE</div>
            </div>
            <div class="p-5 pb-2 text-2xl font-bold">
                OFFICER CATEGORY
            </div>
            <div class="w-full p-2 grid sm:grid-cols-4 xs:grid-cols-4 md:grid-cols-5 gap-4 relative ">
            <div @click="selectedCandidates(items.name,items.key,'ELECOM',items.type,index,items.selected)" 
            v-for="(items,index) in this.candidateElecom" v-bind:key="items.name" 
            :class="[items.theme,{'hidden':items.type=='ENLISTED PERSONNEL'}]"
            class="xs:col-span-2"> 
            <div class="rounded-full justify-end flex w-full" v-if="items.selected">
                    <img src="@/assets/images/papsi_selection_icon_elecom.svg" class="absolute"/>
                </div>  
                <div class="p-2 w-full bg-white">
                    <div class="bg-white rounded-lg">
                        <!-- <img src="@/assets/icons/papsi_profile.svg"/> -->
                        <img :src="items.photo" class="w-full"/>
                    </div>
                    <!-- <img :src="getSource(items.key,items.selected)" class="w-full"/> -->
                </div>
                <div  class="p-2 pb-0 m-auto flex flex-col text-center font-bold min-h-[55px]">
                    <span>{{items.name}}</span>
                </div>
                 <!-- <div  class="pt-2 m-auto flex flex-col text-center text-gray-400 " :class="[{'text-[#6485b8] !important':items.selected}]">
                    <span>{{items.type}}</span>
                </div> -->
            </div>
            </div>
            <div class="p-5 pb-2 text-2xl font-bold">
                ENLISTED PERSONNEL CATEGORY
            </div>
            <div class="w-full p-2 grid sm:grid-cols-4 xs:grid-cols-4 md:grid-cols-5 gap-4  relative">
            <div @click="selectedCandidates(items.name,items.key,'ELECOM',items.type,index,items.selected)" 
            v-for="(items,index) in this.candidateElecom" v-bind:key="items.name" 
            :class="[items.theme,{'hidden':items.type=='OFFICER'}]"
            class="xs:col-span-2">
               <div class="rounded-full justify-end flex w-full" v-if="items.selected">
                    <img src="@/assets/images/papsi_selection_icon_elecom.svg" class="absolute"/>
                </div> 
                <div class="p-2 w-full bg-white">
                    <div class="bg-white rounded-lg">
                        <!-- <img src="@/assets/icons/papsi_profile.svg"/> -->
                           <img :src="items.photo" class="w-full"/>
                    </div>
                    <!-- <img :src="getSource(items.key,items.selected)" class="w-full"/> -->
                </div>
                  <div  class="p-2 pb-0 m-auto flex flex-col text-center font-bold min-h-[55px]">
                    <span>{{items.name}}</span>
                </div>
                 <!-- <div  class="pt-2 m-auto flex flex-col text-center text-gray-400 " :class="[{'text-[#6485b8] !important':items.selected}]">
                    <span>{{items.type}}</span>
                </div> -->
            </div>
            </div>
            </div>
        </div>
        <div class="p-2 sm:hidden md:hidden pt-4">
            <div @click="submiVotes"
                :class="[{'bg-gray-400 text-white border-gray-400 hover:bg-gray-400':this.disabled},'  cursor-pointer']"
                class="border p-5  text-lg font-bold rounded text-center shadow-lg border-theme-primary bg-theme-primary text-white ">
                    <span v-text="'SUBMIT VOTE'" />
            </div>
        </div>
    </div>
</div>   
</div> 
</template>

<script>
import { defineComponent } from 'vue';
import mixin from "./../../mixin";
import Election from "@/services/Election";
import User from "@/services/User";
import OTP from "@/services/OTP";
import Modal from "@/components/controls/ModalControl.vue";
import ElectionPolicy from "@/components/sections/ElectionPolicy.vue";
import LoadingControl from "@/components/controls/LoadingControl.vue";
import VOtpInput from "vue3-otp-input";
// import TextField from "@/components/controls/TextBoxField.vue"
export default defineComponent({
    components: {
        Modal,
        // TextField,
        VOtpInput,
        ElectionPolicy,
        LoadingControl,
    },
    mounted() {
        let that = this;
        let store = that.$store;
        let state = store.state;
        //  that.checkExistingCookie();
        // that.showOtpModal = true;
        // that.loadCandidatesBod();
        // that.loadCandidatesAudit();
        // that.loadCandidatesElecom();
        if(state.user.skip==true){
            that.$router.push('/Dashboard');
        } else {
            that.checkStarted();
        }
         
        
    },
    mixins: [
        mixin,
    ],
    data () {
        return {
          selectedBod:[],
          selectedBodOf:[],
          selectedBodEp:[],
          selectedBodCv:[],
          selectedElecomOf:[],
          selectedElecomEp:[],
          selectedElecomCv:[],
          selectedAudit:[],
          candidateBod :[],
          candidateAudit:[],
          candidateElecom:[],
          bodOfCompleted:false,
          bodEpCompleted:false,
          bodCvCompleted:false,
          modalMessage:'',
          elecomEpCompleted:false,
          showMenuIcon:true,
          elecomOfCompleted:false,
          elecomCvCompleted:false,
          auditCompeleted:false,
          disabled:true,
          showSelectedCandidates:false,
          Loading:false,
          otp:'',
          messageModalShow:false,
          electionCodeView:false,
          confirmation: false,
          showOtpModal:false,
          error_message:'',
          exceed:false,
          show_resend_otp:true,
          timerEnabled:false,
          timerCount:0
        }
    },
    methods: {
        closedElectionOtpModal(){
            let that = this;
            that.showOtpModal=false;
            that.$router.push('/Dashboard');
        },
        resend(){
            let that = this;
            const store = that.$store;
            const state = store.state;
            that.show_resend_otp = false;
            that.timerEnabled= true;
            that.timerCount = 120;
            // console.log(state);  
            var payload = {
                email:state.credentials.email,
                mobile:state.credentials.mobile,
                name:state.credentials.name,
                pin:state.credentials.pin,
                type:5
            }
            that.Loading = true;
             OTP.requestOtp(payload).then(function(response) {
                var request_otp = response.data.data;
                state.credentials.otp = request_otp.otp;
                state.credentials.pin = request_otp.pin;
                that.Loading = false;
            }).catch(function() {
                that.Loading = false;
                that.error = true;
                // that.show_message_modal = true;
                // that.modal_message = response.data.message;
            });
        },
        openList() {
            this.showSelectedCandidates = true
            this.showMenuIcon =false;
        },
        closeList()
        {
            this.showSelectedCandidates = false
            this.showMenuIcon =true;
        },
        closed() {
            
            this.electionCodeView = false;
            this.exceed = false;
        },
        checkStarted() {
            let that = this;
            let store = that.$store;
            let state = store.state;


            if(state.credentials.name=="" && state.credentials.name==null){
                that.checkGaInfo();
            }
         
            if(state.credentials.express || state.credentials.deactivated){
                that.$router.push('/user/settings');
            }
            // console.log(state.election_config.voted);
            if(state.election_config.voted==true) {
                that.$router.push('/Dashboard');
            }
            if(state.election_config.registered==false) {
                that.$router.push('/Dashboard');
            }
            if(state.election_config.voting_started==false) {
                that.$router.push('/Dashboard');
            }
            if(state.election_config.voting_ended==true) {
                that.$router.push('/Dashboard');
            }
            if(state.election_config.can_vote==false) {
                that.$router.push('/Dashboard');
            }
            else{    
                that.showOtpModal = true;
            }
           
        },
        checkGaInfo(){
            let that = this;
            let cookie = that.$cookies;
            let store = that.$store;
            let state = store.state;
            // that.setNavbar();
            that.Loading = true;
            Election.getGaInfo(cookie.get('hash')).then(function(response) {
             let user_data = response.data.data;

            //   state.election_config.registration_end= user_data.schedules.registration_end;
            //   state.election_config.registration_ended= user_data.schedules.registration_ended;
            //   state.election_config.registration_start=user_data.schedules.registration_start;
            //   state.election_config.registration_started= user_data.schedules.registration_started;
            //   state.election_config.date_end = user_data.schedules.voting_end;
            //   state.election_config.voting_ended = user_data.schedules.voting_ended;
            //   state.election_config.date_start = user_data.schedules.voting_start;
            //   state.election_config.date_start ='2025-02-27 10:20:00';
              state.user.skip = false;
              state.election_config.server_time = response.timestamp;
              state.election_config.election_registered = user_data.user.registered;
              state.election_config.code = user_data.code;
              state.election_config.can_vote = user_data.user.can_vote;
              state.election_config.voted = user_data.user.voted;
              that.checkStarted();
          // console.log(response);
            }).catch(function(){
                that.error = true;
                that.loading = false;
                // that.show_message_modal = true;
                // // that.modal_message = error.response.data.message;
                // if(error.response.status == 403) {
                //     // that.expired = true
                //     // that.modal_message = "Please Log in Again.";
                // }
            });

        },
        getSource(key) {
            // if(selected){
            //     return "https://media.pafcpic.net/images/candidates/y/" +key+".jpg";
            // }else{
            //     return "https://media.pafcpic.net/images/candidates/g/" +key+".jpg";
            // }
        return "https://media.pafcpic.net/images/candidates/y/" +key+".jpg";
        },
        cancel() {
            this.$router.push('/Dashboard');
        },
        showElectionPolicy() {

            let that = this;
            const store = that.$store;
            const state = store.state;
            const cookie = that.$cookies;

            that.Loading=true;
            OTP.verify(cookie.get('user').uuid,that.otp).then(function(){
                that.Loading=false;
                state.election_config.started = true;
                that.showOtpModal = false;
                that.electionCodeView = true;
                that.startVoting();
            }).catch(function(response){
                state.election_config.started = false;
                that.error_message = response.data.data.message;
                that.Loading=false;
            });

        },
        startVoting() {
            let that = this;
            let cookie = this.$cookies;
            var existing = cookie.get('user');
            that.Loading=true;
            Election.startVoting(existing.uuid).then(function() {
                // response
                // let data = response.data;
                that.electionCodeView = true;
            }).catch(function() {
                that.Loading=false;
            });
        },

        handleCompletedOtp() {
            let that = this;
            let store = that.$store;
            let state = store.state;
            let cookie = that.$cookies;
            // let credentials = state.credentials;
          

            let payload = {
                hash:cookie.get('user_session').hash,
                otp:that.otp,
            }
            // that.showOtpModal = false;
            // that.startLoading();
            that.Loading = true;
            Election.validateOtp(state.election_config.code,payload).then(function(response){
                let data = response.data.data;
            
                that.showOtpModal=false;
                // that.Loading=false;
                that.votingStart(data.secret_key);
            }).catch(function(error) {
                // that.note_style="text-center";
                that.Loading = false;
                that.$refs.otp_election.clearInput();
                that.error = true;
                that.error_message = error.response.data.message;
                
                // that.showOtpModal = true;
            });

           
        },
        votingStart(key){
            let that = this;
            let store = that.$store;
            let state = store.state;
            let cookie = that.$cookies;
            let election_config = state.election_config;

            Election.startVoting(election_config.code,{hash:cookie.get('user_session').hash,secret_key:key}).then(function(result) {
                let data = result.data.data;
                election_config.ballot_no = data.ballot_no;
                election_config.voter_key = data.voter_key;
                state.user.date_started = result.data.timestamp;
                that.electionCodeView = true;
                that.showOtpModal=false;
                that.loadCandidatesBod();
                that.loadCandidatesAudit();
                that.loadCandidatesElecom();
            }).catch(function(error) {
                if(error.response.data.status == 410) {
                    that.Loading = false;
                    that.$router.push('/Dashboard');
                }else{
                    that.Loading = false;
                    that.error = true;
                    that.error_message = error.response.data.message;
                    
                }
            });
        },
       selectedCandidates(name,key,category,type,index,selected) {
        if(selected == false) {
            if(category == "BOD") {
                if(this.selectedBod.length < 7){
                   this.selectedBod.push({name:name,key:key}); 
                   this.candidateBod[index].selected = true;
                   this.candidateBod[index].theme = 'text-black border border-[#016B00] bg-[#EAFFE9] rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300';
                   
                
                }else{
                    if(this.bodOfCompleted) {
                        this.exceed=true;
                    } 
                }
                // if(type == '1' ) {
                //         if(this.selectedBodOf.length < 3) {
                //    this.selectedBodOf.push({name:name,key:key}); 
                //    this.candidateBod[index].selected = true;
                //    this.candidateBod[index].theme = 'text-white border border-gray-400 bg-theme-election-bod rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300';
                // }
                // if(this.bodOfCompleted) {
                //     this.exceed=true;
                // }
                // }
                //  if(type == '2' ) {
                //        if(this.selectedBodEp.length < 3) {
                //    this.selectedBodEp.push({name:name,key:key}); 
                //    this.candidateBod[index].selected = true;
                //    this.candidateBod[index].theme = 'text-white border border-gray-400 bg-theme-election-bod rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300';
                // }
                // if(this.bodEpCompleted) {
                //     this.exceed=true;
                // }
                // }
                // if(type == '3' ) {
                //     if(this.selectedBodCv.length < 2) {
                //         this.selectedBodCv.push({name:name,key:key}); 
                //         this.candidateBod[index].selected = true;
                //         this.candidateBod[index].theme = 'text-white border border-gray-400 bg-theme-election-bod rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300';
                //     }
                //     if(this.bodCvCompleted) {
                //         this.exceed=true;
                //     }
                // }
            }
            if(category == "ELECOM") {
                if(type == 'OFFICER' ) {
                    if(this.selectedElecomOf.length < 1) {
                        this.selectedElecomOf.push({name:name,key:key});
                        this.candidateElecom[index].selected = true;
                        this.candidateElecom[index].theme = 'text-black border border-[#6485b8] bg-[#E5f0ff] rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300';
                    } 
                     if(this.elecomOfCompleted) {
                        this.exceed = true;
                    }
                   
                }

                if(type == 'ENLISTED PERSONNEL') {
                    if(this.selectedElecomEp.length < 1) {
                        
                        this.selectedElecomEp.push({name:name,key:key});
                        this.candidateElecom[index].selected = true;
                        this.candidateElecom[index].theme = 'text-black  border border-[#6485b8] bg-[#E5f0ff] rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300';
                    }
                    if(this.elecomEpCompleted) {
                        this.exceed=true;
                    }
                } 

                
                // if(type == '3') {
                //     if(this.selectedElecomCv.length < 1) {
                        
                //         this.selectedElecomCv.push({name:name,key:key});
                //         this.candidateElecom[index].selected = true;
                //         this.candidateElecom[index].theme = 'text-white  border border-gray-400 bg-theme-election-elecom rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300';
                //     }
                //     if(this.elecomCvCompleted) {
                //         this.exceed=true;
                //     }
                // } 
           } 
            if(category == "AUDIT") {
                if(this.selectedAudit.length < 2) {
                    this.selectedAudit.push({name:name,key:key});
                    this.candidateAudit[index].selected = true;
                    this.candidateAudit[index].theme = 'text-black  border border-[#25387A] bg-[#6485b8] rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300';
                }
                 if(this.auditCompeleted) {
                    this.exceed=true;
                }
            }
           
        }else {
            if(category == "BOD") {
                // console.log(key);
                 for(let i=0; i<this.selectedBod.length;i++) {
                        if(key == this.selectedBod[i].key){
                            this.selectedBod.splice(i,1); 
                        }
                    }
                    this.candidateBod[index].selected = false;
                    this.candidateBod[index].theme = 'border border-gray-400 rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300';
                // if(type == 1) {
                //     for(let i=0; i<this.selectedBodOf.length;i++) {
                //         if(key == this.selectedBodOf[i].key){
                //             this.selectedBodOf.splice(i,1); 
                //         }
                //     }
                //     this.candidateBod[index].selected = false;
                //     this.candidateBod[index].theme = 'border border-gray-400 rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300';
                // }
                //  if(type == 2) {
                //     for(let i=0; i<this.selectedBodEp.length;i++) {
                //         if(key == this.selectedBodEp[i].key){
                //             this.selectedBodEp.splice(i,1); 
                //         }
                //     }
                //     this.candidateBod[index].selected = false;
                //     this.candidateBod[index].theme = 'border border-gray-400 rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300';
                // }
                //  if(type == 3) {
                //     for(let i=0; i<this.selectedBodCv.length;i++) {
                //         if(key == this.selectedBodCv[i].key){
                //             this.selectedBodCv.splice(i,1); 
                //         }
                //     }
                //     this.candidateBod[index].selected = false;
                //     this.candidateBod[index].theme = 'border border-gray-400 rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300';
                // }
            }
            if(category == "ELECOM") {
                if(type == "OFFICER") {
                    for(let i=0; i<this.selectedElecomOf.length;i++) {
                        if(key == this.selectedElecomOf[i].key){
                            this.selectedElecomOf.splice(i,1); 
                        }
                    }
                    this.candidateElecom[index].selected = false;
                    this.candidateElecom[index].theme = 'border border-gray-400 rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300';
                } 
                if(type == "ENLISTED PERSONNEL") {
                    for(let i=0; i<this.selectedElecomEp.length;i++) {
                        if(key == this.selectedElecomEp[i].key){
                            this.selectedElecomEp.splice(i,1); 
                        }
                    }
                    this.candidateElecom[index].selected = false;
                    this.candidateElecom[index].theme = 'border border-gray-400  rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300';
                }
                //    if(type == 3) {
                //     for(let i=0; i<this.selectedElecomCv.length;i++) {
                //         if(key == this.selectedElecomCv[i].key){
                //             this.selectedElecomCv.splice(i,1); 
                //         }
                //     }
                //     this.candidateElecom[index].selected = false;
                //     this.candidateElecom[index].theme = 'border border-gray-400  rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300';
                // }
                
            } 
            if(category == "AUDIT") {
                for(let i=0; i<this.selectedAudit.length;i++) {
                    if(key == this.selectedAudit[i].key){
                        this.selectedAudit.splice(i,1); 
                    }
                }
                this.candidateAudit[index].selected = false;
                this.candidateAudit[index].theme = 'border border-gray-400  rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300';
            }  
        }
        this.checkCompleted();
       },
       checkCompleted() {
            if(this.selectedBod.length == 7
            // && this.selectedBodEp.length == 3
            // && this.selectedBodCv.length == 2
            && this.selectedAudit.length == 1
            && this.selectedElecomOf.length == 1
            && this.selectedElecomEp.length == 1
            //  && this.selectedElecomCv.length == 1
             ) 
            {
                this.disabled = false;
            } else {
                 this.disabled = true;
            }
            console.log(this.selectedBod.length);
            if(this.selectedBod.length == 7) {
                this.bodOfCompleted = true;
            } else if(this.selectedBod.length < 7) {
                this.bodOfCompleted = false;
            }

            // if(this.selectedBodOf.length == 3) {
            //     this.bodOfCompleted = true;
            // } else if(this.selectedBodOf.length < 3) {
            //     this.bodOfCompleted = false;
            // }
            //  if(this.selectedBodEp.length == 3) {
            //     this.bodEpCompleted = true;
            // } else if(this.selectedBodEp.length < 3) {
            //     this.bodEpCompleted = false;
            // }
            //  if(this.selectedBodCv.length == 2) {
            //     this.bodCvCompleted = true;
            // } else if(this.selectedBodCv.length < 2) {
            //     this.bodCvCompleted = false;
            // }

             if(this.selectedAudit.length == 2) {
                this.auditCompeleted = true;
            } else if(this.selectedAudit.length < 2) {
                this.auditCompeleted = false;
            }
            if(this.selectedElecomOf.length == 1) {
                this.elecomOfCompleted = true;
            } else if(this.selectedElecomOf.length < 1) {
               this.elecomOfCompleted = false;
            }
            if(this.selectedElecomEp.length == 1) {
                this.elecomEpCompleted = true;
            } else if(this.selectedElecomEp.length < 1) {
               this.elecomEpCompleted = false;
            }
            //  if(this.selectedElecomCv.length == 1) {
            //     this.elecomCvCompleted = true;
            // } else if(this.selectedElecomCv.length < 1) {
            //    this.elecomCvCompleted = false;
            // }
       },
       checkLoaded() {
            if(this.candidateBod.length > 0 
            && this.candidateAudit.length > 0 
            && this.candidateElecom.length > 0 ) {
                this.Loading=false;
            }
       },
       submiVotes() {
        if(!this.disabled) {
            this.confirmation = true;
        }
       },
       negativeClick() {
        this.confirmation = false;
       },
       positiveClick()  {
        if(this.disabled==false){
                let overallSelected = [];
                const that = this;
                const store = that.$store;
                const state = store.state;
                const cookie = that.$cookies;
                that.selectedBod.forEach(data => {
                        overallSelected.push(data.key);
                })
                // that.selectedBodEp.forEach(data => {
                //         overallSelected.push(data.key);
                // })
                // that.selectedBodCv.forEach(data => {
                //         overallSelected.push(data.key);
                // })
                that.selectedElecomOf.forEach(data => {
                        overallSelected.push(data.key);
                })
                that.selectedElecomEp.forEach(data => {
                        overallSelected.push(data.key);
                })
                // that.selectedElecomCv.forEach(data => {
                //         overallSelected.push(data.key);
                // })
                that.selectedAudit.forEach(data => {
                        overallSelected.push(data.key);
                })

                var payload = {
                    hash:cookie.get('user_session').hash,
                    votes:JSON.stringify(overallSelected),
                    voter_key:state.election_config.voter_key,
                    ballot_no:state.election_config.ballot_no,
                };

                that.Loading=true;
                // response
                Election.submitVote(state.election_config.code,payload).then(function(response) {
                    // let data = response.data.data;
                        state.user.selected_candidatesBodOf = that.selectedBod;
                                // state.user.selected_candidatesBodEp = that.selectedBodEp;
                                // state.user.selected_candidatesBodCv = that.selectedBodCv;
                        state.user.selected_candidatesElecomOf = that.selectedElecomOf;
                        state.user.selected_candidatesElecomEp = that.selectedElecomEp;
                                // state.user.selected_candidatesElecomCv = that.selectedElecomCv;
                        state.user.selected_candidatesAudit = that.selectedAudit;
                        state.election_config.finished = false;
                        state.user.date_submitted = response.data.timestamp;
                        // state.user.ballot_details = ballot.data;
                        that.Loading=false;
                        that.$router.push('/election/ballot');
                        // if(data.success) {
                        //     Election.getBallot(data.data.ballot,cookie.get('user').hash).then(function(response) {
                        //         var ballot = response.data;
                        //         state.election_config.voted= true;
                        //         if(ballot.success) {
                        //           
                        //         }
                        //     }).catch(function() {
                        //         that.Loading=false;
                        //     });
                        // }
                }).catch(function(error) {
                     if(error.response.data.status == 410) {
                        that.Loading=false;
                        that.$router.push('/Dashboard');
                    } else {
                        that.Loading = false;
                        that.error = true;
                        that.error_message = error.response.data.message;
                        
                    }
                });
            }
       },
       checkExistingCookie() {
        let that = this;
        const cookie = that.$cookies;
        const store = that.$store;
        //  let log = store.state.logged;
        let state = store.state;
        state.hash = cookie.get('user').hash;
        that.Loading= true;
        User.getuserInfo(cookie.get('user')).then(function(response){
          let user_data = response.data.data;
          
              state.credentials.name = user_data.account.username;

              state.election_config.date_end = user_data.election.vote_end;
              state.election_config.date_start = user_data.election.vote_start;
              state.election_config.server_time = response.timestamp;
              state.election_config.election_registered = user_data.election.registered;
              state.election_config.can_vote = user_data.election.can_vote;
              state.election_config.voted = user_data.election.voted;
              state.election_config.voting_ended = user_data.election.voting_ended;
              state.election_config.registration_start=user_data.election.reg_start;
              state.election_config.registration_end= user_data.election.reg_end;
              state.election_config.registration_started= user_data.election.reg_started;

              state.credentials.first_name=user_data.info.first_name;
              state.credentials.last_name=user_data.info.last_name;
              state.credentials.middle_name=user_data.info.middle_name;
              state.credentials.gender=user_data.info.gender;
              state.credentials.regular=user_data.info.regular;
              state.credentials.account_no = user_data.info.account_no;
              state.credentials.serial_no = user_data.info.serial_no;
              state.credentials.tin= user_data.info.tin;
              state.credentials.email = user_data.account.email;
              state.credentials.mobile = user_data.account.mobile;
              state.credentials.username = user_data.account.username;
              state.credentials.qr =user_data.qr;
              state.credentials.uuid = user_data.uuid;
              state.credentials.hash = cookie.get('user').hash;
              state.credentials.key = user_data.key;
              state.credentials.membership_date = user_data.info.membership_date;
              state.credentials.registered_date = user_data.account.registered;
              state.credentials.status = user_data.info.status;
              state.credentials.membership_type = user_data.info.membership_type;
              state.credentials.deactivated = user_data.account.deactivated;

             if(user_data.account.express || user_data.account.deactivated){
                that.$router.push('/user/settings');
              }else{
                that.checkStarted();
              }
  
        }).catch(function(){
            that.error = true;
            that.Loading = false;
            // that.show_message_modal = true;
            //   if(error.response.status == 403){
            //     // that.expired = true;
            //     //  that.modal_message = "Please Log in again.";
            // }else{
            //     // that.modal_message = error.response.data.message;
            // }
        });
       },
       loadCandidatesBod() {
        const that = this;
        const store = this.$store;
        const state = store.state;
        const cookie = that.$cookies;
        that.Loading=true;
        let payload = {
            hash:cookie.get('user_session').hash
        }
        Election.getBOD(payload,state.election_config.code).then(function (response) {
            let data = response.data;
            
                data.data.forEach(element => {
                    let config = {
                        name:element.name,
                        key:element.code,
                        type:element.type,
                        photo:element.photo,
                        selected:false,
                        theme:'border border-gray-400 rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300'
                    };
                    that.candidateBod.push(config);
                    
                });
                state.user.candidateBod = that.candidateBod;
                // console.log(that.candidateBod);
                that.checkLoaded();
            that.$emit('scrolltop');
        })
        .catch(function(error) {
                if(error.response.data.status == 410) {
                    that.Loading=false;
                    that.$router.push('/Dashboard');
                } else {
                    that.Loading = false;
                    that.error = true;
                    that.error_message = error.response.data.message;
                    
                }
        });
       },
       loadCandidatesAudit() {
        const that = this;
        const store = this.$store;
        const state = store.state;
        that.Loading=true;
         const cookie = that.$cookies;
        let payload = {
            hash:cookie.get('user_session').hash
        }
        Election.getAudit(payload,state.election_config.code).then(function (response) {
            let data = response.data;
            
            if(data.success) 
            {
                data.data.forEach(element => {
                    let config = {
                        name:element.name,
                        key:element.code,
                        type:element.type,
                        photo:element.photo,
                        selected:false,
                        theme:'border border-gray-400 rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300'
                    };
                    that.candidateAudit.push(config);
                   
                });
                 state.user.candidateAudit = that.candidateAudit;
                that.checkLoaded();
            }
            that.$emit('scrolltop');
        })
        .catch(function(error) {
             if(error.response.data.status == 410) {
                    that.Loading=false;
                    that.$router.push('/Dashboard');
                } else {
                    that.Loading = false;
                    that.error = true;
                    that.error_message = error.response.data.message;
                }
        });
       },
       updateOtp(e) {
          let key = e.key;
          let that = this;
          that[key] = e.value;
          // this.enableButton();
        },
       openListd() {
        this.electionCodeView = false;
        this.exceed = false;
       },
        requestOtp() {
            let that = this;
            const store = that.$store;
            const state = store.state;
            var payload = {
                email:state.credentials.email,
                mobile:state.credentials.mobile,
                name:state.credentials.first_name,
                pin:state.credentials.pin,
                type:5
            }
            if(!state.election_config.started){
                 OTP.requestOtp(payload).then(function(response) {
                    var request_otp = response.data.data;
                    state.credentials.otp = request_otp.otp;
                    state.credentials.pin = request_otp.pin;
                    that.Loading=false;
            });
            }
           
        },
       loadCandidatesElecom() {
        const that = this;
        const store = this.$store;
        const state = store.state;
        that.Loading=true;
        const cookie = that.$cookies;
        
        let payload = {
            hash:cookie.get('user_session').hash
        }
        // console.log(payload)
        Election.getElecom(payload,state.election_config.code).then(function (response) {
            let data = response.data;
            
            if(data.success) 
            {
               data.data.forEach(element => {
                    let config = {
                        name:element.name,
                        key:element.code,
                        type:element.type,
                        photo:element.photo,
                        selected:false,
                        theme:'border border-gray-400 rounded cursor-pointer shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 hover:text-white duration-300'
                    };
                    that.candidateElecom.push(config);
                   
                });
                 state.user.candidateElecom = that.candidateElecom;
                that.checkLoaded();
            }
            that.$emit('scrolltop');
        })
        .catch(function(error) {
            if(error.response.data.status == 410) {
                    that.Loading=false;
                    that.$router.push('/Dashboard');
                } else {
                    that.Loading = false;
                    that.error = true;
                    that.error_message = error.response.data.message;
                }
        });
       }
    },
    watch: {
        timerCount: {
        handler(value) {
            if (value > 0 && this.timerEnabled) {
            this.show_resend_otp = false;
            setTimeout(() => {
                this.timerCount = this.timerCount - 1;
                // console.log(this.timerCount);
            }, 1000);
            } else {
            this.show_resend_otp = true;
            this.timerEnabled = false; // run your function here
            }
        },
        immediate: true
        },
    },
})
</script>
<style scoped>
.bg-translucent {
    background-color: rgba(0, 0, 0, 0.75);
}
.modal-max-h {
    max-height: calc(100vh - 200px);
}
.menu-alignment {
    text-align:-webkit-right
}
.bod_selected{
    background-color:#019E00;
}
.elecom_selected {
    background-color: #496BA0;
}
.adcom_selected{
    background-color: #314AA1 ;
}
.card_color{
    background: #EDE5D9;
}
.typed {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing;
  animation-duration: 1.5s;
  animation-timing-function: steps(30, end);
  animation-fill-mode: forwards;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
</style>