<template>
    <div v-bind:id="id" v-show="show" class="w-full h-full bg-translucent fixed left-0 p-12 top-0 flex m-auto overflow-y-auto z-50" :class="[z_value]"> 
        <div class="bg-white m-auto  w-full rounded-md relative transition delay-100 duration-500 ease-in-out " 
        v-bind:class="[{'-translate-x-0' : this.popUp ,'-translate-y-full':!this.popUp ,},default_width]">
            <div>
                <div class="p-3 text-xl font-bold rounded-t-md flex flex-row relative bg-theme-primary text-yellow-500" v-if="title">
                    <div class="truncate uppercase grow " v-text="title"></div>
                    <div class="text-md mr-2 cursor-pointer text-white" @click="close" v-if="this.closable">
                     X
                    </div>
                </div>
                <div class="w-full flex flex-col items-center justify-center p-6 pb-1 space-y-3" v-if="this.error || this.success || this.note || this.question">
                    <div class="rounded-full p-4 max-w-[100px] " :class="[{'bg-red-100':this.error},{'bg-green-100':this.success},{'bg-blue-100':this.note},{'bg-yellow-100':this.question}]">
                        <div class="rounded-full " :class="[{'bg-red-500':this.error},{'bg-theme-primary':this.success},{'bg-blue-500':this.note},{'bg-yellow-500':this.question}]">
                            <Icon id="pencil" :source="getSource()" :width="this.icon_size" />
                        </div>
                    </div>
                     <div>
                       <span v-text="pop_up_text" class="font-bold text-2xl" :class="[{'text-red-500':this.error},{'text-theme-primary':this.success},{'text-blue-500':this.note},{'text-yellow-500':this.question}]"/>
                    </div>
                </div>
               
                <div class="overflow-y-auto text-center modal-max-h h-full ">
                    <slot ></slot>
                    <div class="flex sm:flex-row md:flex-row xs:flex-col xs:space-x-0 space-x-5 p-3" v-show="this.amendment">
                        <div class="w-full">
                            <Checkbox :checkbox_width="50" id="scheckbox" class="xs:float-left float-right" ref="fcheckbox" @click="checkStatus('fcheckbox')">
                                <span v-text="lang('checkbox.label.yes')" class="text-black font-bold"/>
                            </Checkbox>
                        </div>
                        <div class="w-full">
                            <Checkbox :checkbox_width="50" id="scheckbox" class="float-left" ref="scheckbox" @click="checkStatus('scheckbox')">
                               <span v-text="lang('checkbox.label.no')" class="text-black font-bold"/>
                            </Checkbox>
                        </div>
                    </div>
                <div class="flex flex-wrap flex-row pl-5 pr-5 pb-5 space-x-2" v-if="positiveButton || negativeButton">
                     <div class="w-full flex-1" v-if="negativeButton !== null">
                        <Button :theme="button_theme_negative"  @clicked="negativeClick" :button_text="negativeButton" :text_size="text_size">
                            {{ negativeButton }}
                        </Button>
                    </div>
                    <div class="w-full flex-1" v-if="positiveButton !== null" >
                        <Button :theme="button_theme_positive" @clicked="positiveClick" :button_text="positiveButton" :disabled="disable_button" :text_size="text_size">
                            {{ positiveButton }}
                        </Button>
                    </div>
                   
                </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import mixin from '@/mixin';
import { defineComponent } from 'vue'
import Button from "./ButtonControl.vue";
import Checkbox from "./CheckBox.vue";
import Icon from "./Icon.vue"

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true,
        },
        default_width: {
            type:String,
            default:'max-w-md',
            required:false,
        },
        type:{
            type: String,
            required: false,   
        },
        closable: {
            type: Boolean,
            required: false,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        display: {
            type: Boolean,
            required: false,
            default: false,
        },
        positiveButton: {
            type: String,
            required: false,
            default: null,
        },
        amendment:{
            type: String,
            required: false,
            default: null,
        },
        z_value:{
            type: String,
            required: false,
        },
        negativeButton: {
            type: String,
            required: false,
            default: null,
        },
        otpType: {
            type: String,
            required: false,
        },
        withcomponent:{
            type: Boolean,
            default:false,
            required: false,
        },
        question:{
            type: Boolean,
            default:false,
        },
        success:{
            type: Boolean,
            default:false,
        },
        error:{
            type:Boolean,
            default:false,
        },
        note:{
            type: Boolean,
            default:false,
        },
        modal_message:{
            type:String,
            default:null
        },
        text_size:{
            type: String,
            required: false,
        }
    },
     components: {
        Button,
        Checkbox,
        Icon,
    },
     data() {
        return {
            show: true,
            popUp: false,
            re_reques_disabled:false,
            request_timer:120,
            pop_up_text:null,
            icon_size:0,
            disable_button:false,
            approved_checkbox:false,
            disapproved_checkbox:false,
            Loading:false,
            button_theme_negative:'',
            button_theme_positive:''
        }
    },
    mixins: [
      mixin,
    ],
    methods :{
        close() {
            this.show = false;
            this.$emit('closed');
        },
        negativeClick(){
            this.$emit('negativeClick');
        },
        positiveClick() {
        const ref = this.$refs;
        let vote;
            if(this.amendment){
                if(ref.scheckbox.status) {
                   vote=false;
                   ref.scheckbox.toggle();
                }
                if(ref.fcheckbox.status)
                {
                  vote=true;
                  ref.fcheckbox.toggle();
                }
                 this.$emit('positiveClick',vote);
            }else{
              this.$emit('positiveClick');
            }
        },
        readAmendments(){
            this.$emit('readAmendments');
        },
        getSource() {
            if(this.success){
                this.icon_size = 130;
                return 'IconsNew/papsi_check.svg';
            }
            if(this.error) {
                this.icon_size = 180;
                return 'IconsNew/papsi_error.svg';
            }
            if(this.note) {
                this.icon_size = 120;
                return 'IconsNew/papsi_info.svg';
            }
            if(this.question){
                 this.icon_size = 120;
                return 'IconsNew/papsi_info.svg';
            }
        },
        checkStatus(n) {
        const ref = this.$refs;

           if(n=='fcheckbox'){
                this.approved_checkbox =true;
                if(ref.scheckbox.status) {
                    this.disapproved_checkbox = false;
                    ref.scheckbox.toggle();
                }
           }
           if(n=='scheckbox'){
            this.disapproved_checkbox = true;
              if(ref.fcheckbox.status)
                {
                this.approved_checkbox =false;
                  ref.fcheckbox.toggle();
                }
           }
           if(ref.fcheckbox.status == false && ref.scheckbox.status == false){
                this.disable_button = true;
           }else{
                this.disable_button = false;
           }
        }
    },
    watch: {
        display(n) {
            this.show = n;
            setTimeout(() => {
                 if(this.error) 
                 {
                    this.pop_up_text ="ERROR";
                 }
                 if(this.success)
                 {
                     this.pop_up_text ="SUCCESS";
                     this.button_theme_positive = "bg-theme-primary"
                 }
                 if(this.note)
                 {
                    if(this.modal_message!='' && this.modal_message!=null){
                      this.pop_up_text ="VOTE LIMIT REACHED";  
                    }else{
                        this.pop_up_text ="INFORMATION";
                    }
                     
                 }
                 if(this.question)
                 {
                     this.pop_up_text ="CONFIRMATION";
                 }
                if(this.question) {
                    this.button_theme_negative = 'bg-white border-2 text-yellow-500 border-yellow-500';
                    this.button_theme_positive = 'bg-yellow-500';
                }
                if(this.error){
                    this.button_theme_negative = 'bg-red-500';
                }

                if(this.note){
                    this.button_theme_positive = 'bg-blue-500';
                }
                this.popUp = n;
            }, 10);
        }
    },
    mounted(){
        this.show = this.display;
        if(this.amendment){
            if(this.approved_checkbox == false && this.disapproved_checkbox == false){
                this.disable_button = true;
           }
        }
 
        if(this.question) {
            this.button_theme_negative = 'bg-white border-2 text-yellow-500 border-yellow-500';
            this.button_theme_positive = 'bg-yellow-500';
        }
        if(this.error){
             this.button_theme_negative = 'bg-red-500';
        }

        if(this.note){
            this.button_theme_positive = 'bg-blue-500';
        }

       
    }
})
</script>
<style>
.bg-translucent {
    background-color: rgba(0, 0, 0, .96) !important;
}
.modal-max-h {
    max-height: calc(100vh - 50px);
}
</style>